import { ApolloError, gql } from "@apollo/client";
import { SelectableListItem } from "Component/Global/Interactive/SelectableList/SelectableList";
import { GraphQLMutationResponse, GraphQLQueryResponse } from "GraphQL/GraphQLResponse";
import { deleteCharacters, updateCharacter, updateCharacters, setCharacterCoverImage } from "GraphQL/Mutations/CharacterMutations";
import { getCharacterById, getCharacters, searchCharacters } from "GraphQL/Queries/CharacterQueries";
import { Character } from "Models/Character";
import { CharacterDetail } from "Models/CharacterDetail";
import GenerateCharacterRequest from "Models/Request/GenerateCharacterRequest";
import { ReactNode } from "react";
import { Size } from "Style/Sizing";
import ApolloClient from "Utils/ApolloClient";
import UploadService from "./UploadService";
import { updateCharacterDetail } from "GraphQL/Mutations/CharacterDetailMutations";
import { updateCharacterNote } from "GraphQL/Mutations/CharacterNoteMutations";
import { updateCharacterRoleplay } from "GraphQL/Mutations/CharacterRoleplayMutations";
import { CharacterNote } from "Models/CharacterNote";
import { CharacterRoleplay } from "Models/CharacterRoleplay";
import { MembersGroups } from "Models/MembersGroups";
import { _FIELDS_CHARACTER_LIST, _FIELDS_CHARACTER_DETAILED, _FIELDS_CHARACTER_DETAIL, _FIELDS_CHARACTER_NOTE, _FIELDS_CHARACTER_ROLEPLAY, _FIELDS_GROUP_LIST, _FIELDS_CHARACTER_LIST_WITH_GROUPS } from "./GraphQLFields";
import AxiosClient from "Utils/AxiosClient";
import { NIL as NIL_UUID } from "uuid";
import { QueryOrdering } from "GraphQL/GraphQLQuery";

export default class CharacterService {
	private readonly _apolloClient: ApolloClient;
	private readonly _axiosClient: AxiosClient;
	private readonly _uploadService: UploadService;

	private csvTemplate?: Blob;

	constructor(apolloClient: ApolloClient, axiosClient: AxiosClient, uploadService: UploadService) {
		this._apolloClient = apolloClient;
		this._axiosClient = axiosClient;
		this._uploadService = uploadService;
	}

	public getDescriptor(character: Partial<Character>): string {
		let descriptor = "";
		if (character.culture?.name) {
			descriptor += character.culture.name + " ";
		}
		if (character.race?.name && character.race?.name !== character.culture?.name) {
			descriptor += character.race.name + " ";
		}
		if (character.profession?.name) {
			descriptor += "- " + character.profession.name;
		}
		return descriptor;
	}

	public getCoverImage(character: Partial<Character>) {
		return character?.images?.find((i) => i.isCover);
	}

	public async getCoverImageSrc(character: Partial<Character>) {
		const coverImage = this.getCoverImage(character);
		if (coverImage) {
			const imageSrc = await this._uploadService.getImage(coverImage.imageId!);
			if (imageSrc) {
				return imageSrc;
			}
		}
		return undefined;
	}

	public async searchForList(query: string, orderBy?: QueryOrdering<Character>, includeGroups?: boolean) {
		const response = await searchCharacters(this._apolloClient.instance(), includeGroups ? _FIELDS_CHARACTER_LIST_WITH_GROUPS : _FIELDS_CHARACTER_LIST, {
			query,
			order: orderBy ?? [{ created: "DESC" }],
		});

		const characters = response.data?.searchCharacters.edges.map((e) => e.node) ?? [];

		return characters;
	}

	public asSelectableList(characters: Partial<Character>[], children: (character: Partial<Character>, index: number) => ReactNode | undefined) {
		return characters.map((c, i) => {
			const coverImage = c.images?.at(0);
			return {
				id: c.id,
				size: Size.Large,
				children: children(c, i),
				backgroundPosition: `${coverImage?.positionX ?? 0}% ${coverImage?.positionY ?? 0}%`,
				backgroundSrc: coverImage ? this._uploadService.getImage(coverImage.imageId!) : undefined,
			} as SelectableListItem;
		});
	}

	public async fetchForList(orderBy?: QueryOrdering<Character>, includeGroups?: boolean) {
		const response = await getCharacters(this._apolloClient.instance(), includeGroups ? _FIELDS_CHARACTER_LIST_WITH_GROUPS : _FIELDS_CHARACTER_LIST, {
			where: {
				isSaved: {
					eq: true,
				},
			},
			order: orderBy ?? [{ created: "DESC" }],
		});

		const characters = response.data?.getCharacters.edges.map((e) => e.node) ?? [];

		return characters;
	}

	public async fetchForGeneratorList() {
		const response = await getCharacters(this._apolloClient.instance(), _FIELDS_CHARACTER_LIST, {
			where: {
				isSaved: {
					eq: false,
				},
			},
			order: [{ created: "DESC" }],
		});

		const characters = response.data?.getCharacters.edges.map((e) => e.node) ?? [];

		return characters;
	}

	public async fetchDetails(id: string) {
		const response = await getCharacterById(this._apolloClient.instance(), _FIELDS_CHARACTER_DETAILED, id);

		const character = response.data?.getCharacterById;

		return character;
	}

	public async save(characterToSave: Partial<Character>, fieldsToUpdate: Array<keyof Character> = []) {
		const response = await updateCharacter(this._apolloClient.instance(), _FIELDS_CHARACTER_DETAILED, characterToSave, fieldsToUpdate, true);

		const character = response.data!.updateCharacter.character;

		return character;
	}

	public async setCharacterCoverImage(characterId: string, imageId: string) {
		await setCharacterCoverImage(this._apolloClient.instance(), characterId, imageId);
	}

	public async generateCharacter(request: GenerateCharacterRequest) {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.query<GraphQLQueryResponse<Partial<Character> | null, "generateCharacter">, GenerateCharacterRequest>({
			query: QUERY_GENERATE_CHARACTER,
			variables: request,
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}

		return response.data.generateCharacter ?? undefined;
	}

	public async persistGeneratedCharacters(characters: Partial<Character>[]) {
		await updateCharacters(
			this._apolloClient.instance(),
			`id`,
			characters.map<Partial<Character>>((c) => ({
				id: c.id,
				isSaved: true,
				name: c.name,
				gender: c.gender,
				raceId: c.raceId ?? c.race?.id,
				cultureId: c.cultureId ?? c.culture?.id,
				professionId: c.professionId ?? c.profession?.id,
				personalityId: c.personalityId ?? c.personality?.id,
			})),
			["id", "isSaved", "name", "gender", "race", "culture", "profession", "personality"],
		);
	}

	public async deleteCharacters(characters: Partial<Character>[]) {
		await deleteCharacters(
			this._apolloClient.instance(),
			characters.map((c) => c.id!),
		);
	}

	public async saveCharacterDetail(details: Partial<CharacterDetail>) {
		const response = await updateCharacterDetail(this._apolloClient.instance(), _FIELDS_CHARACTER_DETAIL, details, ["description", "appearance", "personality", "ideals", "bonds", "flaws"]);
		return response.data?.updateCharacterDetail.characterDetail;
	}

	public async saveCharacterNote(note: Partial<CharacterNote>) {
		const response = await updateCharacterNote(this._apolloClient.instance(), _FIELDS_CHARACTER_NOTE, note, ["notes", "items"]);
		return response.data?.updateCharacterNote.characterNote;
	}

	public async saveCharacterRoleplay(roleplay: Partial<CharacterRoleplay>) {
		const response = await updateCharacterRoleplay(this._apolloClient.instance(), _FIELDS_CHARACTER_ROLEPLAY, roleplay, ["backstory", "goals", "secrets"]);
		return response.data?.updateCharacterRoleplay.characterRoleplay;
	}

	public async saveCharacterInfoBlock(character: Partial<Character>) {
		const response = await updateCharacter(
			this._apolloClient.instance(),
			_FIELDS_CHARACTER_DETAILED,
			{
				id: character.id,
				name: character.name,
				age: character.age,
				gender: character.gender,
				isSaved: character.isSaved,
				professionId: character.professionId ?? character.profession?.id,
				personalityId: character.personalityId ?? character.personality?.id,
				raceId: character.raceId ?? character.race?.id,
				cultureId: character.cultureId ?? character.culture?.id,
			},
			["name", "age", "gender", "isSaved", "professionId", "personalityId", "raceId", "cultureId"],
			true,
		);

		return response.data?.updateCharacter.character;
	}

	public async addGroupToCharacter(characterId: string, groupId: string) {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.mutate<GraphQLMutationResponse<Partial<MembersGroups>[] | null, "addMembersGroups", "membersGroups">, { groupId: string; characterId: string }>({
			mutation: MUTATION_ADD_MEMBERS_GROUPS,
			variables: {
				groupId,
				characterId,
			},
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}

		const added = response.data?.addMembersGroups.membersGroups ?? [];

		if (added.length) {
			return added[0];
		}

		return undefined;
	}

	public async removeGroupsFromCharacter(characterId: string, groupIds: string[]) {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.mutate<GraphQLMutationResponse<Partial<MembersGroups>[] | null, "deleteMembersGroups", "ids">, { groupIds: string[]; characterIds: string[] }>({
			mutation: MUTATION_DELETE_MEMBERS_GROUPS_BY_GROUP_ID,
			variables: {
				groupIds,
				characterIds: [characterId],
			},
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}
	}

	public async downloadCsvTemplate() {
		if (!this.csvTemplate) {
			const axios = await this._axiosClient.instance();
			const response = await axios.get<string>(`csv/character/${NIL_UUID}`);

			this.csvTemplate = new Blob([response.data], { type: "text/csv;charset=utf-8" });
		}

		return this.csvTemplate;
	}

	public async exportToCsv(spaceId: string) {
		const axios = await this._axiosClient.instance();
		const response = await axios.get<string>(`csv/character/${spaceId}`);

		return new Blob([response.data], { type: "text/csv;charset=utf-8" });
	}

	public async importFromCsv(spaceId: string, csvFile: File) {
		const axios = await this._axiosClient.instance();
		await axios.post(`csv/character/${spaceId}`, csvFile, {
			headers: {
				"Content-Type": "text/csv",
			},
		});
	}
}

const QUERY_GENERATE_CHARACTER = gql`
	query generateCharacter($blank: Boolean, $name: String, $genders: [Gender], $raceIds: [UUID], $cultureIds: [UUID], $professionIds: [UUID], $personalityIds: [UUID], $groupId: UUID, $save: Boolean) {
		generateCharacter(blank: $blank, name: $name, genders: $genders, raceIds: $raceIds, cultureIds: $cultureIds, professionIds: $professionIds, personalityIds: $personalityIds, groupId: $groupId, save: $save) {
			id
			name
			gender
			culture {
				id
				name
				raceId
			}
			race {
				id
				name
			}
			profession {
				id
				name
			}
			personality {
				id
				name
			}
		}
	}
`;

const MUTATION_ADD_MEMBERS_GROUPS = gql`
	mutation addMembersGroups($groupId: UUID, $characterId: UUID) {
		addMembersGroups(input: { membersGroups: { groupId: $groupId, characterId: $characterId } }) {
			membersGroups {
				id
				group {
					${_FIELDS_GROUP_LIST}
				}
			}
		}
	}
`;

const MUTATION_DELETE_MEMBERS_GROUPS_BY_GROUP_ID = gql`
	mutation deleteMembersGroups($groupIds: [UUID], $characterIds: [UUID]) {
		deleteMembersGroups(input: { groupIds: $groupIds, characterIds: $characterIds }) {
			ids
		}
	}
`;
