import axios, { AxiosInstance } from "axios";
import AuthenticationService from "Services/AuthenticationService";
import ConfigService from "Services/ConfigService";

export default class AxiosClient {
	private readonly _configService: ConfigService;
	private readonly _authService: AuthenticationService;

	private _client?: AxiosInstance;
	private _clientPromise?: Promise<AxiosInstance>;

	constructor(configService: ConfigService, authService: AuthenticationService) {
		this._configService = configService;
		this._authService = authService;
	}

	public async instance() {
		if (this._client) {
			return this._client;
		}

		if (!this._clientPromise) {
			this._clientPromise = new Promise(async (resolve, reject) => {
				const apiConfig = await this._configService.getConfig();

				const client = axios.create({
					baseURL: apiConfig.endpoints.api,
				});

				// Add a request interceptor
				client.interceptors.request.use(async (config) => {
					const tokenPromise = this._authService.getCurrentToken();

					// No token so we dont add any auth headers
					if (tokenPromise) {
						const token = await tokenPromise;
						// Handle refresh token flow if our token is expired
						// if (isAccessTokenExpired(token)) {
						// 	console.log("Token expired, using refresh token to acquire new access token.");
						// 	try {
						// 		token = await codebotsOauth2Service.getTokenFromRefreshToken(token);
						// 		await tokenStore.setTokenData(token);
						// 	} catch {
						// 		await tokenStore.setTokenData(undefined);
						// 		console.error("Failed to get access token from refresh token, redirecting to home screen");
						// 		redirectToHomeForNewAuthFlow();
						// 		return;
						// 	}
						// }

						config.headers = {
							...config.headers,
							authorization: token ? `Bearer ${token}` : "",
						};
					}

					return config;
				}, reject);

				resolve(client);
			});
		}

		return this._clientPromise;
	}
}
