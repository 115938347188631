/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { Group } from "Models/Group";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddGroupInput = {
	group: Partial<Group>;
};
export type AddGroupOutput = GraphQLMutationResponse<Group, "addGroup", "group">;
export type AddGroupsInput = {
	groups: Partial<Group>[];
};
export type AddGroupsOutput = GraphQLMutationResponse<Group[], "addGroups", "groups">;

// Update Types
export type UpdateGroupInput = {
	group: Partial<Group>;
	fieldsToUpdate?: Array<keyof Group>;
	replaceReferences?: boolean;
};
export type UpdateGroupOutput = GraphQLMutationResponse<Group, "updateGroup", "group">;
export type UpdateGroupsInput = {
	groups: Partial<Group>[];
	fieldsToUpdate?: Array<keyof Group>;
	replaceReferences?: boolean;
};
export type UpdateGroupsOutput = GraphQLMutationResponse<Group[], "updateGroups", "groups">;

// Delete Types
export type DeleteGroupInput = {
	id: string;
};
export type DeleteGroupOutput = GraphQLDeleteResponse<"deleteGroup">;
export type DeleteGroupsInput = {
	ids: string[];
};
export type DeleteGroupsOutput = GraphQLBulkDeleteResponse<"deleteGroups">;

// Add Mutations
export const addGroup = async (apollo: Promise<Apollo<unknown>>, returnFields: string, group: Partial<Group>) => {
	const response = await (
		await apollo
	).mutate<AddGroupOutput, AddGroupInput>({
		variables: {
			group,
		},
		mutation: gql`
			fragment GroupOutput on Group {
				${returnFields}
			}
			mutation addGroup($group: GroupInput!) {
				addGroup(input: { group: $group }) {
					group {
						...GroupOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addGroups = async (apollo: Promise<Apollo<unknown>>, returnFields: string, groups: Partial<Group>[]) => {
	const response = await (
		await apollo
	).mutate<AddGroupsOutput, AddGroupsInput>({
		variables: {
			groups,
		},
		mutation: gql`
			fragment GroupsOutput on Group {
				${returnFields}
			}
			mutation addGroups($groups: [GroupInput!]!) {
				addGroups(input: { groups: $groups }) {
					group {
						...GroupsOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateGroup = async (apollo: Promise<Apollo<unknown>>, returnFields: string, group: Partial<Group>, fieldsToUpdate: Array<keyof Group>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateGroupOutput, UpdateGroupInput>({
		variables: {
			group,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment GroupOutput on Group {
				${returnFields}
			}
			mutation updateGroup($group: GroupInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateGroup(input: { group: $group, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					group {
						...GroupOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateGroups = async (apollo: Promise<Apollo<unknown>>, returnFields: string, groups: Partial<Group>[], fieldsToUpdate: Array<keyof Group>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateGroupsOutput, UpdateGroupsInput>({
		variables: {
			groups,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment GroupsOutput on Group {
				${returnFields}
			}
			mutation updateGroups($groups: [GroupInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateGroups(input: { groups: $groups, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					group {
						...GroupsOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteGroup = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteGroupOutput, DeleteGroupInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteGroup($id: UUID!) {
				deleteGroup(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteGroups = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteGroupsOutput, DeleteGroupsInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteGroups($ids: [UUID!]) {
				deleteGroups(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] on begin */
export type SetGroupCoverImageOutput = {
	setGroupCoverImage: {
		id: string;
	};
};
export type SetGroupCoverImageInput = {
	groupId: string;
	imageId: string;
};

export const setGroupCoverImage = async (apollo: Promise<Apollo<unknown>>, groupId: string, imageId: string) => {
	return (await apollo).mutate<SetGroupCoverImageOutput, SetGroupCoverImageInput>({
		variables: {
			groupId,
			imageId,
		},
		mutation: gql`
			mutation setGroupCoverImage($groupId: UUID!, $imageId: UUID!) {
				setGroupCoverImage(input: { groupId: $groupId, imageId: $imageId }) {
					id
				}
			}
		`,
	});
};

/* protected region [Anything else] end */
