import { SelectableListItem } from "Component/Global/Interactive/SelectableList/SelectableList";
import { Group } from "Models/Group";
import { Size } from "Style/Sizing";
import ApolloClient from "Utils/ApolloClient";
import UploadService from "./UploadService";
import { searchGroups, getGroups, getGroupById } from "GraphQL/Queries/GroupQueries";
import { updateGroup, setGroupCoverImage } from "GraphQL/Mutations/GroupMutations";
import { ReactNode } from "react";
import { GraphQLMutationResponse } from "GraphQL/GraphQLResponse";
import { MembersGroups } from "Models/MembersGroups";
import { ApolloError, gql } from "@apollo/client";
import { _FIELDS_GROUP_LIST, _FIELDS_GROUP_DETAILS, _FIELDS_CHARACTER_LIST } from "./GraphQLFields";
import { QueryOrdering } from "GraphQL/GraphQLQuery";

export default class GroupService {
	private readonly _apolloClient: ApolloClient;
	private readonly _uploadService: UploadService;

	constructor(apolloClient: ApolloClient, uploadService: UploadService) {
		this._apolloClient = apolloClient;
		this._uploadService = uploadService;
	}

	public async getCoverImageSrc(group: Partial<Group>) {
		const coverImage = group?.images?.find((i) => i.isCover);
		if (coverImage) {
			const imageSrc = await this._uploadService.getImage(coverImage.imageId!);
			if (imageSrc) {
				return imageSrc;
			}
		}
		return "";
	}

	public asSelectableList(groups: Partial<Group>[], children: (character: Partial<Group>, index: number) => ReactNode | undefined) {
		return groups.map((g, i) => {
			const coverImage = g.images?.at(0);
			return {
				id: g.id,
				size: Size.Large,
				children: children(g, i),
				backgroundPosition: `${coverImage?.positionX ?? 0}% ${coverImage?.positionY ?? 0}%`,
				backgroundSrc: coverImage ? this._uploadService.getImage(coverImage.imageId!) : undefined,
			} as SelectableListItem;
		});
	}

	public async searchForList(query: string) {
		const response = await searchGroups(this._apolloClient.instance(), _FIELDS_GROUP_LIST, {
			query,
			order: [{ name: "ASC" }],
		});

		const groups = response.data?.searchGroups.edges.map((e) => e.node) ?? [];

		return groups;
	}

	public async fetchForList(orderBy?: QueryOrdering<Group>) {
		const response = await getGroups(this._apolloClient.instance(), _FIELDS_GROUP_LIST, {
			order: orderBy ?? [{ created: "DESC" }],
		});

		const groups = response.data?.getGroups.edges.map((e) => e.node) ?? [];

		return groups;
	}

	public async fetchDetails(id: string) {
		const response = await getGroupById(this._apolloClient.instance(), _FIELDS_GROUP_DETAILS, id);

		const group = response.data?.getGroupById;

		return group;
	}

	public async save(groupToSave: Partial<Group>, fieldsToUpdate: Array<keyof Group> = []) {
		const response = await updateGroup(this._apolloClient.instance(), _FIELDS_GROUP_DETAILS, groupToSave, fieldsToUpdate, true);

		const group = response.data!.updateGroup.group;

		return group;
	}

	public async setGroupCoverImage(groupId: string, imageId: string) {
		await setGroupCoverImage(this._apolloClient.instance(), groupId, imageId);
	}

	public async addCharacterToGroup(groupId: string, characterId: string) {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.mutate<GraphQLMutationResponse<Partial<MembersGroups>[] | null, "addMembersGroups", "membersGroups">, { groupId: string; characterId: string }>({
			mutation: MUTATION_ADD_MEMBERS_GROUPS,
			variables: {
				groupId,
				characterId,
			},
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}

		const added = response.data?.addMembersGroups.membersGroups ?? [];

		if (added.length) {
			return added[0];
		}

		return undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async removeCharactersFromGroup(groupId: string, characterIds: string[]) {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.mutate<GraphQLMutationResponse<Partial<MembersGroups>[] | null, "deleteMembersGroups", "ids">, { groupIds: string[]; characterIds: string[] }>({
			mutation: MUTATION_DELETE_MEMBERS_GROUPS_BY_CHARACTER_ID,
			variables: {
				groupIds: [groupId],
				characterIds,
			},
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}
	}
}

const MUTATION_ADD_MEMBERS_GROUPS = gql`
	mutation addMembersGroups($groupId: UUID, $characterId: UUID) {
		addMembersGroups(input: { membersGroups: { groupId: $groupId, characterId: $characterId } }) {
			membersGroups {
				id
				character {
					${_FIELDS_CHARACTER_LIST}
				}
			}
		}
	}
`;

const MUTATION_DELETE_MEMBERS_GROUPS_BY_CHARACTER_ID = gql`
	mutation deleteMembersGroups($groupIds: [UUID], $characterIds: [UUID]) {
		deleteMembersGroups(input: { groupIds: $groupIds, characterIds: $characterIds }) {
			ids
		}
	}
`;
