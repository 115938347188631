import { IBreakpoints } from "./Breakpoint";
import { Colors, createSchemes, IPalette, ISchemes } from "./Color";
import { ISpacing } from "./Sizing";
import { ITypography } from "./Typography";

export interface ITheme {
	spacing: ISpacing;
	palette: IPalette;
	schemes: ISchemes;
	typography: ITypography;
	breakpoints: IBreakpoints;
}

const themeSpacing: ISpacing = {
	xxs: "4px",
	xs: "8px",
	sm: "12px",
	md: "16px",
	lg: "24px",
	xl: "32px",
	xxl: "48px",
	xxxl: "64px",
};

const themePalette: IPalette = {
	primary: Colors.black,
	secondary: Colors.white,

	success: Colors.blue,
	info: Colors.blue,
	warning: Colors.orange,
	error: Colors.orange,

	accent1: Colors.blue,
	accent2: Colors.orange,

	shade1: Colors.grey1,
	shade2: Colors.grey2,
	shade3: Colors.grey3,
};

const themeSchemes = createSchemes(themePalette);
// Cheeky override of using the white text with orange background
themeSchemes.accent2.text = Colors.white;

const themeTypography: ITypography = {
	fontSizes: {
		xxxSmall: "8px",
		xxSmall: "10px",
		xSmall: "12px",
		small: "14px",
		medium: "16px",
		large: "18px",
		xLarge: "24px",
		xxLarge: "36px",
		xxxLarge: "56px",
	},
	fontWeight: {
		bold: 700,
		regular: 500,
		light: 300,
	},
	fontFamily: `'Montserrat', sans-serif`,
	fontFamilyMonospace: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
	fontColor: themePalette.secondary,
	truncate: `
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;`,
	noSelect: `
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: default;`,
};

const themeBreakpoints: IBreakpoints = {
	xxxxxs: "240px",
	xxxxs: "320px",
	xxxs: "380px",
	xxs: "480px",
	xs: "640px",
	sm: "800px",
	md: "1000px",
	lg: "1200px",
	xl: "1600px",
};

const Theme: ITheme = {
	spacing: themeSpacing,
	palette: themePalette,
	schemes: themeSchemes,
	typography: themeTypography,
	breakpoints: themeBreakpoints,
};

export default Theme;
