import React, { useEffect, useState } from "react";
import TextInput, { TextInputProps } from "./TextInput";
import Schema, { ValidationError } from "validate";
import styled, { css } from "styled-components";

export interface ModelBoundTextInputProps<T> extends TextInputProps {
	model: T;
	modelProp: keyof T;
	validationErrors?: ValidationError[];
	validationSchema?: Schema;
}

export default function ModelBoundTextInput<T>(props: ModelBoundTextInputProps<T>) {
	const modelValue = props.model[props.modelProp] ? `${props.model[props.modelProp]}` : undefined;

	const [value, setValue] = useState<string | undefined>(modelValue);
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>(props.validationErrors ?? []);

	const validationMessages = validationErrors?.filter((e) => e.path == props.modelProp) ?? [];

	useEffect(() => {
		setValue(modelValue);
	}, [modelValue]);

	useEffect(() => {
		if (props.validationErrors) {
			setValidationErrors(props.validationErrors);
		}
	}, [props.validationErrors]);

	const performValidation = (val: string | undefined) => {
		if (props.validationSchema) {
			setValidationErrors(props.validationSchema.validate({ ...props.model, [props.modelProp]: val }));
		}
	};

	const handleTextChanged = (val: string) => {
		setValue(val);
		(props.model[props.modelProp] as unknown as string) = val;

		// If we're already invalid, try to validate again
		if (validationMessages.length > 0) {
			performValidation(val);
		}
	};

	const handleBlur = () => {
		performValidation(value);
	};

	return (
		<StyledModelBoundTextInput className={`ModelBoundTextInput ${props.className || ""}`}>
			<StyledTextInput {...props} value={value} onChange={handleTextChanged} $hasError={validationMessages.length > 0} onBlur={handleBlur} />
			{validationMessages.length > 0 && validationMessages.map((e, i) => <ValidationMessage key={i}>{e.message}</ValidationMessage>)}
		</StyledModelBoundTextInput>
	);
}
const ValidationMessage = styled.p`
	${({ theme }) => css`
		text-align: right;
		font-size: ${theme.typography.fontSizes.xSmall};
		color: ${theme.palette.error};
		margin-top: ${theme.spacing.xxs};
	`}
`;

const StyledTextInput = styled(TextInput)<{ $hasError: boolean }>`
	${({ theme, $hasError }) =>
		$hasError
			? css`
					input {
						outline: 1px solid ${theme.palette.error};
					}
			  `
			: ""}
`;

const StyledModelBoundTextInput = styled.div``;
