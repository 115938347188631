import { AlertModalProps } from "Component/Global/Interactive/Modal/AlertModal";
import { ModalProps, ModalType } from "Component/Global/Interactive/Modal/Modal";
import { makeAutoObservable } from "mobx";
import React from "react";
import { IScheme } from "Style/Color";
import Theme from "Style/Theme";
import { v4 as uuid } from "uuid";
import ErrorUtil from "./ErrorUtil";

export class ModalManager {
	private _modals: ModalProps[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	public get modals() {
		return this._modals;
	}

	public hideAllModals() {
		// Don't auto hide any error modals.
		this._modals = this._modals.filter((m) => m.error !== undefined);
	}

	public show(modal: ModalProps) {
		this._modals.push(modal);
	}

	public hide(id: string) {
		const index = this._modals.findIndex((m) => m.id === id);
		if (index >= 0) {
			this._modals.splice(index, 1);
		}
	}

	public showError(error: Error) {
		console.error(error);

		const modalId = uuid();
		this.show({
			id: modalId,
			type: ModalType.Alert,
			title: "Uh oh...",
			children: ErrorUtil.getErrorMessage(error),
			scheme: Theme.schemes.error,
			error: error,
		} as AlertModalProps);
	}

	public showAlert(children: React.ReactNode, title = "Notice", scheme?: IScheme) {
		const modalId = uuid();
		this.show({
			id: modalId,
			type: ModalType.Alert,
			title,
			children,
			scheme,
		} as AlertModalProps);
	}
}

const modalManager = new ModalManager();
export default modalManager;

export function useModalManager() {
	return modalManager;
}
