import { makeAutoObservable } from "mobx";

export default class FocusedTextInputStore {
	private _element?: HTMLElement;

	constructor() {
		makeAutoObservable(this);
	}

	get element() {
		return this._element;
	}

	set element(element: HTMLElement | undefined) {
		this._element = element;
		if (element) {
			element?.scrollTo();
		}
	}

	get hasFocus() {
		return this._element != null;
	}
}
