import React, { useCallback, useEffect, useMemo, useState } from "react";
import Padding from "Component/Global/Content/Padding";
import Button from "Component/Global/Input/Button";
import SearchInput from "Component/Global/Input/SearchInput";
import SelectableList, { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import useDebouncedValue from "Hooks/UseDebouncedValue";
import { Group } from "Models/Group";
import { useNavigate } from "react-router-dom";
import GroupService from "Services/GroupService";
import GeneratorStore from "Stores/GeneratorStore";
import GroupArchetypeStore from "Stores/GroupArchetypeStore";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { NestedRoutes_Groups } from "Utils/Navigation";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";

export interface GroupsFiltersProps {
	className?: string;
	store: GroupArchetypeStore | GeneratorStore;
	onGroupChanged?: (group?: Group) => void;
}

export default function GroupsFilters(props: GroupsFiltersProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const groupService = useDi(GroupService);

	const [isLoading, setIsLoading] = useState(true);
	const [groupSearchQuery, setGroupSearchQuery] = useState("");
	const [groups, setGroups] = useState<Group[]>([]);

	useEffect(() => {
		setIsLoading(true);
		groupService
			.fetchForList()
			.then(setGroups)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => setIsLoading(false));
	}, [groupService]);

	const performSearch = useCallback(
		(query: string) => {
			setIsLoading(true);
			if (query) {
				groupService
					.searchForList(query)
					.then(setGroups)
					.catch((err) => modalManager.showError(err as Error))
					.finally(() => setIsLoading(false));
			} else {
				groupService
					.fetchForList()
					.then(setGroups)
					.catch((err) => modalManager.showError(err as Error))
					.finally(() => setIsLoading(false));
			}
		},
		[groupService],
	);

	const handleNewGroupClicked = () => {
		modalManager.hide("generator-filters");
		navigate(NestedRoutes_Groups.New);
	};

	const handleGroupSelected: SelectableListOnChangeHandler = (selectedItems) => {
		if (selectedItems.length) {
			props.onGroupChanged?.(groups.find((x) => x.id === selectedItems[0].id));
		} else {
			props.onGroupChanged?.(undefined);
		}
	};

	const groupItems = useMemo(() => {
		return groupService.asSelectableList(groups, (g) => g.name);
	}, [groupService, groups]);

	const [groupSearchQueryDebounced, cancelDebounce] = useDebouncedValue(groupSearchQuery, 500);

	const handleSearchEnterPressed = () => {
		cancelDebounce();
		performSearch(groupSearchQuery);
	};

	useEffect(() => {
		performSearch(groupSearchQueryDebounced);
	}, [groupSearchQueryDebounced, performSearch]);

	return (
		<StyledGroupsFilters className={`GroupsFilters ${props.className || ""}`}>
			<Controls>
				<Label>Archetypes</Label>
				<Button size={Size.Small} onClick={handleNewGroupClicked}>
					New group
				</Button>
				<StyledSearchInput placeholder="Search for a group" onChange={setGroupSearchQuery} onEnterPressed={handleSearchEnterPressed} />
			</Controls>
			<StyledPadding padding={theme.spacing.xxs}>
				{isLoading ? (
					<Loader layoutType={LoaderLayout.CenterStretchHeight} />
				) : (
					<StyledSelectableList key="groups-list" items={groupItems} type={SelectableListType.SingleColumn} onChange={handleGroupSelected} verticalAlign={"bottom"} />
				)}
			</StyledPadding>
		</StyledGroupsFilters>
	);
}

const StyledPadding = styled(Padding)`
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: auto;
`;

const StyledSelectableList = styled(SelectableList)``;

const StyledSearchInput = styled(SearchInput)`
	grid-area: search;
`;

const Controls = styled.div`
	${({ theme }) => css`
		flex-grow: 0;
		flex-shrink: 0;
		padding: ${theme.spacing.sm};
		gap: ${theme.spacing.sm};
		display: grid;
		grid-template:
			"label button"
			"search search";
		grid-template-columns: 1fr auto;
	`}
`;

const Label = styled.h3`
	${({ theme }) => css`
		flex-grow: 1;
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;

const StyledGroupsFilters = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
`;
