import React, { CSSProperties, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";

export interface AsyncBackgroundImageProps {
	className?: string;
	backgroundSrc?: string | Promise<string | undefined>;
	children?: ReactNode;
	style?: CSSProperties;
}

export default function AsyncBackgroundImage(props: AsyncBackgroundImageProps) {
	const el = useRef<HTMLDivElement>(null);

	const setBackgroundImage = (src?: string) => {
		if (src && el.current) {
			el.current.style.backgroundImage = `url(${src})` || "";
			el.current.dataset["src"] = src || "";
		}
	};

	useEffect(() => {
		if (props.backgroundSrc === undefined) {
			setBackgroundImage(undefined);
		} else if (typeof props.backgroundSrc === "string") {
			setBackgroundImage(props.backgroundSrc);
		} else {
			props.backgroundSrc.then(setBackgroundImage);
		}
	}, [props.backgroundSrc]);

	return (
		<StyledAsyncBackgroundImage className={`AsyncBackgroundImage ${props.className || ""}`} ref={el} style={props.style}>
			{props.children}
		</StyledAsyncBackgroundImage>
	);
}

const StyledAsyncBackgroundImage = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&[data-src=""],
	&:not([data-src]) {
		display: none !important;
	}
`;
