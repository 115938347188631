import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { generatePath, useNavigate } from "react-router-dom";
import { useDi } from "Utils/DependencyInjection";
import SelectableList, { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import { Character } from "Models/Character";
import { NestedRoutes_Characters } from "Utils/Navigation";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Background from "Static/Image/Characters/empty.jpg";
import CharacterItem from "./CharacterItem";
import EmptyPrompt from "Component/Global/Content/EmptyPrompt";
import CharacterService from "Services/CharacterService";
import { IconName } from "Component/Global/Content/Icon";
import modalManager from "Utils/ModalManager";

export interface CharacterListPageProps {
	className?: string;
}

export default function CharacterListPage(props: CharacterListPageProps) {
	const navigate = useNavigate();
	const characterService = useDi(CharacterService);
	const [isLoading, setIsLoading] = useState(true);
	const [characters, setCharacters] = useState<Character[]>([]);

	useEffect(() => {
		setIsLoading(true);
		characterService
			.fetchForList()
			.then(setCharacters)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => setIsLoading(false));
	}, [characterService]);

	const characterItems = useMemo(() => {
		return characterService.asSelectableList(characters, (c, i) => <CharacterItem character={c} isDisplay={characters.length > 2 ? i === 0 : i < 2} />);
	}, [characterService, characters]);

	const handleCharacterSelected: SelectableListOnChangeHandler = (selection) => {
		if (selection) {
			navigate(generatePath(NestedRoutes_Characters.Details, { id: selection.at(0)!.id }));
		}
	};

	const isEmpty = isLoading || !characters.length;

	return (
		<StyledCharacterListPage
			className={`CharacterListPage ${props.className || ""}`}
			background={characterItems.length ? undefined : Background}
			titleBar={{
				title: "Characters",
				leftIcon: isEmpty ? undefined : IconName.PlusCircle,
				onLeftIconClicked: isEmpty ? undefined : () => navigate(NestedRoutes_Characters.New),
				rightIcon: isEmpty ? undefined : IconName.Search,
				onRightIconClicked: isEmpty ? undefined : () => navigate(NestedRoutes_Characters.Search),
				transparentIcons: true,
			}}
		>
			{isLoading ? (
				<Loader layoutType={LoaderLayout.CenterStretchHeight} />
			) : (
				<>
					{characters.length ? (
						<SelectableList key="character-list" items={characterItems} type={SelectableListType.Display} onChange={handleCharacterSelected} verticalAlign={"bottom"} />
					) : (
						<EmptyPrompt prompt="Lets make a new character." pointer={{ target: "a[data-id='Quick Roll']" }} />
					)}
				</>
			)}
		</StyledCharacterListPage>
	);
}

const StyledCharacterListPage = styled(Page)``;
