import { useCallback, useEffect, useRef, useState } from "react";
import { IBreakpoints } from "Style/Breakpoint";
import { useTheme } from "styled-components";
import useResizeObserver from "./UseResizeObserver";

export type BreakpointResult = {
	smallerThan: Record<keyof IBreakpoints, boolean>;
	largerThan: Record<keyof IBreakpoints, boolean>;
	atLeast: Record<keyof IBreakpoints, boolean>;
	atMost: Record<keyof IBreakpoints, boolean>;
};

function useBreakpoints(element: HTMLElement | null) {
	const [breakpointResult, setBreakpointResult] = useState<BreakpointResult>();

	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	});

	const theme = useTheme();
	const calculateBreakpoint = useCallback(
		(width: number) => {
			if (mounted.current) {
				const result = {
					smallerThan: {},
					largerThan: {},
					atLeast: {},
					atMost: {},
				} as BreakpointResult;

				Object.keys(theme.breakpoints).forEach((i) => {
					const key = i as keyof IBreakpoints;
					const breakpoint = parseInt(theme.breakpoints[key]);

					result.smallerThan[key] = width < breakpoint;
					result.largerThan[key] = width > breakpoint;
					result.atLeast[key] = width >= breakpoint;
					result.atMost[key] = width <= breakpoint;
				});

				setBreakpointResult(result);
			}
		},
		[theme],
	);

	useResizeObserver(element, calculateBreakpoint);

	return breakpointResult;
}

export default useBreakpoints;
