import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import EditableText from "Component/Global/Input/EditableText";
import CoverArt, { CoverArtState } from "Component/Global/Interactive/CoverArt";
import Pivot, { PivotItem } from "Component/Global/Navigation/Pivot/Pivot";
import { Group } from "Models/Group";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import UploadService from "Services/UploadService";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import Padding from "Component/Global/Content/Padding";
import { IconName } from "Component/Global/Content/Icon";
import ModelUtils from "Utils/ModelUtils";
import { NestedRoutes_Characters, NestedRoutes_Groups } from "Utils/Navigation";
import Page from "Component/Global/Navigation/Page";
import TraitsFilters from "Component/Generator/TraitsFilters";
import GroupArchetypeStore from "Stores/GroupArchetypeStore";
import TraitsService from "Services/TraitsService";
import GroupService from "Services/GroupService";
import CharacterService from "Services/CharacterService";
import CharacterItem from "Component/Character/CharacterItem";
import SelectableList, { SelectableListItem, SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Button from "Component/Global/Input/Button";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import modalManager from "Utils/ModalManager";
import { FullScreenModalProps } from "Component/Global/Interactive/Modal/FullScreenModal";
import GroupMemberSearch from "./GroupMemberSearch";
import SearchInput from "Component/Global/Input/SearchInput";
import FocusedTextInputStore from "Stores/FocusedTextInputStore";
import { observer } from "mobx-react-lite";
import useDebouncedValue from "Hooks/UseDebouncedValue";

export interface GroupDetailsPageProps {
	className?: string;
}

export default observer(GroupDetailsPage);
function GroupDetailsPage(props: GroupDetailsPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const uploadService = useDi(UploadService);
	const groupService = useDi(GroupService);
	const characterService = useDi(CharacterService);
	const traitsService = useDi(TraitsService);
	const archetypeStore = useDi(GroupArchetypeStore);
	const focusedTextInputStore = useDi(FocusedTextInputStore);

	const params = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [coverArtState, setCoverArtState] = useState<CoverArtState>(CoverArtState.Collapsed);
	const [isEditingGroups, setIsEditingGroups] = useState(false);
	const [selectedMemberItems, setSelectedMemberItems] = useState<SelectableListItem[]>([]);
	const [memberSearchQuery, setMemberSearchQuery] = useState("");

	const [group, setGroup] = useState<Partial<Group>>();

	const groupId = params.id || "";

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchChanged = (query: string) => {
		setMemberSearchQuery(query);
	};

	// Fetch the group on load
	useEffect(() => {
		groupService
			.fetchDetails(groupId)
			.then(setGroup)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => setIsLoading(false));
	}, [groupId, groupService]);

	// Fetch the traits data on load
	useEffect(() => {
		traitsService
			.getAllFilterData()
			.then((data) => {
				archetypeStore.races = data.getRaces.nodes;
				archetypeStore.cultures = data.getCultures.nodes;
				archetypeStore.personalities = data.getPersonalities.nodes;
				archetypeStore.professions = data.getProfessions.nodes;
			})
			.catch((err) => modalManager.showError(err as Error));
	}, [traitsService, archetypeStore]);

	const loadTraitStoreFromGroup = useCallback(
		(group: Partial<Group> | undefined) => {
			archetypeStore.selectedRaceIds = group?.races?.map((x) => x.id!) ?? [];
			archetypeStore.selectedCultureIds = group?.cultures?.map((x) => x.id!) ?? [];
			archetypeStore.selectedProfessionIds = group?.professions?.map((x) => x.id!) ?? [];
			archetypeStore.selectedPersonalityIds = group?.personalities?.map((x) => x.id!) ?? [];
			archetypeStore.selectedGenders = group?.genders?.map((x) => x.gender!) ?? [];
		},
		[archetypeStore],
	);

	// Load the group's archetypes into the store when the group changes
	useEffect(() => {
		loadTraitStoreFromGroup(group);
	}, [group, loadTraitStoreFromGroup]);

	const handleTraitsStoreUpdated = useCallback(async () => {
		group!.groupsRaces = archetypeStore.selectedRaceIds.map((id) => ({ groupId: group!.id, raceId: id }));
		group!.groupsCultures = archetypeStore.selectedCultureIds.map((id) => ({ groupId: group!.id, cultureId: id }));
		group!.groupsProfessions = archetypeStore.selectedProfessionIds.map((id) => ({
			groupId: group!.id,
			professionId: id,
		}));
		group!.groupsPersonalities = archetypeStore.selectedPersonalityIds.map((id) => ({
			groupId: group!.id,
			personalityId: id,
		}));
		group!.genders = archetypeStore.selectedGenders.map((gender) => ({ groupId: group!.id, gender: gender }));

		setIsLoading(true);

		try {
			const updatedGroup = await groupService.save(ModelUtils.pick(group!, ["id", "name", "groupsRaces", "groupsCultures", "genders", "groupsPersonalities", "groupsProfessions"]), [
				"groupsRaces",
				"groupsCultures",
				"genders",
				"groupsPersonalities",
				"groupsProfessions",
			]);
			setGroup(updatedGroup);
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, [group, archetypeStore, groupService]);

	const handleSaveFields = useCallback(async () => {
		const updatedGroup = await groupService.save(ModelUtils.pick(group!, ["id", "name", "details"]), ["details"]);
		setGroup(updatedGroup);
	}, [groupService, group]);

	const groupMemberItems = useMemo(() => {
		const groupMembers = (group?.members ?? []).filter((c) => (memberSearchQuery ? c.name!.toLowerCase().includes(memberSearchQuery) : true));

		return characterService.asSelectableList(groupMembers, (c, i) => <CharacterItem character={c} isDisplay={groupMembers.length > 2 ? i === 0 : i < 2} />);
	}, [characterService, group?.members, memberSearchQuery]);

	const handleCharacterGroupSelected: SelectableListOnChangeHandler = useCallback(
		(selected) => {
			if (isEditingGroups) {
				setSelectedMemberItems(selected);
			} else {
				setTimeout(() => {
					navigate(generatePath(NestedRoutes_Characters.Details, { id: selected.at(0)!.id }));
				}, 0);
			}
		},
		[isEditingGroups, navigate],
	);

	const handleGroupMemberAdded = useCallback(
		async (characterId: string) => {
			setIsLoading(true);
			try {
				const addedCharacter = await groupService.addCharacterToGroup(group!.id!, characterId);
				if (addedCharacter?.character) {
					setGroup((group) => {
						const updatedGroup = {
							...group,
							members: [...(group?.members ?? []), addedCharacter!.character!],
						};
						return updatedGroup;
					});
				}
			} catch (err) {
				modalManager.showError(err as Error);
			} finally {
				setIsLoading(false);
			}
		},
		[group, groupService],
	);

	const handleAddOrRemoveMemberClicked = useCallback(() => {
		if (isEditingGroups) {
			setIsLoading(true);
			const characterIdsToRemove = selectedMemberItems.map((x) => x.id!);
			groupService
				.removeCharactersFromGroup(group!.id!, characterIdsToRemove)
				.then(() => {
					setGroup((group) => {
						const updatedGroup = {
							...group,
							members: [...(group?.members ?? []).filter((x) => characterIdsToRemove.indexOf(x.id!) === -1)],
						};
						return updatedGroup;
					});
					setSelectedMemberItems([]);
					setIsEditingGroups(false);
				})
				.catch((err) => modalManager.showError(err as Error))
				.finally(() => setIsLoading(false));
		} else {
			modalManager.show({
				id: "groups-add-member",
				type: ModalType.FullScreen,
				children: <GroupMemberSearch existingMemberIds={groupMemberItems.map((c) => c.id!)} onGroupMemberSelected={handleGroupMemberAdded} />,
			} as FullScreenModalProps);
		}
	}, [group, groupMemberItems, groupService, handleGroupMemberAdded, isEditingGroups, selectedMemberItems]);

	const handleEditMembersClicked = useCallback(() => {
		setSelectedMemberItems([]);
		setIsEditingGroups(!isEditingGroups);
	}, [isEditingGroups]);

	const [isTextInputFocused] = useDebouncedValue(focusedTextInputStore.hasFocus, 50);

	useEffect(() => {
		if (coverArtState != CoverArtState.Expanded) {
			setCoverArtState(isTextInputFocused ? CoverArtState.Hidden : CoverArtState.Collapsed);
		}
	}, [coverArtState, isTextInputFocused]);

	const pivotItems: PivotItem[] = useMemo(
		() =>
			group
				? [
						{
							id: "details",
							menuTitle: "Details",
							component: () => (
								<Padding>
									<EditableText
										title="Description"
										onSave={handleSaveFields}
										textItems={[
											{
												placeholder: "Tap to edit or add a group description",
												value: group.details,
												onChange: (val) => (group.details = val),
											},
										]}
									/>
								</Padding>
							),
						},
						{
							id: "members",
							menuTitle: "Members",
							component: () => (
								<CharacterGroups>
									<SearchControls>
										<Top>
											<Label>Groups</Label>
											<Button size={Size.Small} onClick={handleEditMembersClicked} scheme={isEditingGroups ? theme.schemes.accent1 : undefined}>
												{isEditingGroups ? "Save" : "Edit"}
											</Button>
										</Top>
										<Bottom>
											<AddOrRemoveButton
												size={Size.Small}
												onClick={handleAddOrRemoveMemberClicked}
												disabled={isEditingGroups && !selectedMemberItems.length}
												scheme={isEditingGroups ? theme.schemes.accent2 : undefined}
											>
												{isEditingGroups ? "Remove" : "Add"}
											</AddOrRemoveButton>
											<StyledSearchInput placeholder="Search characters" onChange={handleSearchChanged} />
										</Bottom>
									</SearchControls>
									<StyledSelectableList
										items={groupMemberItems}
										type={groupMemberItems.length < 3 ? SelectableListType.SingleColumn : SelectableListType.Display}
										onChange={handleCharacterGroupSelected}
										verticalAlign={"top"}
										multiSelect={isEditingGroups}
										selectedItems={selectedMemberItems}
									/>
								</CharacterGroups>
							),
						},
						{
							id: "archetype",
							menuTitle: "Archetype",
							component: () => <TraitsFilters store={archetypeStore} onStoreUpdated={handleTraitsStoreUpdated} />,
						},
				  ]
				: [],
		[
			archetypeStore,
			group,
			groupMemberItems,
			handleAddOrRemoveMemberClicked,
			handleEditMembersClicked,
			handleCharacterGroupSelected,
			handleSaveFields,
			handleTraitsStoreUpdated,
			isEditingGroups,
			selectedMemberItems,
			theme,
		],
	);

	const coverImage = group?.images?.at(0);

	const totalCharacterGroups = group?.members?.length ?? 0;
	let totalCharacterGroupsText = "1 member";
	if (totalCharacterGroups > 1) {
		totalCharacterGroupsText = `${totalCharacterGroups} members`;
	} else if (totalCharacterGroups === 0) {
		totalCharacterGroupsText = `No members`;
	}

	return (
		<StyledGroupDetailsPage className={`GroupDetailsPage ${props.className || ""}`} tabBar={coverArtState != CoverArtState.Expanded} padding="0">
			{isLoading && <Loader size={Size.Large} layoutType={LoaderLayout.FullScreenOverlay} key="loading-group-details" />}
			{group && (
				<>
					<StyledCoverArt
						title={`${group.name}`}
						subtitle={totalCharacterGroupsText}
						backgroundPosition={`50% ${coverImage?.positionY ?? 0}%`}
						backgroundSrc={coverImage ? uploadService.getImage(coverImage.imageId!) : undefined}
						leftIcon={IconName.ArrowLeft}
						onLeftIconClicked={() => navigate(-1)}
						rightIcon={IconName.Edit}
						onRightIconClicked={() => navigate(generatePath(NestedRoutes_Groups.Edit, { id: group.id }))}
						state={coverArtState}
						onStateChanged={setCoverArtState}
					>
						<Pivot items={pivotItems} />
					</StyledCoverArt>
				</>
			)}
		</StyledGroupDetailsPage>
	);
}

const StyledCoverArt = styled(CoverArt)``;

const CharacterGroups = styled.div``;

const SearchControls = styled(Padding)``;

const StyledSelectableList = styled(SelectableList)`
	padding: ${(props) => props.theme.spacing.xxs};
	box-sizing: border-box;
`;

const Top = styled.div`
	${({ theme }) => css`
		display: flex;
		gap: ${theme.spacing.md};
		align-items: center;
		margin-bottom: ${theme.spacing.md};
	`}
`;

const Bottom = styled.div`
	display: flex;
	gap: ${(props) => props.theme.spacing.md};
	align-items: center;
`;

const Label = styled.h3`
	${({ theme }) => css`
		flex-grow: 1;
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;

const StyledSearchInput = styled(SearchInput)`
	flex-grow: 1;
`;

const AddOrRemoveButton = styled(Button)`
	${({ theme }) => css`
		// These match the values of the search/text input
		height: calc(${theme.spacing.md} * 2 + ${theme.typography.fontSizes.medium});
		border-radius: 18px;
	`}
`;

const StyledGroupDetailsPage = styled(Page)``;
