import Icon, { IconName } from "Component/Global/Content/Icon";
import { motion } from "framer-motion";
import React from "react";
import { Location, NavigateFunction } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

export type TabMenuItemSpecOnClickHandler = (props: { navigate: NavigateFunction; location: Location }) => void;
export type TabMenuItemSpecLinkStateHandler<T> = () => T;
export interface TabMenuItemSpec {
	icon: IconName;
	text: string;
	link?: string;
	linkState?: TabMenuItemSpecLinkStateHandler<unknown>;
	onClick?: TabMenuItemSpecOnClickHandler;
}

export interface TabMenuItemProps {
	className?: string;
	name: string;
	icon: IconName;
	isContextual?: boolean;
	isSelected?: boolean;
}

export default function TabMenuItem(props: TabMenuItemProps) {
	const theme = useTheme();
	const classNames = ["TabMenuItem", props.className || "", props.isContextual ? "contextual" : ""].join(" ");

	return (
		<StyledMenuItem className={classNames} $color={props.isSelected ? theme.palette.accent2 : theme.palette.shade1}>
			<Icon name={props.icon} color={props.isContextual ? theme.schemes.accent1.text : props.isSelected ? theme.palette.accent2 : theme.palette.shade1} />
			<Name>{props.name}</Name>
		</StyledMenuItem>
	);
}

const StyledMenuItem = styled(motion.div)<{ $color: string }>`
	${({ theme, $color }) => {
		return css`
			height: 80px;
			color: ${$color};
			text-align: center;
			font-size: ${theme.typography.fontSizes.xSmall};
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: ${theme.typography.fontWeight.regular};
			position: relative;

			&:hover {
				background: ${theme.schemes.primary.hover};
			}

			&.contextual {
				background: ${theme.palette.accent1};

				color: ${theme.schemes.accent1.text};

				&:after {
					display: none;
				}

				&:hover {
					background: ${theme.schemes.accent1.hover};
				}
			}
		`;
	}}
`;
const Name = styled.div`
	margin-top: ${(props) => props.theme.spacing.xxs};
`;
