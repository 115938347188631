import React, { useCallback, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Search from "Component/Global/Interactive/Search";
import { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Page from "Component/Global/Navigation/Page";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import GroupService from "Services/GroupService";

export interface CharacterGroupsSearchProps {
	className?: string;
	existingGroupIds: string[];
	onGroupSelected: (groupId: string) => void;
}

export default function CharacterGroupsSearch(props: CharacterGroupsSearchProps) {
	const groupService = useDi(GroupService);
	const [isLoading, setIsLoading] = useState(false);

	const performSearch = useCallback(
		async (query: string) => {
			try {
				setIsLoading(true);
				const groups = await groupService.searchForList(query);
				// Filter out the existing groups
				const filteredGroups = groups.filter((c) => props.existingGroupIds.indexOf(c.id) === -1);
				return groupService.asSelectableList(filteredGroups, (g) => g.name);
			} catch (err) {
				modalManager.showError(err as Error);
				return [];
			} finally {
				setIsLoading(false);
			}
		},
		[groupService, props.existingGroupIds],
	);

	const handleCloseClicked = () => {
		modalManager.hide("character-add-group");
	};

	const handleGroupSelected: SelectableListOnChangeHandler = (selectedGroups) => {
		if (selectedGroups.length) {
			props.onGroupSelected(selectedGroups[0].id);
			handleCloseClicked();
		}
	};

	return (
		<StyledCharacterGroupsSearch className={`CharacterGroupsSearch ${props.className || ""}`} titleBar={{ title: "Add to character", rightIcon: IconName.X, onRightIconClicked: handleCloseClicked }}>
			{isLoading && <Loader layoutType={LoaderLayout.FullScreenOverlay} />}
			<Search performSearch={performSearch} selectableListOptions={{ type: SelectableListType.SingleColumn, onChange: handleGroupSelected }} />
		</StyledCharacterGroupsSearch>
	);
}

const StyledCharacterGroupsSearch = styled(Page)``;
