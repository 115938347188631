import { HTMLMotionProps, motion } from "framer-motion";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css, FlattenSimpleInterpolation, useTheme } from "styled-components";

export interface PointerProps {
	className?: string;
	source: HTMLElement | SVGElement | string | null;
	target: HTMLElement | SVGElement | string | null;
	strokeWidth?: number;
	curveRadius?: number;
	arrowSize?: number;
	verticalMargin?: number;
	motionProps?: HTMLMotionProps<"div">;
}

export default function Pointer(props: PointerProps) {
	const theme = useTheme();

	const source = useMemo(() => (typeof props.source === "string" ? document.querySelector(props.source) : props.source), [props.source]);
	const target = useMemo(() => (typeof props.target === "string" ? document.querySelector(props.target) : props.target), [props.target]);

	const strokeWidth = props.strokeWidth ?? 3;
	const curveRadius = props.curveRadius ?? parseInt(theme.spacing.lg);
	const arrowSize = props.arrowSize ?? parseInt(theme.spacing.md);
	const verticalMargin = props.verticalMargin ?? parseInt(theme.spacing.md);

	const [sourceAboveTarget, setSourceAboveTarget] = useState(true);
	const [sourceLeftOfTarget, setSourceLeftOfTarget] = useState(true);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);
	const [endX, setEndX] = useState(0);
	const [endY, setEndY] = useState(0);

	const calculatePositions = useCallback(() => {
		const sourceBounds = source?.getBoundingClientRect();
		const targetBounds = target?.getBoundingClientRect();

		if (!sourceBounds || !targetBounds) {
			return;
		}

		const sourceMidPoint = sourceBounds.left + sourceBounds.width / 2;
		const targetMidPoint = targetBounds.left + targetBounds.width / 2;

		setSourceLeftOfTarget(sourceMidPoint < targetMidPoint);

		if (sourceBounds.bottom < targetBounds.top) {
			// Source is above target
			setSourceAboveTarget(true);

			setStartX(sourceMidPoint);
			setStartY(sourceBounds.bottom + verticalMargin);

			setEndX(targetMidPoint);
			setEndY(targetBounds.top - verticalMargin - strokeWidth);
		} else if (targetBounds.bottom < sourceBounds.top) {
			// Target is above source
			setSourceAboveTarget(false);

			setStartX(targetMidPoint);
			setStartY(targetBounds.bottom + verticalMargin);

			setEndX(sourceMidPoint);
			setEndY(sourceBounds.top - verticalMargin - strokeWidth);
		}
	}, [source, strokeWidth, target, verticalMargin]);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver(() => {
				calculatePositions();
			}),
		[calculatePositions],
	);

	useLayoutEffect(() => {
		if (source && target) {
			calculatePositions();

			resizeObserver.observe(source);
			resizeObserver.observe(target);

			return () => {
				resizeObserver.unobserve(source);
				resizeObserver.unobserve(target);
			};
		}
	}, [calculatePositions, resizeObserver, source, target]);

	const width = Math.abs(startX - endX);
	const height = Math.abs(startY - endY);

	const clientLeft = startX > endX ? endX : startX;
	const clientTop = startY > endY ? endY : startY;

	const dom = (
		<StyledPointer
			className={`Pointer ${props.className || ""}`}
			$sourceAboveTarget={sourceAboveTarget}
			$sourceLeftOfTarget={sourceLeftOfTarget}
			$left={clientLeft}
			$top={clientTop}
			$width={width}
			$height={height}
			$strokeWidth={strokeWidth}
			$curveRadius={curveRadius}
			$arrowSize={arrowSize}
			{...props.motionProps}
		>
			<div />
		</StyledPointer>
	);

	return ReactDOM.createPortal(dom, document.body);
}

type StyledPointerProps = {
	$sourceAboveTarget: boolean;
	$sourceLeftOfTarget: boolean;
	$left: number;
	$top: number;
	$width: number;
	$height: number;
	$strokeWidth: number;
	$curveRadius: number;
	$arrowSize: number;
};

function generateBeforeStyles(props: StyledPointerProps): FlattenSimpleInterpolation | undefined {
	if ((props.$sourceAboveTarget && props.$sourceLeftOfTarget) || (!props.$sourceAboveTarget && !props.$sourceLeftOfTarget)) {
		return css`
			top: 0;
			border-bottom-left-radius: ${props.$curveRadius}px;
			border-bottom: ${props.$strokeWidth}px solid;
		`;
	} else if ((props.$sourceAboveTarget && !props.$sourceLeftOfTarget) || (!props.$sourceAboveTarget && props.$sourceLeftOfTarget)) {
		return css`
			top: 50%;
			border-top-left-radius: ${props.$curveRadius}px;
			border-top: ${props.$strokeWidth}px solid;
		`;
	}
	return undefined;
}

function generateAfterStyles(props: StyledPointerProps): FlattenSimpleInterpolation | undefined {
	if ((props.$sourceAboveTarget && props.$sourceLeftOfTarget) || (!props.$sourceAboveTarget && !props.$sourceLeftOfTarget)) {
		return css`
			top: 50%;
			border-top-right-radius: ${props.$curveRadius}px;
			border-top: ${props.$strokeWidth}px solid;
		`;
	} else if ((props.$sourceAboveTarget && !props.$sourceLeftOfTarget) || (!props.$sourceAboveTarget && props.$sourceLeftOfTarget)) {
		return css`
			top: 0;
			border-bottom-right-radius: ${props.$curveRadius}px;
			border-bottom: ${props.$strokeWidth}px solid;
		`;
	}
	return undefined;
}

function generateArrowHeadStyles(props: StyledPointerProps): FlattenSimpleInterpolation | undefined {
	const rotatedHeadWidth = Math.sqrt(Math.pow(props.$arrowSize, 2) * 2);
	const arrowWidthDiff = rotatedHeadWidth - props.$arrowSize;
	const halfDiff = arrowWidthDiff / 2;
	const arrowHeadMidPoint = rotatedHeadWidth / 2;
	const halfStroke = props.$strokeWidth / 2;

	if (props.$sourceAboveTarget && props.$sourceLeftOfTarget) {
		return css`
			transform: rotate(-45deg);
			right: ${-arrowHeadMidPoint + halfStroke}px;
			bottom: ${-halfStroke}px;
		`;
	} else if (props.$sourceAboveTarget && !props.$sourceLeftOfTarget) {
		return css`
			transform: rotate(-45deg);
			left: ${halfDiff - arrowHeadMidPoint + halfStroke}px;
			bottom: ${-halfStroke}px;
		`;
	} else if (!props.$sourceAboveTarget && props.$sourceLeftOfTarget) {
		return css`
			transform: rotate(135deg);
			right: ${-arrowHeadMidPoint + halfStroke}px;
			top: ${-halfStroke}px;
		`;
	} else if (!props.$sourceAboveTarget && !props.$sourceLeftOfTarget) {
		return css`
			transform: rotate(135deg);
			left: ${halfDiff - arrowHeadMidPoint + halfStroke}px;
			top: ${-halfStroke}px;
		`;
	}
	return undefined;
}

const StyledPointer = styled(motion.div)<StyledPointerProps>`
	${(props) => css`
		box-sizing: border-box;
		left: ${props.$left}px;
		top: ${props.$top}px;
		width: ${props.$width}px;
		height: ${props.$height}px;
		position: absolute;

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			width: 50%;
			height: 50%;
			border-left: ${props.$strokeWidth}px solid white;
			${generateBeforeStyles(props)};
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			width: 50%;
			height: 50%;
			border-right: ${props.$strokeWidth}px solid white;
			${generateAfterStyles(props)};
		}

		> div {
			box-sizing: border-box;
			position: absolute;
			width: ${props.$arrowSize}px;
			height: ${props.$arrowSize}px;
			border-left: ${props.$strokeWidth}px solid white;
			border-bottom: ${props.$strokeWidth}px solid white;
			${generateArrowHeadStyles(props)};
		}
	`}
`;
