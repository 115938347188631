import { useEffect } from "react";

export type OutsideClickCallback = (event: MouseEvent) => void;
export type OutsideClickAdditionalTargets = Array<Element | null>;

const useOutsideClick = (el: HTMLElement | null | undefined, callback: OutsideClickCallback, additionalTargets?: OutsideClickAdditionalTargets) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (el !== undefined && el !== null) {
				const outsideEl = !el.contains(event.target as Element);
				const outsideAdditionalTargets = !additionalTargets?.find((el) => el !== null && el.contains(event.target as Element));

				if (outsideEl && outsideAdditionalTargets) {
					callback?.(event);
				}
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [el, callback, additionalTargets]);
};

export default useOutsideClick;
