import React from "react";
import styled, { css, useTheme } from "styled-components";
import { ReactComponent as Logo } from "Static/Image/logo.svg";
import { SquareButton } from "./Global/Input/Button";
import useInApp from "Hooks/UseInApp";

export interface InAppWarningPageProps {
	className?: string;
	onContinue?: () => void;
}

export default function InAppWarningPage(props: InAppWarningPageProps) {
	const theme = useTheme();
	const { browser, userAgent } = useInApp();
	return (
		<StyledInAppWarningPage className={`InAppWarningPage ${props.className || ""}`}>
			<StyledLogo />
			<Warning>
				<h1>Easy there...</h1>
				<p>We&apos;ve detected you&apos;re trying to access Saga using an &quot;In App&quot; browser.</p>
				<p>For the best experience, we suggest opening Saga in your device&apos;s native browser.</p>
				<p className="small">
					{browser?.toUpperCase() ?? "Unknown browser"} - {userAgent ?? "Unknown browser"}
				</p>
			</Warning>
			<SquareButton isResponsive scheme={theme.schemes.accent2} onClick={props.onContinue}>
				Ignore advice and continue
			</SquareButton>
		</StyledInAppWarningPage>
	);
}

const Warning = styled.div`
	${({ theme }) => css`
		text-align: center;
		h1 {
			font-size: ${theme.typography.fontSizes.xLarge};
			margin: ${theme.spacing.sm};
		}
		p {
			font-size: ${theme.typography.fontSizes.medium};
			margin: ${theme.spacing.sm};

			&.small {
				font-size: ${theme.typography.fontSizes.xxxSmall};
				margin: ${theme.spacing.xl};
			}
		}
	`};
`;

const StyledLogo = styled(Logo)`
	${({ theme }) => css`
		margin: ${theme.spacing.lg} auto;
		text-align: center;
		align-self: center;
		width: 100%;
		max-width: calc(${theme.spacing.xxxl} * 3);
		height: calc(100% - calc(${theme.spacing.lg} * 2));
		path,
		rect {
			fill: ${theme.palette.secondary};
		}
	`};
`;

const StyledInAppWarningPage = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 2fr 1fr auto;
`;
