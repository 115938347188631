import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyDOT2OcVf2c8KMrMQuMY4b50ydrk7Xqduo",
	authDomain: "storytale-fables.firebaseapp.com",
	projectId: "storytale-fables",
	storageBucket: "storytale-fables.appspot.com",
	messagingSenderId: "517269320952",
	appId: "1:517269320952:web:383f886244afa55c3a770d",
};

const app = initializeApp(firebaseConfig);

export default app;
