/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { User } from "Models/User";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddUserInput = {
	user: Partial<User>;
};
export type AddUserOutput = GraphQLMutationResponse<User, "addUser", "user">;
export type AddUsersInput = {
	users: Partial<User>[];
};
export type AddUsersOutput = GraphQLMutationResponse<User[], "addUsers", "users">;

// Update Types
export type UpdateUserInput = {
	user: Partial<User>;
	fieldsToUpdate?: Array<keyof User>;
	replaceReferences?: boolean;
};
export type UpdateUserOutput = GraphQLMutationResponse<User, "updateUser", "user">;
export type UpdateUsersInput = {
	users: Partial<User>[];
	fieldsToUpdate?: Array<keyof User>;
	replaceReferences?: boolean;
};
export type UpdateUsersOutput = GraphQLMutationResponse<User[], "updateUsers", "users">;

// Delete Types
export type DeleteUserInput = {
	id: string;
};
export type DeleteUserOutput = GraphQLDeleteResponse<"deleteUser">;
export type DeleteUsersInput = {
	ids: string[];
};
export type DeleteUsersOutput = GraphQLBulkDeleteResponse<"deleteUsers">;

// Add Mutations
export const addUser = async (apollo: Promise<Apollo<unknown>>, returnFields: string, user: Partial<User>) => {
	const response = await (
		await apollo
	).mutate<AddUserOutput, AddUserInput>({
		variables: {
			user,
		},
		mutation: gql`
			fragment UserOutput on User {
				${returnFields}
			}
			mutation addUser($user: UserInput!) {
				addUser(input: { user: $user }) {
					user {
						...UserOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addUsers = async (apollo: Promise<Apollo<unknown>>, returnFields: string, users: Partial<User>[]) => {
	const response = await (
		await apollo
	).mutate<AddUsersOutput, AddUsersInput>({
		variables: {
			users,
		},
		mutation: gql`
			fragment UsersOutput on User {
				${returnFields}
			}
			mutation addUsers($users: [UserInput!]!) {
				addUsers(input: { users: $users }) {
					user {
						...UsersOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateUser = async (apollo: Promise<Apollo<unknown>>, returnFields: string, user: Partial<User>, fieldsToUpdate: Array<keyof User>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateUserOutput, UpdateUserInput>({
		variables: {
			user,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment UserOutput on User {
				${returnFields}
			}
			mutation updateUser($user: UserInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateUser(input: { user: $user, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					user {
						...UserOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateUsers = async (apollo: Promise<Apollo<unknown>>, returnFields: string, users: Partial<User>[], fieldsToUpdate: Array<keyof User>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateUsersOutput, UpdateUsersInput>({
		variables: {
			users,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment UsersOutput on User {
				${returnFields}
			}
			mutation updateUsers($users: [UserInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateUsers(input: { users: $users, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					user {
						...UsersOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteUser = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteUserOutput, DeleteUserInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteUser($id: UUID!) {
				deleteUser(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteUsers = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteUsersOutput, DeleteUsersInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteUsers($ids: [UUID!]) {
				deleteUsers(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
