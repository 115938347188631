import { useEffect, useRef, useState } from "react";

function useDebouncedValue<T>(value: T, delay?: number): [T, () => void] {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);
	const timer = useRef<NodeJS.Timeout>();

	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
			clearTimer();
		};
	}, []);

	const clearTimer = () => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = undefined;
		}
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			if (mounted.current) {
				setDebouncedValue(value);
			}
		}, delay || 500);

		return () => {
			clearTimer();
		};
	}, [value, delay]);

	return [debouncedValue, clearTimer];
}

export default useDebouncedValue;
