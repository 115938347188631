import React from "react";
import Button from "Component/Global/Input/Button";
import styled, { css, useTheme } from "styled-components";
import { ModalProps } from "./Modal";
import { useModalManager } from "Utils/ModalManager";
import { IScheme } from "Style/Color";
import Padding from "Component/Global/Content/Padding";

export type AlertModalProps = ModalProps & {
	okText?: string;
	scheme?: IScheme;
	onAcknowledged?: () => void;
	title?: string;
};

export default function AlertModal(props: AlertModalProps) {
	const theme = useTheme();
	const modalManager = useModalManager();

	const okText = props.okText ?? "OK";
	const title = props.title ?? "Notice";
	const scheme = props.scheme ?? theme.schemes.accent1;

	const handleOkClicked = () => {
		props.onAcknowledged?.();
		modalManager.hide(props.id);
	};
	return (
		<StyledAlertModal className={`AlertModal ${props.className || ""}`}>
			<Title>{title}</Title>
			<Padding>{props.children && <Content>{props.children}</Content>}</Padding>
			<ConfirmButton scheme={scheme} onClick={handleOkClicked} isResponsive>
				{okText}
			</ConfirmButton>
		</StyledAlertModal>
	);
}

const StyledAlertModal = styled.div`
	text-align: center;
	width: 100%;
`;

const Content = styled.div`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.small};
		margin-bottom: ${theme.spacing.xl};
	`}
`;

const Title = styled.p`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
		margin-top: ${theme.spacing.lg};
	`}
`;

const ConfirmButton = styled(Button)`
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;
