import React, { useEffect, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import TitleBar from "Component/Global/Content/TitleBar";
import Button from "Component/Global/Input/Button";
import SelectableList, { SelectableListItem, SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import styled, { useTheme } from "styled-components";
import modalManager from "Utils/ModalManager";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";

export interface TraitFilterProps {
	className?: string;
	items: SelectableListItem[];
	title: string;
	onItemsSelected: SelectableListOnChangeHandler;
	modalId: string;
	selectedItemIds?: string[];
	showSelectAll?: boolean;
	multiSelect?: boolean;
	isLoading?: boolean;
}

export default function TraitFilter(props: TraitFilterProps) {
	const showSelectAll = props.showSelectAll ?? true;
	const multiSelect = props.multiSelect ?? true;

	const theme = useTheme();

	const [selectedItems, setSelectedItems] = useState<SelectableListItem[]>([]);

	useEffect(() => {
		if (props.selectedItemIds) {
			setSelectedItems(props.items.filter((x) => props.selectedItemIds!.indexOf(x.id) >= 0));
		}
	}, [props.items, props.selectedItemIds]);

	const handleCloseClicked = () => {
		props.onItemsSelected(selectedItems);
		modalManager.hide(props.modalId);
	};

	const handleSelectAllClicked = () => {
		let updatedItems: SelectableListItem[];

		if (props.items.length === selectedItems.length) {
			updatedItems = [];
		} else {
			updatedItems = [...props.items];
		}

		setSelectedItems(updatedItems);
	};

	return (
		<StyledTraitFilter className={`TraitFilter ${props.className || ""}`}>
			<StyledTitleBar title={props.title} leftIcon={showSelectAll ? IconName.CheckSquare : undefined} onLeftIconClicked={showSelectAll ? handleSelectAllClicked : undefined} />
			<Content>
				{props.isLoading ? (
					<Loader layoutType={LoaderLayout.CenterStretchHeight} />
				) : (
					<SelectableList
						items={props.items}
						type={props.items?.length < 4 ? SelectableListType.SingleColumn : SelectableListType.MultiColumns}
						selectedItems={selectedItems}
						onChange={setSelectedItems}
						multiSelect={multiSelect}
					/>
				)}
			</Content>
			<CloseButton onClick={handleCloseClicked} isResponsive scheme={selectedItems.length > 0 ? theme.schemes.accent1 : undefined}>
				{selectedItems.length > 0 ? "Select" : "Close"}
			</CloseButton>
		</StyledTraitFilter>
	);
}

const StyledTitleBar = styled(TitleBar)`
	flex-grow: 0;
	flex-shrink: 0;
`;

const Content = styled.div`
	flex-grow: 1;
	width: 100%;
	height: 100%;
	overflow-y: auto;
`;

const CloseButton = styled(Button)`
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;

const StyledTraitFilter = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};
`;
