import React from "react";
import styled from "styled-components";
import ContentModal, { ContentModalProps } from "./ContentModal";

export type FullScreenModalProps = ContentModalProps;

export default function FullScreenModal(props: FullScreenModalProps) {
	return (
		<StyledFullScreenModal {...props} padding={props.padding ?? "0"} className={`FullScreenModal ${props.className || ""}`}>
			{props.children}
		</StyledFullScreenModal>
	);
}

const StyledFullScreenModal = styled(ContentModal)`
	height: 100%;

	.Padding {
		height: 100%;
	}
`;
