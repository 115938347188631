import Icon, { IconName } from "Component/Global/Content/Icon";
import Button from "Component/Global/Input/Button";
import React from "react";
import { IScheme } from "Style/Color";
import { ITheme } from "Style/Theme";
import styled, { css, useTheme } from "styled-components";

export interface TitleBarProps {
	className?: string;
	title?: string;
	leftIcon?: IconName;
	rightIcon?: IconName;
	onLeftIconClicked?: () => void;
	onRightIconClicked?: () => void;
	transparentIcons?: boolean;
}

export default function TitleBar(props: TitleBarProps) {
	const theme = useTheme();
	return (
		<StyledTitleBar className={`TitleBar ${props.className || ""}`}>
			{props.leftIcon && (
				<LeftButton iconOnly scheme={props.transparentIcons ? transparentScheme : theme.schemes.primary} onClick={props.onLeftIconClicked}>
					<Icon name={props.leftIcon} />
				</LeftButton>
			)}
			{props.title && <Title>{props.title}</Title>}
			{props.rightIcon && (
				<RightButton iconOnly scheme={props.transparentIcons ? transparentScheme : theme.schemes.primary} onClick={props.onRightIconClicked}>
					<Icon name={props.rightIcon} />
				</RightButton>
			)}
		</StyledTitleBar>
	);
}

const transparentScheme: IScheme = {
	active: "rgba(0,0,0,0)",
	hover: "rgba(0,0,0,0)",
	passive: "rgba(0,0,0,0)",
	text: "rgba(0,0,0,0)",
};

const StyledTitleBar = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-areas: "left middle right";
		grid-template-columns: ${`${theme.typography.fontSizes.xxLarge} 1fr ${theme.typography.fontSizes.xxLarge}`};
		align-items: center;
		padding: ${theme.spacing.md};
		box-sizing: border-box;
	`}
`;

const getCommonButtonStyles = (theme: ITheme) => css`
	min-width: unset;
	padding: 0;
	margin: 0;
	border-radius: ${theme.typography.fontSizes.xxLarge};
	width: ${theme.typography.fontSizes.xxLarge};
	height: ${theme.typography.fontSizes.xxLarge};
	position: relative;

	> .Icon {
		position: absolute;
		top: calc(calc(${theme.typography.fontSizes.xxLarge} - 24px) / 2);
		left: calc(calc(${theme.typography.fontSizes.xxLarge} - 24px) / 2);
	}
`;

export const LeftButton = styled(Button)`
	${(props) => getCommonButtonStyles(props.theme)}
	grid-area: left;
`;
export const RightButton = styled(Button)`
	${(props) => getCommonButtonStyles(props.theme)}
	grid-area: right;
`;

const Title = styled.h2`
	${({ theme }) => css`
		grid-area: middle;
		justify-self: center;
		text-align: center;
		font-size: ${theme.typography.fontSizes.xxLarge};
		font-weight: ${theme.typography.fontWeight.regular};
	`}
`;
