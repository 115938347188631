import Padding from "Component/Global/Content/Padding";
import React from "react";
import styled from "styled-components";
import { ModalProps } from "./Modal";

export type ContentModalProps = ModalProps & {
	padding?: string;
};

export default function ContentModal(props: ContentModalProps) {
	return (
		<StyledContentModal className={`ContentModal ${props.className || ""}`}>
			<Padding padding={props.padding}>{props.children}</Padding>
		</StyledContentModal>
	);
}

const StyledContentModal = styled.div``;
