import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import Button from "Component/Global/Input/Button";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";
import styled, { css, useTheme } from "styled-components";
import Icon, { IconName } from "Component/Global/Content/Icon";
import { DefaultTransition } from "Style/Animation";
import { LeftButton, RightButton } from "../Content/TitleBar";
import { ITheme } from "Style/Theme";

export const IMAGE_HEIGHT_COLLAPSED = 128;

export type CoverArtStateChangedHandler = (state: CoverArtState) => void;

export enum CoverArtState {
	Expanded = "Expanded",
	Collapsed = "Collapsed",
	Hidden = "Hidden",
}

export type CoverArtProps = PropsWithChildren<{
	className?: string;
	backgroundSrc?: string | Promise<string | undefined>;
	backgroundPosition?: string;
	title: string;
	description?: string;
	subtitle?: string;
	state?: CoverArtState;
	onStateChanged?: CoverArtStateChangedHandler;
	leftIcon?: IconName;
	rightIcon?: IconName;
	onLeftIconClicked?: () => void;
	onRightIconClicked?: () => void;
}>;

export default function CoverArt(props: CoverArtProps) {
	const theme = useTheme();

	const y = useMotionValue(0);
	const height = useMotionTemplate`calc(100% + ${y}px)`;

	const onStateChanged = props.onStateChanged;

	const [state, setState] = useState<CoverArtState>(props.state ?? CoverArtState.Expanded);
	const [backgroundSrc, setBackgroundSrc] = useState("");

	const styles = useMemo(() => getStylesForState(state, theme), [state, theme]);

	useEffect(() => {
		if (props.backgroundSrc) {
			if (typeof props.backgroundSrc == "string") {
				setBackgroundSrc(props.backgroundSrc);
			} else {
				props.backgroundSrc.then((src) => {
					setBackgroundSrc(src ?? "");
				});
			}
		}
	}, [props.backgroundSrc]);

	useEffect(() => {
		if (props.state) {
			setState(props.state);
		}
	}, [props.state]);

	useEffect(() => {
		if (onStateChanged) {
			onStateChanged(state);
		}
	}, [state, onStateChanged]);

	function handleChange() {
		if (y.get() < -30) {
			setState(CoverArtState.Collapsed);
		}
		if (y.get() > 30) {
			setState(CoverArtState.Expanded);
		}
	}

	return (
		<StyledCoverArt className={`CoverArt ${props.className || ""}`}>
			<Icons animate={styles.Icons} initial={false} transition={DefaultTransition}>
				{props.leftIcon ? (
					<LeftButton iconOnly scheme={theme.schemes.primary} onClick={props.onLeftIconClicked}>
						<Icon name={props.leftIcon} />
					</LeftButton>
				) : (
					<div />
				)}
				{props.rightIcon ? (
					<RightButton iconOnly scheme={theme.schemes.primary} onClick={props.onRightIconClicked}>
						<Icon name={props.rightIcon} />
					</RightButton>
				) : (
					<div />
				)}
			</Icons>
			<Cover animate={styles.Cover} initial={false} drag={"y"} dragConstraints={{ top: 0, bottom: 0 }} style={{ y }} onDragEnd={handleChange} transition={DefaultTransition}>
				{backgroundSrc && (
					<Background
						style={{
							height,
							backgroundPosition: props.backgroundPosition,
							backgroundImage: `url(${backgroundSrc})`,
						}}
					/>
				)}
				<ExpandedCoverContent animate={styles.ExpandedCoverContent} initial={false} transition={DefaultTransition}>
					<Title>{props.title}</Title>
					<Subtitle>{props.subtitle}</Subtitle>
					<Description>{props.description}</Description>
				</ExpandedCoverContent>
				<CollapsedCoverContent animate={styles.CollapsedCoverContent} initial={false} transition={DefaultTransition}>
					{state == CoverArtState.Hidden ? undefined : (
						<>
							<Title>{props.title}</Title>
							<Subtitle>{props.subtitle}</Subtitle>
							<Description>{props.description}</Description>
						</>
					)}
				</CollapsedCoverContent>
				<UpButton iconOnly onClick={() => setState(CoverArtState.Collapsed)} transition={DefaultTransition} animate={styles.UpButton} initial={false}>
					<Icon name={IconName.ArrowUp} />
				</UpButton>
			</Cover>
			<ChildContent animate={styles.ChildContent} initial={false} transition={DefaultTransition}>
				{props.children}
			</ChildContent>
		</StyledCoverArt>
	);
}

const StyledCoverArt = styled(motion.div)`
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	> div {
		flex-grow: 1;
		flex-shrink: 1;
	}
`;

const Cover = styled(motion.div)`
	${({ theme }) => css`
		box-sizing: border-box;
		position: relative;
		background: ${theme.palette.shade3};
		position: relative;
		z-index: 3;
		&,
		& * {
			touch-action: none;
		}
	`};
`;

const Background = styled(motion.div)`
	${({ theme }) => css`
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		z-index: 0;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background: ${theme.palette.primary};
			opacity: 0.4;
		}
	`}
`;

const Icons = styled(motion.div)`
	${({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding: ${theme.spacing.md};
		box-sizing: border-box;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 4;
	`}
`;

const Title = styled(motion.h1)``;
const Subtitle = styled(motion.h2)`
	${({ theme }) => css`
		margin: ${theme.spacing.sm} 0 ${theme.spacing.xs} 0;
		font-weight: ${theme.typography.fontWeight.light};
		font-size: ${theme.typography.fontSizes.medium};
	`}
`;
const Description = styled(motion.h3)`
	${({ theme }) => css`
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.small};
	`}
`;

const ExpandedCoverContent = styled(motion.div)`
	${({ theme }) => css`
		width: calc(100% - ${theme.spacing.lg});
		bottom: ${theme.spacing.xxxl};
		left: ${theme.spacing.lg};

		display: block;
		position: absolute;
		z-index: 4;
	`}
`;

const CollapsedCoverContent = styled(motion.div)`
	${({ theme }) => css`
		width: 100%;
		height: ${theme.spacing.xxxl};
		bottom: calc(calc(${theme.typography.fontSizes.xLarge} / 2) - ${theme.spacing.xxxl});
		left: 0;
		overflow: hidden;
		display: block;
		position: absolute;
		z-index: 4;
		text-align: center;

		${Title} {
			font-size: ${theme.typography.fontSizes.xLarge};
		}

		${Subtitle} {
			font-size: ${theme.typography.fontSizes.xSmall};
			margin: ${theme.spacing.xxs} 0;
		}

		${Description} {
			font-size: ${theme.typography.fontSizes.xxSmall};
		}
	`}
`;

const ChildContent = styled(motion.div)`
	${({ theme }) => css`
		width: 100%;
		overflow: hidden;
		/* position: relative;
		z-index: 3; */
		background: ${theme.palette.primary};
	`};
`;

const UpButton = styled(Button)`
	${({ theme }) => css`
		border-radius: 50%;
		height: ${theme.spacing.xxxl};
		width: ${theme.spacing.xxxl};
		padding: 0;
		margin: 0;
		position: absolute;
		bottom: calc(${theme.spacing.xxxl} / -2);
		left: calc(50% - calc(${theme.spacing.xxxl} / 2));
		z-index: 5;
	`}
`;

interface AnimationStyles {
	Cover: {
		height: string;
		minHeight: string;
		borderBottom: string;
	};
	Background: {
		height: string;
	};
	ChildContent: {
		height: string;
	};
	Icons: {
		opacity: number;
		scale: number;
		display: string;
	};
	UpButton: {
		opacity: number;
	};
	ExpandedCoverContent: {
		opacity: number;
	};
	CollapsedCoverContent: {
		opacity: number;
	};
}

const getStylesForState = (state: CoverArtState, theme: ITheme) => {
	switch (state) {
		case CoverArtState.Expanded:
			return expandedStyles(theme);
		case CoverArtState.Collapsed:
			return collapsedStyles(theme);
		case CoverArtState.Hidden:
			return hiddenStyles(theme);
	}
};

// This is the "default" state (full screen)
const expandedStyles = (theme: ITheme): AnimationStyles => {
	return {
		Cover: {
			height: `100%`,
			minHeight: `calc(${IMAGE_HEIGHT_COLLAPSED}px + ${theme.spacing.xxxl})`,
			borderBottom: `${theme.spacing.xxxl} solid ${theme.palette.primary}`,
		},
		Background: {
			height: `100%`,
		},
		ChildContent: {
			height: "0%",
		},
		Icons: {
			opacity: 0,
			scale: 2,
			display: "flex",
		},
		UpButton: {
			opacity: 1,
		},
		ExpandedCoverContent: {
			opacity: 1,
		},
		CollapsedCoverContent: {
			opacity: 0,
		},
	};
};

// This is when they've swiped up
const collapsedStyles = (theme: ITheme): AnimationStyles => {
	return {
		Cover: {
			height: "0%",
			minHeight: `calc(${IMAGE_HEIGHT_COLLAPSED}px + ${theme.spacing.xxxl})`,
			borderBottom: `${theme.spacing.xxxl} solid ${theme.palette.primary}`,
		},
		Background: {
			height: `150%`,
		},
		ChildContent: {
			height: "100%",
		},
		Icons: {
			opacity: 1,
			scale: 1,
			display: "flex",
		},
		UpButton: {
			opacity: 0,
		},
		ExpandedCoverContent: {
			opacity: 0,
		},
		CollapsedCoverContent: {
			opacity: 1,
		},
	};
};

// This is when a child input is focused (usually)
const hiddenStyles = (theme: ITheme): AnimationStyles => {
	return {
		...collapsedStyles(theme),
		Cover: {
			height: "0%",
			minHeight: "0",
			borderBottom: `0px solid ${theme.palette.primary}`,
		},
		Icons: {
			opacity: 0,
			scale: 1,
			display: "none",
		},
	};
};
