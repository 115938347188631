export type Keyable = string | number | symbol;

export default class ArrayUtil {
	public static toRecord<T>(array: T[], idSelector: keyof T | ((item: T) => Keyable)) {
		const record: Partial<Record<Keyable, T>> = {};

		array.forEach((item) => {
			const key = typeof idSelector === "function" ? idSelector(item) : (item[idSelector] as unknown as Keyable);
			record[key] = item;
		});

		return record as Record<keyof T | Keyable, T>;
	}
}
