import React, { PropsWithChildren, useState } from "react";
import { motion, Variants } from "framer-motion";
import useOutsideClick from "Hooks/UseOutsideClick";
import { DefaultTransition } from "Style/Animation";
import styled, { css } from "styled-components";
import { useModalManager } from "Utils/ModalManager";
import ConfirmModal, { ConfirmModalProps } from "./ConfirmModal";
import ContentModal, { ContentModalProps } from "./ContentModal";
import FullScreenModal, { FullScreenModalProps } from "./FullScreenModal";
import AlertModal, { AlertModalProps } from "./AlertModal";

export enum ModalType {
	Content = "Content",
	Confirm = "Confirm",
	Alert = "Alert",
	FullScreen = "FullScreen",
}

export type ModalProps = PropsWithChildren<{
	className?: string;
	id: string;
	type: ModalType;
	onOutsideClicked?: () => void;
	error?: Error;
}>;

export default function Modal(props: ModalProps | ConfirmModalProps | AlertModalProps | FullScreenModalProps | ContentModalProps) {
	const modalManager = useModalManager();
	const [modalEl, setModalEl] = useState<HTMLDivElement | null>(null);

	useOutsideClick(props.type == ModalType.FullScreen ? null : modalEl, () => {
		modalManager.hide(props.id);
		props.onOutsideClicked?.();
	});

	const modalContent = getModalContent(props);

	return (
		<StyledModal ref={setModalEl} className={`Modal ${props.className || ""}`} $type={props.type} key={props.id} transition={DefaultTransition} variants={variants} exit="bumpOut" animate="stable" initial="bumpIn">
			{modalContent}
		</StyledModal>
	);
}

function getModalContent(props: ModalProps) {
	switch (props.type) {
		case ModalType.FullScreen:
			return <FullScreenModal {...props} />;
		case ModalType.Alert:
			return <AlertModal {...props} />;
		case ModalType.Confirm:
			return <ConfirmModal {...props} />;
		case ModalType.Content:
			return <ContentModal {...props} />;
	}
}

const variants: Variants = {
	bumpIn: {
		y: "100%",
	},
	stable: {
		y: 0,
	},
	bumpOut: {
		y: "100%",
	},
};

const StyledModal = styled(motion.div)<{ $type: ModalType }>`
	${({ theme, $type }) => css`
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
		${$type == ModalType.FullScreen
			? css`
					height: 100%;
					background: ${theme.palette.primary};
			  `
			: css`
					border-top-left-radius: ${theme.spacing.lg};
					border-top-right-radius: ${theme.spacing.lg};
					background: ${theme.schemes.shade2.passive};
					color: ${theme.schemes.shade2.text};
			  `}
	`}
`;
