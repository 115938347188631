import { motion, Variants } from "framer-motion";
import React, { forwardRef } from "react";
import ReactDOM from "react-dom";
import { DefaultTransition } from "Style/Animation";
import { IScheme, transparentize } from "Style/Color";
import { Size } from "Style/Sizing";
import { ITheme } from "Style/Theme";
import styled, { css, useTheme } from "styled-components";

export enum LoaderLayout {
	Inline = "Inline",
	InlineButton = "InlineButton",
	Center = "Center",
	CenterStretchHeight = "CenterStretchHeight",
	FullScreenOverlay = "FullScreenOverlay",
}

export interface LoaderProps {
	className?: string;
	text?: string;
	size?: Size;
	scheme?: IScheme;
	contrastScheme?: boolean;
	layoutType?: LoaderLayout;
}

const Loader = forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
	const theme = useTheme();
	const size = props.size ?? Size.Normal;
	const scheme = props.scheme ?? theme.schemes.secondary;
	const contrastScheme = props.contrastScheme ?? false;
	const layout = props.layoutType ?? LoaderLayout.Inline;

	const loader = (
		<StyledLoader ref={ref} className={`Loader ${props.className || ""}`} transition={DefaultTransition} variants={variants} exit="bumpOut" animate="stable" initial="bumpIn" $layout={layout}>
			<Spinner className="Spinner" $size={size} $scheme={scheme} $contrastScheme={contrastScheme} $layout={layout} />
			{props.text && (
				<Text $layout={layout} $scheme={scheme} $contrastScheme={contrastScheme}>
					{props.text}
				</Text>
			)}
		</StyledLoader>
	);

	if (layout == LoaderLayout.FullScreenOverlay) {
		return ReactDOM.createPortal(loader, document.body);
	} else {
		return loader;
	}
});
export default motion(Loader);

const variants: Variants = {
	bumpIn: {
		opacity: 0.1,
	},
	stable: {
		opacity: 1,
	},
	bumpOut: {
		opacity: 0,
	},
};

const getSize = (size: Size, theme: ITheme): string => {
	switch (size) {
		case Size.Small:
			return theme.spacing.md;
		case Size.Normal:
			return theme.spacing.lg;
		case Size.Large:
			return theme.spacing.xxl;
	}
};

const Text = styled.p<{ $layout: LoaderLayout; $scheme: IScheme; $contrastScheme: boolean }>`
	${({ theme, $layout, $scheme, $contrastScheme }) => {
		const isInline = $layout == LoaderLayout.Inline || LoaderLayout.InlineButton;
		return css`
			display: ${$layout == isInline ? "inline-block" : "block"};
			margin-top: ${$layout == isInline ? "0" : theme.spacing.sm};
			margin-left: ${$layout == isInline ? theme.spacing.sm : "0"};
			text-align: ${$layout == isInline ? "left" : "center"};
			font-size: ${theme.typography.fontSizes.small};
			color: ${$contrastScheme ? $scheme.text : $scheme.passive};
		`;
	}}
`;

const Spinner = styled.div<{ $size: Size; $scheme: IScheme; $contrastScheme: boolean; $layout: LoaderLayout }>`
	${({ theme, $size, $scheme, $contrastScheme, $layout }) => {
		const size = getSize($size, theme);
		const isInline = $layout == LoaderLayout.Inline || LoaderLayout.InlineButton;
		return css`
			&,
			&:after {
				border-radius: 50%;
				vertical-align: middle;
				width: ${size};
				height: ${size};
			}
			& {
				display: ${$layout == isInline ? "inline-block" : "block"};
				margin: ${$layout == isInline ? 0 : "0 auto"};
				border-top: calc(${size} * 0.2) solid ${theme.palette.shade1};
				border-right: calc(${size} * 0.2) solid ${theme.palette.shade1};
				border-bottom: calc(${size} * 0.2) solid ${theme.palette.shade1};
				border-left: calc(${size} * 0.2) solid ${$contrastScheme ? $scheme.text : $scheme.passive};

				-webkit-transform: translateZ(0);
				-ms-transform: translateZ(0);
				transform: translateZ(0);
				-webkit-animation: spinnerRotate 1s infinite linear;
				animation: spinnerRotate 1s infinite linear;
			}
		`;
	}}
`;

const StyledLoader = styled(motion.div)<{ $layout: LoaderLayout }>`
	${({ theme, $layout }) => {
		const isInline = $layout == LoaderLayout.Inline || $layout == LoaderLayout.InlineButton;
		if (isInline) {
			return css`
				display: inline-block;
				margin-left: ${LoaderLayout.InlineButton ? theme.spacing.sm : 0};
			`;
		} else {
			return css`
				box-sizing: border-box;
				height: ${$layout == LoaderLayout.Center ? "auto" : "100%"};
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				${$layout == LoaderLayout.FullScreenOverlay
					? css`
							background-color: ${transparentize(theme.palette.primary, 0.5)};
							position: absolute;
							left: 0;
							top: 0;
							z-index: 20;
					  `
					: ""}
			`;
		}
	}}

	@-webkit-keyframes spinnerRotate {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes spinnerRotate {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`;
