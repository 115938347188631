import { motion } from "framer-motion";
import React, { forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "Services/AuthenticationService";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { PageRoutes } from "Utils/Navigation";

export interface LogoutPageProps {
	className?: string;
}

const LogoutPage = forwardRef<HTMLDivElement, LogoutPageProps>((props, ref) => {
	const authService = useDi(AuthenticationService);
	const navigate = useNavigate();

	useEffect(() => {
		authService
			.signOut()
			.then(() => navigate(PageRoutes.Home, { replace: true }))
			.catch((err) => modalManager.showError(err as Error));
	});

	return <StyledLogoutPage ref={ref} className={`LogoutPage ${props.className || ""}`}></StyledLogoutPage>;
});
export default motion(LogoutPage);

const StyledLogoutPage = styled.div``;
