import React, { useMemo } from "react";
import { Character } from "Models/Character";
import CharacterService from "Services/CharacterService";
import styled, { css } from "styled-components";

import { useDi } from "Utils/DependencyInjection";
import { observer } from "mobx-react";
import CharacterPageStore from "Stores/CharacterPageStore";
import useBreakpoints, { BreakpointResult } from "Hooks/UseBreakpoints";
import AsyncBackgroundImage from "Component/Global/Content/AsyncBackgroundImage";

export type onSelectedHandler = (character?: Character) => void;
export interface CharacterVirtualListItemProps {
	className?: string;
	character: Character;
	isSelected?: boolean;
}

export default observer(CharacterVirtualListItem);
function CharacterVirtualListItem(props: CharacterVirtualListItemProps) {
	const store = useDi(CharacterPageStore);
	const characterService = useDi(CharacterService);

	const imageSrc = useMemo(() => characterService.getCoverImageSrc(props.character), [characterService, props.character]);

	const handleOnSelected = () => {
		if (props.isSelected) {
			store.character = undefined;
		} else {
			store.character = props.character;
		}
	};

	const breakpoints = useBreakpoints(store.listEl);

	return (
		<StyledCharacterVirtualListItem className={`CharacterVirtualListItem ${props.className || ""} ${props.isSelected ? "selected" : ""}`} onClick={handleOnSelected} $breakpoints={breakpoints}>
			<BackgroundImage backgroundSrc={imageSrc} />
			<Image backgroundSrc={imageSrc} />
			<Name>{props.character.name}</Name>
			<Culture>
				{props.character.culture?.name} {props.character.race?.name}
			</Culture>
			<Profession>{props.character.profession?.name}</Profession>
			<Groups>
				{props.character.groups
					?.map((g) => g.name)
					.sort((a, b) => a?.localeCompare(b ?? "") ?? 0)
					.join(", ") ?? ""}
			</Groups>
		</StyledCharacterVirtualListItem>
	);
}

const NoImage = styled.div``;
const Image = styled(AsyncBackgroundImage)`
	${({ theme }) => css`
		&,
		&:after {
			background-size: cover;
			width: ${theme.spacing.xxxl};
			height: ${theme.spacing.xxxl};
			border-radius: 9999px;
			z-index: 1;
		}
	`}
`;
const BackgroundImage = styled(AsyncBackgroundImage)`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
	filter: blur(24px);
`;
const Name = styled.div`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.large};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;
const Culture = styled.div``;
const Profession = styled.div``;
const Groups = styled.div``;

const StyledCharacterVirtualListItem = styled.div<{ $breakpoints?: BreakpointResult }>`
	${({ theme, $breakpoints }) => css`
		overflow: hidden;
		position: relative;
		border-radius: 9999px;
		height: ${theme.spacing.xxxl};
		background-color: ${theme.schemes.shade1.passive};
		color: ${theme.schemes.shade1.text};
		cursor: pointer;
		display: grid;
		column-gap: ${theme.spacing.sm};
		margin: ${theme.spacing.sm} 0;
		margin-right: ${theme.spacing.md};
		border: 2px solid ${theme.palette.shade3};

		${$breakpoints?.atLeast.xs &&
		css`
			grid-template-columns: ${theme.spacing.xxxl} 2fr 1fr 1fr 2fr;
			align-items: center;
		`}

		${$breakpoints?.smallerThan.xs &&
		css`
			grid-template-areas:
				"image name"
				"image culture";

			grid-template-columns: ${theme.spacing.xxxl} 1fr;

			${Image}, ${NoImage} {
				grid-area: image;
			}
			${Name} {
				grid-area: name;
				align-self: end;
			}
			${Culture} {
				grid-area: culture;
				color: rgba(255, 255, 255, 0.6);
			}
			${Profession}, ${Groups} {
				display: none;
			}
		`}

		&.selected {
			border-color: ${theme.palette.accent2};

			${Image}:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-top: 2px solid ${theme.palette.accent2};
				border-right: 2px solid ${theme.palette.accent2};
				transform: rotate(45deg);
			}
		}

		> div {
			z-index: 1;
		}
	`}
`;
