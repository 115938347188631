import { motion, TargetAndTransition, VariantLabels } from "framer-motion";
import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from "react";
import { IScheme } from "Style/Color";
import { Size } from "Style/Sizing";
import { ITheme } from "Style/Theme";
import styled, { css, useTheme } from "styled-components";

export type ButtonProps = PropsWithChildren<{
	className?: string;
	scheme?: IScheme;
	isResponsive?: boolean;
	onClick?: () => void;
	size?: Size;
	iconOnly?: boolean;
	type?: ButtonHTMLAttributes<unknown>["type"];
	disabled?: boolean;
	tapAnimation?: boolean;
}>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
	const theme = useTheme();
	const scheme = props.disabled ? theme.schemes.shade2 : props.scheme ?? theme.schemes.shade2;
	const size = props.size ?? Size.Normal;
	const tapAnimation = props.tapAnimation ?? true;

	return (
		<StyledButton
			ref={ref}
			className={`Button ${props.className || ""} ${props.iconOnly ? "iconOnly" : ""}`}
			onClick={props.onClick}
			$size={size}
			$scheme={scheme}
			$isResponsive={props.isResponsive}
			$iconOnly={props.iconOnly}
			type={props.type}
			disabled={props.disabled}
			whileTap={tapAnimation ? (props.iconOnly ? sizeTapAnimationMapIconOnly[size] : sizeTapAnimationMap[size]) : undefined}
		>
			{props.children}
		</StyledButton>
	);
});
export default motion(Button);

const sizeTapAnimationMap: Record<Size, VariantLabels | TargetAndTransition> = {
	Small: {
		scale: 0.9,
	},
	Normal: {
		scale: 0.95,
	},
	Large: {
		scale: 0.98,
	},
};

const sizeTapAnimationMapIconOnly: Record<Size, VariantLabels | TargetAndTransition> = {
	Small: {
		scale: 0.7,
	},
	Normal: {
		scale: 0.8,
	},
	Large: {
		scale: 0.9,
	},
};

type ButtonSizeSpec = {
	height: string;
	minWidth: string;
	borderRadius: string;
	fontSize: string;
	fontWeight: number;
	padding: string;
};

const getSize = (size: Size, theme: ITheme): ButtonSizeSpec => {
	if (size == Size.Small) {
		return {
			height: "36px",
			minWidth: "76px",
			borderRadius: "18px",
			fontSize: theme.typography.fontSizes.small,
			fontWeight: theme.typography.fontWeight.regular,
			padding: theme.spacing.sm,
		};
	} else if (size == Size.Normal) {
		return {
			height: "60px",
			minWidth: "128px",
			borderRadius: "16px",
			fontSize: theme.typography.fontSizes.large,
			fontWeight: theme.typography.fontWeight.bold,
			padding: theme.spacing.lg,
		};
	} else if (size == Size.Large) {
		return {
			height: "80px",
			minWidth: "128px",
			borderRadius: "16px",
			fontSize: theme.typography.fontSizes.large,
			fontWeight: theme.typography.fontWeight.bold,
			padding: theme.spacing.lg,
		};
	}
	throw new Error("Invalid size");
};

export const SquareButton = styled(Button)`
	border-radius: unset !important;
`;

const StyledButton = styled(motion.button)<{
	$size: Size;
	$scheme: IScheme;
	$isResponsive?: boolean;
	$iconOnly?: boolean;
}>`
	${({ $size, $scheme, $isResponsive, $iconOnly, theme }) => {
		const size = getSize($size, theme);
		return css`
			overflow: hidden;
			display: inline-block;
			box-sizing: border-box;
			min-width: ${size.minWidth};
			border: none;
			outline: none;
			background: ${$scheme.passive};
			color: ${$scheme.text};
			padding: 0 ${size.padding};
			border-radius: ${size.borderRadius};
			font-family: ${theme.typography.fontFamily};
			font-weight: ${size.fontWeight};
			font-size: ${size.fontSize};
			width: ${$isResponsive ? "100%" : "fit-content"};
			height: ${size.height};
			line-height: ${size.fontSize};
			text-align: center;
			cursor: pointer;

			&:active {
				background: ${$scheme.text};
			}

			&:hover {
				background: ${$scheme.hover};
			}

			${$iconOnly
				? css`
						min-width: 0;
				  `
				: css`
						.Icon {
							margin-right: ${theme.spacing.sm};
						}
				  `}
		`;
	}}
`;
