import React, { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

export interface AsyncImageProps {
	className?: string;
	src?: string | Promise<string | undefined>;
	fallback?: ReactNode;
}

export default function AsyncImage(props: AsyncImageProps) {
	const el = useRef<HTMLImageElement>(null);

	const setImage = (src?: string) => {
		if (src && el.current) {
			el.current.src = src || "";
		}
	};

	useEffect(() => {
		if (props.src === undefined) {
			setImage(undefined);
		} else if (typeof props.src === "string") {
			setImage(props.src);
		} else {
			props.src.then(setImage);
		}
	}, [props.src]);

	return (
		<>
			<StyledAsyncImage className={`AsyncImage ${props.className || ""}`} ref={el} $hasFallback={props.fallback !== undefined} />
			{props.fallback}
		</>
	);
}

const StyledAsyncImage = styled.img<{ $hasFallback: boolean }>`
	&[src=""],
	&:not([src]) {
		display: none !important;
	}

	${(props) =>
		props.$hasFallback
			? css`
					& + * {
						display: none !important;
					}

					&[src=""] + *,
					&:not([src]) + * {
						display: unset !important;
					}
			  `
			: ""}
`;
