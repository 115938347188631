/**
 * @generated
 */
import { AbstractModel, ModelValidationErrors, validateAbstractModel } from "Models/AbstractModel";
import { Character } from "Models/Character";
import { GroupImage } from "Models/GroupImage";
import { GroupGender } from "Models/GroupGender";
import { MembersGroups } from "Models/MembersGroups";
import { Race } from "Models/Race";
import { Culture } from "Models/Culture";
import { Personality } from "Models/Personality";
import { Profession } from "Models/Profession";
import { GroupsRaces } from "Models/GroupsRaces";
import { GroupsCultures } from "Models/GroupsCultures";
import { GroupsPersonalities } from "Models/GroupsPersonalities";
import { GroupsProfessions } from "Models/GroupsProfessions";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

export interface Group extends AbstractModel {
	name: string;
	details?: string;
	spaceId?: string;

	// Outgoing many-to-many
	membersGroups?: Partial<MembersGroups>[];
	members?: Partial<Character>[];

	// Outgoing one-to-many
	images?: Partial<GroupImage>[];

	// Outgoing one-to-many
	genders?: Partial<GroupGender>[];

	// Incoming many-to-many
	groupsRaces?: Partial<GroupsRaces>[];
	races?: Partial<Race>[];

	// Incoming many-to-many
	groupsCultures?: Partial<GroupsCultures>[];
	cultures?: Partial<Culture>[];

	// Incoming many-to-many
	groupsPersonalities?: Partial<GroupsPersonalities>[];
	personalities?: Partial<Personality>[];

	// Incoming many-to-many
	groupsProfessions?: Partial<GroupsProfessions>[];
	professions?: Partial<Profession>[];

	/* protected region [Any additional model configuration] off begin */
	/* protected region [Any additional model configuration] end */
}

export function validateGroup(model: Partial<Group>) {
	const validationErrors: ModelValidationErrors<Group> = validateAbstractModel(model) ?? {};

	/* protected region [Customise the required validation logic here] on begin */
	if (!model.name) {
		validationErrors.name = "Name is required";
	}
	/* protected region [Customise the required validation logic here] end */

	/* protected region [Customise additional validation logic here] off begin */
	/* protected region [Customise additional validation logic here] end */

	return Object.keys(validationErrors).length > 0 ? validationErrors : undefined;
}

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
