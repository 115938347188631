import React from "react";
import Padding from "Component/Global/Content/Padding";
import styled, { css, useTheme } from "styled-components";
import CharacterStatBlock from "./CharacterStatBlock";
import { observer } from "mobx-react";
import CharacterPageStore from "Stores/CharacterPageStore";
import { useDi } from "Utils/DependencyInjection";
import TextInput from "Component/Global/Input/TextInput";
import useBreakpoints, { BreakpointResult } from "Hooks/UseBreakpoints";

export interface CharacterDetailsAboutProps {
	className?: string;
}

export default observer(CharacterDetailsAbout);
function CharacterDetailsAbout(props: CharacterDetailsAboutProps) {
	const theme = useTheme();
	const store = useDi(CharacterPageStore);

	const breakpoints = useBreakpoints(store.detailsEl);

	const handleDescriptionChanged = (val: string) => {
		store.character = {
			...store.character!,
			details: {
				...store.character!.details!,
				description: val,
			},
		};
	};

	return (
		<StyledCharacterDetailsAbout className={`CharacterDetailsAbout ${props.className || ""}`} $breakpoints={breakpoints}>
			<StyledPadding padding={theme.spacing.lg}>
				<CharacterStatBlock />
				{store.isEditing ? (
					<TextInput label="Description" value={store.character?.details?.description} onChange={handleDescriptionChanged} multiline />
				) : (
					<Description>{store.character?.details?.description ?? "No description."}</Description>
				)}
			</StyledPadding>
		</StyledCharacterDetailsAbout>
	);
}

const StyledPadding = styled(Padding)``;

const Description = styled.p`
	${({ theme }) => css`
		margin-top: ${theme.spacing.xl};
		font-weight: ${theme.typography.fontWeight.light};
		font-size: ${theme.typography.fontSizes.xSmall};
		line-height: ${theme.typography.fontSizes.xLarge};
	`}
`;
const StyledCharacterDetailsAbout = styled.div<{ $breakpoints?: BreakpointResult }>`
	${({ theme, $breakpoints }) => css`
		.SelectableList {
			margin-bottom: ${theme.spacing.lg};
		}
		${$breakpoints?.largerThan?.xs &&
		css`
			${StyledPadding} {
				gap: ${theme.spacing.md};
				display: grid !important;
				grid-template-columns: 2fr 1fr;

				.CharacterStatBlock {
					order: 1;
				}

				.TextInput {
					order: -1;
				}

				.SelectableList {
					margin-bottom: 0;
					&:before {
						content: "Details";
						display: block;
						width: 100%;
						color: ${theme.schemes.shade2.text};
						font-size: ${theme.typography.fontSizes.small};
						font-weight: ${theme.typography.fontWeight.bold};
						margin-bottom: ${theme.spacing.xxs};
					}
				}
			}
		`}
	`}
`;
