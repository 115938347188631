import React, { useEffect } from "react";
import { observer } from "mobx-react";
import CharacterPageStore, { ColumnLayout } from "Stores/CharacterPageStore";
import styled, { css } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import CharacterCover from "./CharacterCover";
import CharacterDetails from "./CharacterDetails";
import CharacterVirtualList from "./CharacterVirtualList";

export interface CharacterPageProps {
	className?: string;
}

export default observer(CharacterPage);
function CharacterPage(props: CharacterPageProps) {
	const store = useDi(CharacterPageStore);
	const character = store.character;

	useEffect(() => {
		if (character) {
			store.patchColumnLayout({ cover: true });
		} else {
			store.columnLayout = { list: true };
		}
	}, [character, store]);

	return (
		<StyledCharacterPage className={`CharacterPage ${props.className || ""}`} $columnLayout={store.columnLayout}>
			<StyledCharacterVirtualList />
			{store.columnLayout.cover && <StyledCharacterCover />}
			{store.columnLayout.details && <StyledCharacterDetails />}
		</StyledCharacterPage>
	);
}

function getGridTemplateColumns(layout: ColumnLayout) {
	if (layout.list && layout.cover && layout.details === "expanded") {
		return "1fr 1fr 2fr";
	} else if (layout.list && layout.cover && layout.details) {
		return "1fr 1fr 1fr";
	} else if (layout.cover && layout.details === "expanded") {
		return "1fr 1fr";
	} else if (layout.cover && layout.details) {
		return "2fr 1fr";
	} else if (layout.list && layout.cover) {
		return "1fr 2fr";
	} else if (layout.list || layout.cover) {
		return "1fr";
	}
}

const StyledCharacterVirtualList = styled(CharacterVirtualList)``;
const StyledCharacterCover = styled(CharacterCover)``;
const StyledCharacterDetails = styled(CharacterDetails)``;

const StyledCharacterPage = styled.div<{ $columnLayout: ColumnLayout }>`
	${({ theme, $columnLayout }) => css`
		background: ${theme.palette.shade3};
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: ${getGridTemplateColumns($columnLayout)};

		${!$columnLayout.list &&
		css`
			${StyledCharacterVirtualList} {
				display: none;
			}
		`}
	`}
`;
