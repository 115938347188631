import React, { CSSProperties, useMemo } from "react";
import CharacterService from "Services/CharacterService";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import Button from "Component/Global/Input/Button";
import Icon, { IconName } from "Component/Global/Content/Icon";
import { IScheme } from "Style/Color";
import CharacterPageStore from "Stores/CharacterPageStore";
import AsyncBackgroundImage from "Component/Global/Content/AsyncBackgroundImage";

export interface CharacterCoverProps {
	className?: string;
}

export default function CharacterCover(props: CharacterCoverProps) {
	const theme = useTheme();

	const store = useDi(CharacterPageStore);
	const characterService = useDi(CharacterService);

	const imageSrc = useMemo(() => (store.character ? characterService.getCoverImageSrc(store.character) : undefined), [characterService, store.character]);

	if (!store.character) {
		return null;
	}

	const handleCloseClicked = () => {
		store.character = undefined;
	};

	const handleMoreInfoClicked = () => {
		store.patchColumnLayout({ details: true });
	};

	const handleLeftColumnToggled = () => {
		store.patchColumnLayout({ list: !store.columnLayout?.list });
	};

	const isLeftColumnVisible = store.columnLayout.list;
	const isDetailsVisible = store.columnLayout.details;

	const scheme = isLeftColumnVisible ? undefined : theme.schemes.accent2;

	const backgroundStyles: CSSProperties = {
		backgroundPosition: `center ${characterService.getCoverImage(store.character)?.positionY ?? "center"}`,
	};

	return (
		<StyledCharacterCover className={`CharacterCover ${props.className || ""}`} backgroundSrc={imageSrc} style={backgroundStyles}>
			<Controls>
				<RoundButton iconOnly onClick={handleLeftColumnToggled} $scheme={scheme}>
					<Icon name={IconName.Columns} color={scheme ? scheme.passive : undefined} />
				</RoundButton>
				<RoundButton iconOnly onClick={handleCloseClicked}>
					<Icon name={IconName.X} />
				</RoundButton>

				{!isDetailsVisible && (
					<MoreInfo onClick={handleMoreInfoClicked}>
						More info
						{imageSrc && <Image backgroundSrc={imageSrc} />}
					</MoreInfo>
				)}
			</Controls>
			<Details>
				<Title>{store.character.title}</Title>
				<Name>{store.character.name}</Name>
				<Descriptor>{characterService.getDescriptor(store.character)}</Descriptor>
			</Details>
		</StyledCharacterCover>
	);
}

export const RoundButton = styled(Button)<{ $scheme?: IScheme }>`
	${({ theme, $scheme }) => css`
		border-radius: 9999px;
		width: ${theme.spacing.xxl};
		height: ${theme.spacing.xxl};
		border: 2px solid ${$scheme ? $scheme.passive : theme.palette.secondary};
		background: none;
		padding: 0;

		&:hover {
			background: none;
		}
	`}
`;

const Image = styled(AsyncBackgroundImage)`
	${({ theme }) => css`
		display: inline-block;
		border-radius: ${theme.spacing.xxl};
		vertical-align: middle;
		width: ${theme.spacing.xxl};
		height: ${theme.spacing.xxl};
		margin-left: ${theme.spacing.md};
	`}
`;

const MoreInfo = styled(Button)`
	background: none;
	font-weight: ${(props) => props.theme.typography.fontWeight.regular};
	font-size: ${(props) => props.theme.typography.fontSizes.medium};
	&:hover {
		background: none;
	}
`;

const Controls = styled.div`
	${({ theme }) => css`
		display: grid;
		gap: ${theme.spacing.md};
		grid-template-columns: auto 1fr auto;
	`}
`;

const Title = styled.h3`
	${({ theme }) => css`
		margin: 0;
		font-style: italic;
		font-weight: ${theme.typography.fontWeight.light};
		font-size: ${theme.typography.fontSizes.xLarge};
	`}
`;
const Name = styled.h2`
	${({ theme }) => css`
		margin: 0;
		word-break: break-word;
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.xxxLarge};
	`}
`;
const Descriptor = styled.h4`
	${({ theme }) => css`
		margin: 0;
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.medium};
	`}
`;

const Details = styled.div`
	align-self: end;
`;

const StyledCharacterCover = styled(AsyncBackgroundImage)`
	${({ theme }) => css`
		background-size: cover;
		background-position: center center;
		box-sizing: border-box;
		padding: ${theme.spacing.lg};
		display: grid;
		grid-template-rows: auto 1fr;
	`}
`;
