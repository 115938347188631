import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import CharacterItem from "Component/Character/CharacterItem";
import EmptyPrompt from "Component/Global/Content/EmptyPrompt";
import Icon, { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import StackedButtonGroup from "Component/Global/Content/StackedButtonGroup";
import Button from "Component/Global/Input/Button";
import { AlertModalProps } from "Component/Global/Interactive/Modal/AlertModal";
import { ModalType, ModalProps } from "Component/Global/Interactive/Modal/Modal";
import SelectableList, { SelectableListItem, SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import { useLocation, useNavigate } from "react-router-dom";
import CharacterService from "Services/CharacterService";
import GeneratorStore from "Stores/GeneratorStore";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { PageRoutes } from "Utils/Navigation";
import GeneratorFilters from "./GeneratorFilters";
import { observer } from "mobx-react";
import TraitsService from "Services/TraitsService";
import useLoader from "Hooks/UseLoader";

export interface GeneratorProps {
	className?: string;
	rollPrompt?: ReactNode;
	savePrompt?: ReactNode;
	canSelectAndSave?: boolean;
	isSaveMode?: boolean;
	isLoading?: boolean;
	selectedCharacterIds?: string[];
	setSelectedCharacterIds?: (ids: string[]) => void;
	onCharactersDeleted?: () => void;
}

function Generator(props: GeneratorProps) {
	const location = useLocation();
	const theme = useTheme();
	const navigate = useNavigate();
	const traitsService = useDi(TraitsService);
	const characterService = useDi(CharacterService);
	const generatorStore = useDi(GeneratorStore);

	const { addLoader, removeLoader, isLoading } = useLoader();

	const quickRoll = (location.state as { quick?: string })?.quick;

	const rollPrompt = props.rollPrompt ?? "Roll a character to get started. You can also change their options below.";
	const savePrompt = props.savePrompt ?? "Tap one or more names to save them to your characters.";
	const canSelectAndSave = props.canSelectAndSave ?? true;
	const selectedCharacterIds = props.selectedCharacterIds;
	const setSelectedCharacterIds = props.setSelectedCharacterIds;

	const [isGeneratingCharacter, setIsGeneratingCharacter] = useState(false);
	const [isSavingCharacters, setIsSavingCharacters] = useState(false);
	const [isDeletingCharacters, setIsDeletingCharacters] = useState(false);

	useEffect(() => {
		const loaderId = addLoader();
		traitsService
			.getAllFilterData()
			.then((data) => {
				generatorStore.races = data.getRaces.nodes;
				generatorStore.cultures = data.getCultures.nodes;
				generatorStore.personalities = data.getPersonalities.nodes;
				generatorStore.professions = data.getProfessions.nodes;
			})
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => removeLoader(loaderId));
	}, [traitsService, generatorStore, addLoader, removeLoader]);

	useEffect(() => {
		generatorStore.generatedCharacters = [];
		const loaderId = addLoader();
		characterService
			.fetchForGeneratorList()
			.then((characters) => {
				generatorStore.generatedCharacters = characters;
			})
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => removeLoader(loaderId));
	}, [addLoader, characterService, generatorStore, removeLoader]);

	const generatorItems = useMemo(() => {
		return generatorStore.generatedCharacters.map((c, i) => {
			return {
				id: c.id,
				size: Size.Large,
				isSelectable: canSelectAndSave,
				children: <CharacterItem character={c} isDisplay={generatorStore.generatedCharacters.length > 2 ? i === 0 : i < 2} />,
			} as SelectableListItem;
		});
	}, [canSelectAndSave, generatorStore.generatedCharacters]);

	const handleRollCharacterClicked = useCallback(async () => {
		setIsGeneratingCharacter(true);
		try {
			const character = await characterService.generateCharacter({
				name: generatorStore.name,
				genders: generatorStore.selectedGenders,
				raceIds: generatorStore.selectedRaceIds,
				cultureIds: generatorStore.selectedCultureIds,
				personalityIds: generatorStore.selectedPersonalityIds,
				professionIds: generatorStore.selectedProfessionIds,
				groupId: generatorStore.groupArchetype?.id,
			});
			if (character) {
				generatorStore.addGeneratedCharacter(character);
				generatorStore.name = undefined; // Unset the custom name
			} else {
				modalManager.show({
					id: "generator-no-character",
					type: ModalType.Alert,
					title: "Uh oh...",
					children: <p>It looks like your filters are too specific. Please try something else.</p>,
				} as AlertModalProps);
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsGeneratingCharacter(false);
		}
	}, [characterService, generatorStore]);

	const handleCharacterSelected: SelectableListOnChangeHandler = (selectedItems) => {
		setTimeout(() => setSelectedCharacterIds?.(selectedItems.map((x) => x.id)), 0);
	};

	const handleSaveSelection = async () => {
		setIsSavingCharacters(true);
		try {
			await characterService.persistGeneratedCharacters(generatorStore.generatedCharacters.filter((x) => (selectedCharacterIds ?? []).indexOf(x.id!) >= 0));
			generatorStore.generatedCharacters = generatorStore.generatedCharacters.filter((x) => (selectedCharacterIds ?? []).indexOf(x.id!) < 0);
			navigate(PageRoutes.Character);
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsSavingCharacters(false);
		}
	};

	const handleDeleteSelection = async () => {
		setIsDeletingCharacters(true);
		try {
			await characterService.deleteCharacters(generatorStore.generatedCharacters.filter((x) => (selectedCharacterIds ?? []).indexOf(x.id!) >= 0));
			generatorStore.generatedCharacters = generatorStore.generatedCharacters.filter((x) => (selectedCharacterIds ?? []).indexOf(x.id!) < 0);
			setSelectedCharacterIds?.([]);
			props.onCharactersDeleted?.();
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsDeletingCharacters(false);
		}
	};

	const handleFiltersClicked = () => {
		const contentModal: ModalProps = {
			id: "generator-filters",
			type: ModalType.FullScreen,
			children: <GeneratorFilters />,
		};
		modalManager.show(contentModal);
	};

	useLayoutEffect(() => {
		if (quickRoll) {
			handleRollCharacterClicked();
		}
	}, [handleRollCharacterClicked, quickRoll]);

	const buttons = (
		<>
			{selectedCharacterIds?.length || props.isSaveMode ? (
				<>
					<Button isResponsive onClick={handleDeleteSelection} disabled={isDeletingCharacters || isSavingCharacters || !selectedCharacterIds?.length}>
						{isDeletingCharacters ? (
							<>
								<>
									Deleting <Loader size={Size.Small} layoutType={LoaderLayout.InlineButton} key="loading-generator-save" />
								</>
							</>
						) : (
							<>Delete</>
						)}
					</Button>
					<Button scheme={theme.schemes.accent2} isResponsive onClick={handleSaveSelection} disabled={isDeletingCharacters || isSavingCharacters || !selectedCharacterIds?.length}>
						{isSavingCharacters ? (
							<>
								<>
									Saving <Loader size={Size.Small} layoutType={LoaderLayout.InlineButton} key="loading-generator-save" />
								</>
							</>
						) : (
							<>Save to characters</>
						)}
					</Button>
				</>
			) : (
				<>
					<Button scheme={theme.schemes.accent2} isResponsive onClick={handleRollCharacterClicked} disabled={isGeneratingCharacter}>
						{isGeneratingCharacter ? (
							<>
								Rolling <Loader size={Size.Small} layoutType={LoaderLayout.InlineButton} key="loading-generator-generate" />
							</>
						) : (
							<>Roll {generatorItems.length === 0 ? "character" : "another"}</>
						)}
					</Button>
					<Button isResponsive onClick={handleFiltersClicked} disabled={isGeneratingCharacter}>
						<Icon name={IconName.Sliders} />
						Filters
					</Button>
				</>
			)}
		</>
	);

	return (
		<StyledGenerator className={`Generator ${props.className || ""}`}>
			<StackedButtonGroup buttons={buttons}>
				{isLoading || props.isLoading ? (
					<Loader layoutType={LoaderLayout.CenterStretchHeight} />
				) : generatorItems.length ? (
					<>
						<StyledSelectableList
							type={generatorItems.length < 3 ? SelectableListType.SingleColumn : SelectableListType.Display}
							key="generator-list"
							items={generatorItems}
							selectedItems={generatorItems.filter((x) => (selectedCharacterIds ?? []).indexOf(x.id) >= 0)}
							onChange={handleCharacterSelected}
							verticalAlign="top"
							gap={theme.spacing.xxs}
							multiSelect
						/>
						{generatorItems.length < 4 && <Prompt>{savePrompt}</Prompt>}
					</>
				) : (
					<EmptyPrompt prompt={rollPrompt} />
				)}
			</StackedButtonGroup>
		</StyledGenerator>
	);
}
export default observer(Generator);

const StyledSelectableList = styled(SelectableList)`
	flex-shrink: 1;
	flex-grow: 1;
	min-height: min-content;
`;

const Prompt = styled.p`
	${({ theme }) => css`
		margin: ${theme.spacing.xl} ${theme.spacing.md};
		font-size: ${theme.typography.fontSizes.xLarge};
		text-align: center;
	`}
`;

const StyledGenerator = styled.div`
	width: 100%;
	height: 100%;
`;
