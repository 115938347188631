import React, { Children, PropsWithChildren, ReactNode, useState } from "react";
import { VerticalAlignment } from "Style/Alignment";
import styled, { css, useTheme } from "styled-components";
import Pointer, { PointerProps } from "./Pointer";
import VerticalStack from "./VerticalStack";

export type EmptyPromptProps = PropsWithChildren<{
	className?: string;
	prompt?: ReactNode;
	align?: VerticalAlignment;
	pointer?: Omit<PointerProps, "source" | "className">;
}>;

export default function EmptyPrompt(props: EmptyPromptProps) {
	const theme = useTheme();
	const align = props.align ?? VerticalAlignment.Bottom;

	const [promptEl, setPromptEl] = useState<HTMLElement | null>(null);

	const promptBottomMargin = props.pointer ? theme.spacing.xxxl : Children.count(props.children) > 0 ? theme.spacing.md : "0";

	return (
		<StyledEmptyPrompt className={`EmptyPrompt ${props.className || ""}`} align={align}>
			{props.prompt && (
				<Prompt ref={setPromptEl} $bottomMargin={promptBottomMargin}>
					{props.prompt}
				</Prompt>
			)}
			{props.pointer && <Pointer {...props.pointer} source={promptEl} />}
			{props.children}
		</StyledEmptyPrompt>
	);
}

const Prompt = styled.p<{ $bottomMargin: string }>`
	${({ theme, $bottomMargin }) => css`
		font-size: ${theme.typography.fontSizes.xLarge};
		text-align: center;
		margin-bottom: ${$bottomMargin};
	`}
`;

const StyledEmptyPrompt = styled(VerticalStack)`
	box-sizing: border-box;
	padding: ${(props) => props.theme.spacing.md};
`;
