/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import * as GraphQLQuery from "GraphQL/GraphQLQuery";
import { GraphQLQueryResponse, PaginatedGraphQLResponse } from "GraphQL/GraphQLResponse";
import { Group } from "Models/Group";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

export type GetGroupByIdInput = {
	id: string;
};
export type GetGroupByIdOutput = GraphQLQueryResponse<Group, "getGroupById">;

export const getGroupById = async (apollo: Promise<Apollo<unknown>>, returnFields: string, id: string) => {
	const response = await (
		await apollo
	).query<GetGroupByIdOutput, GetGroupByIdInput>({
		variables: {
			id,
		},
		query: gql`
			fragment GroupOutput on Group {
				${returnFields}
			}
			query getGroupById($id : UUID!) {
				getGroupById(id: $id) {
					...GroupOutput
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

export type GroupInputFilter = {
	and?: GroupInputFilter[];
	or?: GroupInputFilter[];
	id?: GraphQLQuery.ComparableNullableOfGuidOperationFilterInput;
	created?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	modified?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	spaceId?: GraphQLQuery.ComparableGuidOperationFilterInput;
	name?: GraphQLQuery.StringOperationFilterInput;
	details?: GraphQLQuery.StringOperationFilterInput;
};
export type GetGroupsInput = GraphQLQuery.PaginatedQueryInput<Group, GroupInputFilter>;
export type GetGroupsOutput = PaginatedGraphQLResponse<Group, "getGroups">;
const GetGroupsInputTypes: Record<keyof GetGroupsInput, string> = {
	first: "Int",
	after: "String",
	last: "Int",
	before: "String",
	where: "GroupFilterInput",
	order: "[GroupSortInput!]",
};
export const getGroups = async (apollo: Promise<Apollo<unknown>>, returnFields: string, variables: GetGroupsInput = {}) => {
	const [operationVars, queryVars] = GraphQLQuery.getQueryVars(variables, GetGroupsInputTypes);
	const response = await (
		await apollo
	).query<GetGroupsOutput, GetGroupsInput>({
		variables,
		query: gql`
			fragment GroupOutput on Group {
				${returnFields}
			}
			query getGroups${operationVars} {
				getGroups${queryVars} {
					totalCount
					pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					edges {
						cursor
						node {
							...GroupOutput
						}
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] on begin */
export type SearchGroupInput = Omit<GraphQLQuery.PaginatedQueryInput<Group, undefined>, "where"> & {
	query: string;
};
const SearchGroupInputTypes: Record<keyof SearchGroupInput, string> = {
	first: "Int",
	after: "String",
	last: "Int",
	before: "String",
	query: "String",
	order: "[GroupSortInput!]",
};

export type SearchGroupsOutput = PaginatedGraphQLResponse<Group, "searchGroups">;

export const searchGroups = async (apollo: Promise<Apollo<unknown>>, returnFields: string, variables: SearchGroupInput) => {
	const [operationVars, queryVars] = GraphQLQuery.getQueryVars(variables, SearchGroupInputTypes);
	return (await apollo).query<SearchGroupsOutput, SearchGroupInput>({
		variables,
		query: gql`
			fragment SearchGroupsOutput on Group {
				${returnFields}
			}
			query searchGroups${operationVars} {
				searchGroups${queryVars} {
					totalCount
					pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					edges {
						cursor
						node {
							...SearchGroupsOutput
						}
					}
				}
			}
		`,
	});
};

/* protected region [Anything else] end */
