import Color from "color";

export interface IPalette {
	primary: string;
	secondary: string;

	success: string;
	info: string;
	warning: string;
	error: string;

	accent1: string;
	accent2: string;

	shade1: string;
	shade2: string;
	shade3: string;
}

export interface IScheme {
	passive: string;
	hover: string;
	active: string;
	text: string;
}

export type ISchemes = Record<keyof IPalette, IScheme>;

/**
 * Defaults/Constants
 */

export const Colors = {
	white: "#ffffff",
	black: "#000000",

	grey1: "#606060",
	grey2: "#313131",
	grey3: "#1D1D1D",

	blue: "#0038FF",
	orange: "#CF4A00",
};

/**
 * Methods
 */

export function createSchemes(palette: IPalette): ISchemes {
	return {
		primary: createScheme(palette.primary),
		secondary: createScheme(palette.secondary),

		shade1: createScheme(palette.shade1),
		shade2: createScheme(palette.shade2),
		shade3: createScheme(palette.shade3),
		success: createScheme(palette.success),
		info: createScheme(palette.info),
		warning: createScheme(palette.warning),
		error: createScheme(palette.error),
		accent1: createScheme(palette.accent1),
		accent2: createScheme(palette.accent2),
	};
}

export function transparentize(colorValue: string, opacity: number) {
	const color = new Color(colorValue);
	return color.fade(opacity).rgb().string();
}

export function darken(colorValue: string, strength: number) {
	const color = new Color(colorValue);
	return color.darken(strength).rgb().string();
}

export function lighten(colorValue: string, strength: number) {
	const color = new Color(colorValue);
	return color.lighten(strength).rgb().string();
}

export function getContrastingTextColor(colorHex: string): string {
	const light = new Color(Colors.white);
	const dark = new Color(Colors.black);
	const color = new Color(colorHex);

	const lightTextContrast = color.contrast(light);
	const darkTextContrast = color.contrast(dark);
	if (lightTextContrast > darkTextContrast) {
		return light.toString();
	}

	return dark.toString();
}

function createScheme(color: string): IScheme {
	const hslcolor = new Color(color);
	const [, s] = [...hslcolor.hsl().array()];

	if (s < 20) {
		// Greyscale
		const mixColor = hslcolor.isLight() ? "black" : "white";

		return {
			passive: color,
			hover: hslcolor.mix(new Color(mixColor), 0.2).toString(),
			active: hslcolor.mix(new Color(mixColor), 0.25).toString(),
			text: getContrastingTextColor(color),
		};
	} else {
		const rate = (s - 50) / 250;

		return {
			passive: color,
			hover: hslcolor.mix(new Color("white"), rate).toString(),
			active: hslcolor.mix(new Color("black"), rate).toString(),
			text: getContrastingTextColor(color),
		};
	}
}
