export interface ISpacing {
	xxs: string;
	xs: string;
	sm: string;
	md: string;
	lg: string;
	xl: string;
	xxl: string;
	xxxl: string;
}

export enum Size {
	Small = "Small",
	Normal = "Normal",
	Large = "Large",
}
