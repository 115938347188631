import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import StackedButtonGroup from "Component/Global/Content/StackedButtonGroup";
import Button from "Component/Global/Input/Button";
import ModelBoundTextInput from "Component/Global/Input/ModelBoundTextInput";
import Page from "Component/Global/Navigation/Page";
import SignupRequest, { SignupRequestValidation } from "Models/Request/SignupRequest";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "Services/AuthenticationService";
import styled, { useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { ValidationError } from "validate";

export interface SignupPageProps {
	className?: string;
}

export default function SignupPage(props: SignupPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const authenticationService = useDi(AuthenticationService);

	const formEl = useRef<HTMLFormElement | null>(null);

	const [isLoading, setIsLoading] = useState(false);

	const model = useRef<SignupRequest>({
		name: "",
		email: "",
		password: "",
		confirmPassword: "",
	});
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault();

			const errors = SignupRequestValidation.validate(model.current);
			setValidationErrors(errors);

			if (errors.length === 0) {
				setIsLoading(true);
				await authenticationService.createUserWithUsernameAndPassword(model.current.email, model.current.password, model.current.name);
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSignupClicked = () => {
		if (formEl.current) {
			formEl.current.requestSubmit();
		}
	};

	return (
		<StyledSignupPage
			className={`SignupPage ${props.className || ""}`}
			titleBar={{
				title: "Sign up",
				leftIcon: IconName.ArrowLeft,
				onLeftIconClicked: () => navigate(-1),
			}}
			padding={"0"}
		>
			<StackedButtonGroup
				contentPadding={theme.spacing.sm}
				buttons={[
					<SignupButton key="submit" isResponsive scheme={theme.schemes.accent2} onClick={handleSignupClicked} tapAnimation={false} disabled={isLoading}>
						{isLoading ? (
							<>
								Signing up <Loader layoutType={LoaderLayout.InlineButton} />
							</>
						) : (
							"Sign up"
						)}
					</SignupButton>,
				]}
			>
				<form ref={formEl} onSubmit={handleSubmit} noValidate>
					<ModelBoundTextInput type="text" label="Name" model={model.current} modelProp="name" validationSchema={SignupRequestValidation} validationErrors={validationErrors} disabled={isLoading} />
					<ModelBoundTextInput type="email" label="Email address" model={model.current} modelProp="email" validationSchema={SignupRequestValidation} validationErrors={validationErrors} disabled={isLoading} />
					<ModelBoundTextInput type="password" label="Password" model={model.current} modelProp="password" validationSchema={SignupRequestValidation} validationErrors={validationErrors} disabled={isLoading} />
					<ModelBoundTextInput
						type="password"
						label="Confirm password"
						model={model.current}
						modelProp="confirmPassword"
						validationSchema={SignupRequestValidation}
						validationErrors={validationErrors}
						disabled={isLoading}
					/>
					<HiddenSubmit type="submit" />
				</form>
			</StackedButtonGroup>
		</StyledSignupPage>
	);
}

const HiddenSubmit = styled.input`
	display: none;
`;

const SignupButton = styled(Button)`
	border-radius: unset;
`;

const StyledSignupPage = styled(Page)`
	.ModelBoundTextInput {
		margin: ${(props) => props.theme.spacing.xs} 0;
	}
`;
