import React from "react";
import styled, { css, useTheme } from "styled-components";

import Feather from "feather-icons";
export interface IconProps {
	className?: string;
	name: IconName;
	color?: string;
	size?: string;
	onClick?: () => void;
}

// This is based on https://feathericons.com/

export default function Icon(props: IconProps) {
	const theme = useTheme();
	const color = props.color ?? theme.typography.fontColor;
	const size = props.size ?? theme.typography.fontSizes.xLarge;
	return (
		<StyledIcon
			className={`Icon ${props.className || ""}`}
			onClick={props.onClick}
			$size={size}
			dangerouslySetInnerHTML={{ __html: Feather.icons[props.name].toSvg({ color, height: size, width: size }) }}
		></StyledIcon>
	);
}

const StyledIcon = styled.span<{ $size: string }>`
	${({ $size }) => css`
		display: inline-block;
		height: ${$size};
		width: ${$size};
		line-height: ${$size};
		text-align: center;
		vertical-align: middle;
	`}
`;

export enum IconName {
	ArrowLeft = "arrow-left",
	ArrowRight = "arrow-right",
	ArrowUp = "arrow-up",
	ArrowDown = "arrow-down",
	CheckSquare = "check-square",
	Edit = "edit",
	Edit2 = "edit-2",
	Menu = "menu",
	PlusCircle = "plus-circle",
	Refresh = "refresh-cw",
	Rotate = "rotate-cw",
	Search = "search",
	Settings = "settings",
	Sliders = "sliders",
	Trash = "trash",
	User = "user",
	UserPlus = "user-plus",
	Users = "users",
	X = "x",
	HelpCircle = "help-circle",
	Filter = "filter",
	Grid = "grid",
	List = "list",
	Columns = "columns",
	Minimize = "minimize",
	Maximize = "maximize",
	Save = "save",
}
