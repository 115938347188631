import Schema from "validate";

export default interface ForgotPasswordRequest {
	email: string;
}

export const ForgotPasswordRequestValidation = new Schema({
	email: {
		required: true,
		match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
		message: {
			required: "Email is required",
			match: "Email is not valid",
		},
	},
});
