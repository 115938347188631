import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { NestedRoutes_Characters, NestedRoutes_Groups, NestedRoutes_Onboarding, PageRoutes } from "Utils/Navigation";
import GeneratorPage from "Component/Generator/GeneratorPage";
import CharacterListPage from "Component/Character/CharacterListPage";
import GroupsListPage from "Component/Groups/GroupListPage";
import NotFoundPage from "./NotFoundPage";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import UserStore from "Stores/UserStore";
import LoginPage from "./User/LoginPage";
import { observer } from "mobx-react";
import SplashPage from "./SplashPage";
import LogoutPage from "./User/LogoutPage";
import { OnboardingGenerator, OnboardingHowItWorks, OnboardingWelcome } from "./User/Onboarding";
import GroupEditPage from "./Groups/GroupEditPage";
import GroupDetailsPage from "./Groups/GroupDetailsPage";
import TabMenu from "./Global/Navigation/TabMenu/TabMenu";
import TabMenuStore from "Stores/TabMenuStore";
import CharacterDetailsPage from "./Character/CharacterDetailsPage";
import GroupSearchPage from "./Groups/GroupSearchPage";
import FocusedTextInputStore from "Stores/FocusedTextInputStore";
import SignupPage from "./User/SignupPage";
import ForgotPasswordPage from "./User/ForgotPasswordPage";
import CharacterEditPage from "./Character/CharacterEditPage";
import SettingsPage from "./User/SettingsPage";
import InAppWarningPage from "./InAppWarningPage";
import CharacterSearchPage from "./Character/CharacterSearchPage";
import NewCharacterPage from "./Character/NewCharacterPage";
import useInApp from "Hooks/UseInApp";
import { PageWrapper } from "Component/Global/Navigation/Page";

export interface MobileProps {
	className?: string;
}

export default observer(Mobile);
function Mobile(props: MobileProps) {
	const location = useLocation();
	const { isInAppBrowser } = useInApp();

	const tabMenuStore = useDi(TabMenuStore);
	const userStore = useDi(UserStore);
	const focusedTextInputStore = useDi(FocusedTextInputStore);

	const [hasIgnoredInAppWarning, setHasIgnoredInAppWarning] = useState(false);

	return (
		<StyledMobile className={`Mobile ${props.className || ""}`}>
			{isInAppBrowser && !hasIgnoredInAppWarning ? (
				<PageWrapper className="PageWrapper">
					<InAppWarningPage onContinue={() => setHasIgnoredInAppWarning(true)} />
				</PageWrapper>
			) : userStore.isLoading ? (
				<SplashPage key="splash" />
			) : (
				<PageWrapper className="PageWrapper">
					<Routes location={location} key={location.pathname}>
						<Route path={PageRoutes.Logout} element={<LogoutPage />} />
						{userStore.isLoggedIn ? (
							<>
								{!userStore.hasCompletedOnboarding ? (
									<>
										<Route path="*" element={<Navigate to={NestedRoutes_Onboarding.Welcome} />} />
									</>
								) : (
									<>
										<Route path={PageRoutes.Generator} element={<GeneratorPage />} />

										<Route path={PageRoutes.Character} element={<CharacterListPage />} />
										<Route path={NestedRoutes_Characters.New} element={<NewCharacterPage />} />
										<Route path={NestedRoutes_Characters.Search} element={<CharacterSearchPage />} />
										<Route path={NestedRoutes_Characters.Edit} element={<CharacterEditPage />} />
										<Route path={NestedRoutes_Characters.Details} element={<CharacterDetailsPage />} />

										<Route path={PageRoutes.Group} element={<GroupsListPage />} />
										<Route path={NestedRoutes_Groups.New} element={<GroupEditPage />} />
										<Route path={NestedRoutes_Groups.Search} element={<GroupSearchPage />} />
										<Route path={NestedRoutes_Groups.Edit} element={<GroupEditPage />} />
										<Route path={NestedRoutes_Groups.Details} element={<GroupDetailsPage />} />

										<Route path={PageRoutes.Settings} element={<SettingsPage />} />
										<Route path={PageRoutes.Home} element={<Navigate to={PageRoutes.Generator} />} />
									</>
								)}
								<Route path={PageRoutes.Onboarding} element={<Navigate to={NestedRoutes_Onboarding.Welcome} />} />
								<Route path={NestedRoutes_Onboarding.Welcome} element={<OnboardingWelcome />} />
								<Route path={NestedRoutes_Onboarding.HowItWorks} element={<OnboardingHowItWorks />} />
								<Route path={NestedRoutes_Onboarding.Generator} element={<OnboardingGenerator />} />
							</>
						) : (
							<>
								<Route path={PageRoutes.Signup} element={<SignupPage />} />
								<Route path={PageRoutes.ForgotPassword} element={<ForgotPasswordPage />} />
								<Route path="*" element={<LoginPage />} />
							</>
						)}
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</PageWrapper>
			)}
			{!isInAppBrowser && userStore.isLoggedIn && tabMenuStore.isVisible && !focusedTextInputStore.hasFocus && <TabMenu />}
		</StyledMobile>
	);
}

const StyledMobile = styled.div`
	height: 100%;
	width: 100vw;
	overflow: hidden;
	display: grid;
	grid-template-rows: 1fr auto;
`;
