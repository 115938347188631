import { motion } from "framer-motion";
import React, { forwardRef, PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";

export type PaddingProps = PropsWithChildren<{
	className?: string;
	padding?: string;
}>;

const Padding = forwardRef<HTMLDivElement, PaddingProps>((props, ref) => {
	const theme = useTheme();
	const padding = props.padding ?? theme.spacing.md;
	return (
		<StyledPadding ref={ref} className={`Padding ${props.className || ""}`} $padding={padding}>
			{props.children}
		</StyledPadding>
	);
});
export default motion(Padding);

const StyledPadding = styled.div<{ $padding: string }>`
	box-sizing: border-box;
	width: 100%;
	padding: ${(props) => props.$padding};
`;
