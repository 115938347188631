import React, { forwardRef, PropsWithChildren, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { DefaultTransition } from "Style/Animation";
import { IScheme } from "Style/Color";
import styled, { css, useTheme } from "styled-components";
import Padding from "Component/Global/Content/Padding";
import { Size } from "Style/Sizing";
import { ITheme } from "Style/Theme";

export type SelectableProps = PropsWithChildren<{
	className?: string;
	backgroundSrc?: string | Promise<string>;
	backgroundPosition?: string;
	isSelected?: boolean;
	scheme?: IScheme;
	selectedScheme?: IScheme;
	padding?: boolean;
	onChange?: (isSelected: boolean) => void;
	onClick?: () => void;
	isSelectable?: boolean;
	size?: Size;
}>;

const Selectable = forwardRef<HTMLDivElement, SelectableProps>((props, ref) => {
	const theme = useTheme();

	const padding = props.padding ?? true;
	const scheme = props.scheme ?? theme.schemes.shade2;
	const selectedScheme = props.selectedScheme ?? theme.schemes.accent2;
	const isSelectable = props.isSelectable ?? true;
	const size = props.size ?? Size.Normal;

	const [isSelected, setIsSelected] = useState(props.isSelected ?? false);
	const [backgroundSrc, setBackgroundSrc] = useState("");

	useEffect(() => {
		setIsSelected(props.isSelected ?? false);
	}, [props.isSelected]);

	useEffect(() => {
		if (props.backgroundSrc) {
			if (typeof props.backgroundSrc == "string") {
				setBackgroundSrc(props.backgroundSrc);
			} else {
				props.backgroundSrc.then((src) => {
					setBackgroundSrc(src ?? "");
				});
			}
		}
	}, [props.backgroundSrc]);

	const handleClick = () => {
		props.onClick?.();

		if (isSelectable) {
			const newValue = !isSelected;
			props.onChange?.(newValue);
			setIsSelected(newValue);
		}
	};

	let content = props.children;
	if (padding) {
		content = <Padding>{content}</Padding>;
	}

	return (
		<StyledSelectable
			ref={ref}
			className={`Selectable ${props.className || ""}`}
			$scheme={scheme}
			$size={size}
			style={{
				backgroundImage: `linear-gradient(45deg, rgba(49,49,49,0.4), rgba(49,49,49,0.4)), url(${backgroundSrc})`,
				backgroundPosition: props.backgroundPosition,
			}}
			onClick={handleClick}
		>
			<Overlay
				transition={DefaultTransition}
				initial={false}
				animate={{
					backgroundColor: selectedScheme.passive,
					opacity: isSelected ? (backgroundSrc ? 0.4 : 1) : 0,
				}}
			/>
			<Content>{content}</Content>
		</StyledSelectable>
	);
});
export default motion(Selectable);

function getHeight(theme: ITheme, size: Size) {
	if (size === Size.Small) {
		return theme.spacing.xxxl;
	} else if (size === Size.Normal) {
		return `calc(${theme.spacing.xxl} * 2)`;
	} else if (size === Size.Large) {
		return `calc(${theme.spacing.xxxl} * 2)`;
	}
}

const StyledSelectable = styled.div<{ $scheme: IScheme; $size: Size }>`
	${({ theme, $scheme, $size }) => css`
		position: relative;
		background-color: ${$scheme.passive};
		background-position: center center;
		background-size: cover;
		border-radius: 18px;
		color: ${$scheme.text};
		min-height: ${getHeight(theme, $size)};
		cursor: pointer;
		display: flex;
		flex-direction: column;
	`}
`;

const Overlay = styled(motion.div)`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: 18px;
`;
const Content = styled.div`
	z-index: 1;

	&,
	& > .Padding {
		height: 100%;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: flex-end;
	}
`;
