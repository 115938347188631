import React, { useCallback, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Search from "Component/Global/Interactive/Search";
import { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Page from "Component/Global/Navigation/Page";
import { generatePath, useNavigate } from "react-router-dom";
import CharacterService from "Services/CharacterService";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import { NestedRoutes_Characters } from "Utils/Navigation";
import modalManager from "Utils/ModalManager";

export interface CharacterSearchPageProps {
	className?: string;
}

export default function CharacterSearchPage(props: CharacterSearchPageProps) {
	const navigate = useNavigate();
	const characterService = useDi(CharacterService);
	const [isLoading, setIsLoading] = useState(false);

	const handleCharacterSelected: SelectableListOnChangeHandler = (selectedItems) => {
		if (selectedItems.length === 1) {
			navigate(generatePath(NestedRoutes_Characters.Details, { id: selectedItems[0].id }));
		}
	};

	const performSearch = useCallback(
		async (query: string) => {
			try {
				setIsLoading(true);
				const characters = await characterService.searchForList(query);
				return characterService.asSelectableList(characters, (g) => g.name);
			} catch (err) {
				modalManager.showError(err as Error);
				return [];
			} finally {
				setIsLoading(false);
			}
		},
		[characterService],
	);

	return (
		<StyledCharacterSearchPage
			className={`CharacterSearchPage ${props.className || ""}`}
			titleBar={{
				title: "Search",
				rightIcon: IconName.X,
				onRightIconClicked: () => navigate(-1),
				transparentIcons: true,
			}}
		>
			{isLoading && <Loader layoutType={LoaderLayout.FullScreenOverlay} />}
			<Search performSearch={performSearch} selectableListOptions={{ type: SelectableListType.SingleColumn, onChange: handleCharacterSelected }} />
		</StyledCharacterSearchPage>
	);
}

const StyledCharacterSearchPage = styled(Page)``;
