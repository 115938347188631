import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import CoverArt, { CoverArtState } from "Component/Global/Interactive/CoverArt";
import Pivot, { PivotItem } from "Component/Global/Navigation/Pivot/Pivot";
import { Character } from "Models/Character";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import UploadService from "Services/UploadService";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import Padding from "Component/Global/Content/Padding";
import { IconName } from "Component/Global/Content/Icon";
import { NestedRoutes_Characters, NestedRoutes_Groups } from "Utils/Navigation";
import Page from "Component/Global/Navigation/Page";
import EditableText from "Component/Global/Input/EditableText";
import CharacterInfoBlock from "./CharacterInfoBlock";
import CharacterService from "Services/CharacterService";
import { observer } from "mobx-react";
import FocusedTextInputStore from "Stores/FocusedTextInputStore";
import useDebouncedValue from "Hooks/UseDebouncedValue";
import TraitsService from "Services/TraitsService";
import { Profession } from "Models/Profession";
import { Personality } from "Models/Personality";
import Button from "Component/Global/Input/Button";
import SelectableList, { SelectableListItem, SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import SearchInput from "Component/Global/Input/SearchInput";
import { FullScreenModalProps } from "Component/Global/Interactive/Modal/FullScreenModal";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import modalManager from "Utils/ModalManager";
import GroupService from "Services/GroupService";
import CharacterGroupsSearch from "./CharacterGroupsSearch";
import { GroupArchetypeRouterState } from "Component/Groups/GroupEditPage";
import { Race } from "Models/Race";
import { Culture } from "Models/Culture";

export interface CharacterDetailsPageProps {
	className?: string;
}

export default observer(CharacterDetailsPage);
function CharacterDetailsPage(props: CharacterDetailsPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const uploadService = useDi(UploadService);
	const focusedTextInputStore = useDi(FocusedTextInputStore);

	const params = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [coverArtState, setCoverArtState] = useState<CoverArtState>(CoverArtState.Collapsed);

	const [availableRaces, setAvailableRaces] = useState<Partial<Race>[]>([]);
	const [availableCultures, setAvailableCultures] = useState<Partial<Culture>[]>([]);
	const [availableProfessions, setAvailableProfessions] = useState<Partial<Profession>[]>([]);
	const [availablePersonalities, setAvailablePersonalities] = useState<Partial<Personality>[]>([]);
	const [character, setCharacter] = useState<Partial<Character>>();

	const traitsService = useDi(TraitsService);
	const characterService = useDi(CharacterService);
	const groupService = useDi(GroupService);

	const [isEditingGroups, setIsEditingGroups] = useState(false);
	const [selectedMemberItems, setSelectedMemberItems] = useState<SelectableListItem[]>([]);
	const [groupSearchQuery, setGroupSearchQuery] = useState("");

	const characterId = params.id || "";

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchChanged = (query: string) => {
		setGroupSearchQuery(query);
	};

	useEffect(() => {
		characterService
			.fetchDetails(characterId)
			.then(setCharacter)
			.finally(() => setIsLoading(false));
	}, [characterId, characterService]);

	useEffect(() => {
		traitsService
			.getAllFilterData()
			.then((data) => {
				setAvailableRaces(data.getRaces.nodes);
				setAvailableCultures(data.getCultures.nodes);
				setAvailableProfessions(data.getProfessions.nodes);
				setAvailablePersonalities(data.getPersonalities.nodes);
			})
			.catch((err) => modalManager.showError(err as Error));
	}, [traitsService]);

	const [isTextInputFocused] = useDebouncedValue(focusedTextInputStore.hasFocus, 50);

	useEffect(() => {
		if (coverArtState != CoverArtState.Expanded) {
			setCoverArtState(isTextInputFocused ? CoverArtState.Hidden : CoverArtState.Collapsed);
		}
	}, [coverArtState, isTextInputFocused]);

	const handleInfoBlockChanged = useCallback(async () => {
		if (!character) {
			return;
		}
		try {
			setIsLoading(true);
			const updatedCharacter = await characterService.saveCharacterInfoBlock(character);
			if (updatedCharacter) {
				setCharacter(updatedCharacter);
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, [character, characterService]);

	const characterGroupsItems = useMemo(() => {
		const characterGroups = (character?.groups ?? []).filter((g) => (groupSearchQuery ? g.name!.toLowerCase().includes(groupSearchQuery) : true));

		return groupService.asSelectableList(characterGroups, (g) => g.name);
	}, [character?.groups, groupService, groupSearchQuery]);

	const handleGroupMemberSelected: SelectableListOnChangeHandler = useCallback(
		(selected) => {
			if (isEditingGroups) {
				setSelectedMemberItems(selected);
			} else {
				setTimeout(() => {
					navigate(generatePath(NestedRoutes_Groups.Details, { id: selected.at(0)!.id }));
				}, 0);
			}
		},
		[isEditingGroups, navigate],
	);

	const handleGroupAdded = useCallback(
		async (groupId: string) => {
			setIsLoading(true);
			try {
				const addedGroup = await characterService.addGroupToCharacter(character!.id!, groupId);
				if (addedGroup?.group) {
					setCharacter((character) => {
						const updatedCharacter = {
							...character,
							groups: [...(character?.groups ?? []), addedGroup!.group!],
						};
						return updatedCharacter;
					});
				}
			} catch (err) {
				modalManager.showError(err as Error);
			} finally {
				setIsLoading(false);
			}
		},
		[character, characterService],
	);

	const handleAddOrRemoveMemberClicked = useCallback(() => {
		if (isEditingGroups) {
			setIsLoading(true);
			const groupIdsToRemove = selectedMemberItems.map((x) => x.id!);
			characterService
				.removeGroupsFromCharacter(character!.id!, groupIdsToRemove)
				.then(() => {
					setCharacter((character) => {
						const updatedCharacter = {
							...character,
							groups: [...(character?.groups ?? []).filter((x) => groupIdsToRemove.indexOf(x.id!) === -1)],
						};
						return updatedCharacter;
					});
					setSelectedMemberItems([]);
					setIsEditingGroups(false);
				})
				.catch((err) => modalManager.showError(err as Error))
				.finally(() => setIsLoading(false));
		} else {
			modalManager.show({
				id: "character-add-group",
				type: ModalType.FullScreen,
				children: <CharacterGroupsSearch existingGroupIds={characterGroupsItems.map((c) => c.id!)} onGroupSelected={handleGroupAdded} />,
			} as FullScreenModalProps);
		}
	}, [isEditingGroups, selectedMemberItems, characterService, character, characterGroupsItems, handleGroupAdded]);

	const handleEditMembersClicked = useCallback(() => {
		setSelectedMemberItems([]);
		setIsEditingGroups(!isEditingGroups);
	}, [isEditingGroups]);

	const handleAddGroupClicked = useCallback(() => {
		setTimeout(() => {
			navigate(NestedRoutes_Groups.New, {
				state: {
					characterName: character?.name,
					characterId: character?.id,
					cultureId: character?.culture?.id,
					professionId: character?.profession?.id,
					personalityId: character?.personality?.id,
					raceId: character?.race?.id,
					gender: character?.gender,
				} as GroupArchetypeRouterState,
			});
		});
	}, [character, navigate]);

	const pivotItems: PivotItem[] = useMemo(
		() =>
			character
				? [
						{
							id: "details",
							menuTitle: "Details",
							component: () => (
								<Padding>
									<EditableText
										title="Description"
										onSave={() => characterService.saveCharacterDetail(character.details!)}
										textItems={[
											{
												placeholder: "Tap to edit or add a description",
												value: character.details?.description,
												onChange: (val) => (character.details!.description = val),
											},
										]}
									/>
									<CharacterInfoBlock
										character={character}
										races={availableRaces}
										cultures={availableCultures}
										professions={availableProfessions}
										personalities={availablePersonalities}
										onInfoBlockChanged={handleInfoBlockChanged}
									/>
									<EditableText
										title="Appearance"
										onSave={() => characterService.saveCharacterDetail(character.details!)}
										textItems={[
											{
												placeholder: "Tap to edit or add appearance",
												value: character.details?.appearance,
												onChange: (val) => (character.details!.appearance = val),
											},
										]}
									/>
									<EditableText
										title="Personality"
										onSave={() => characterService.saveCharacterDetail(character.details!)}
										textItems={[
											{
												placeholder: "Tap to edit or add personality",
												value: character.details?.personality,
												onChange: (val) => (character.details!.personality = val),
											},
										]}
									/>
									<EditableText
										title="Motivations"
										onSave={() => characterService.saveCharacterDetail(character.details!)}
										textItems={[
											{
												label: "Ideals",
												placeholder: "Tap to edit or add ideals",
												value: character.details?.ideals,
												onChange: (val) => (character.details!.ideals = val),
											},
											{
												label: "Bonds",
												placeholder: "Tap to edit or add bonds",
												value: character.details?.bonds,
												onChange: (val) => (character.details!.bonds = val),
											},
											{
												label: "Flaws",
												placeholder: "Tap to edit or add flaws",
												value: character.details?.flaws,
												onChange: (val) => (character.details!.flaws = val),
											},
										]}
									/>
								</Padding>
							),
						},
						{
							id: "roleplay",
							menuTitle: "Roleplay",
							component: () => (
								<Padding>
									<EditableText
										title="Backstory"
										onSave={() => characterService.saveCharacterRoleplay(character.roleplay!)}
										textItems={[
											{
												placeholder: "Tap to edit or add a backstory",
												value: character.roleplay?.backstory,
												onChange: (val) => (character.roleplay!.backstory = val),
											},
										]}
									/>
									<EditableText
										title="Goals"
										onSave={() => characterService.saveCharacterRoleplay(character.roleplay!)}
										textItems={[
											{
												placeholder: "Tap to edit or add goals",
												value: character.roleplay?.goals,
												onChange: (val) => (character.roleplay!.goals = val),
											},
										]}
									/>
									<EditableText
										title="Secrets"
										onSave={() => characterService.saveCharacterRoleplay(character.roleplay!)}
										textItems={[
											{
												placeholder: "Tap to edit or add secrets",
												value: character.roleplay?.secrets,
												onChange: (val) => (character.roleplay!.secrets = val),
											},
										]}
									/>
								</Padding>
							),
						},
						{
							id: "notes",
							menuTitle: "Notes",
							component: () => (
								<Padding>
									<EditableText
										title="Notes"
										onSave={() => characterService.saveCharacterNote(character.notes!)}
										textItems={[
											{
												placeholder: "Tap to edit or add notes",
												value: character.notes?.notes,
												onChange: (val) => (character.notes!.notes = val),
											},
										]}
									/>
									<EditableText
										title="Items"
										onSave={() => characterService.saveCharacterNote(character.notes!)}
										textItems={[
											{
												placeholder: "Tap to edit or add items",
												value: character.notes?.items,
												onChange: (val) => (character.notes!.items = val),
											},
										]}
									/>
								</Padding>
							),
						},
						{
							id: "Groups",
							menuTitle: "Groups",
							component: () => (
								<GroupMembers>
									<SearchControls>
										<Top>
											<Label>Groups</Label>
											<Button size={Size.Small} onClick={handleEditMembersClicked} scheme={isEditingGroups ? theme.schemes.accent1 : undefined}>
												{isEditingGroups ? "Save" : "Edit"}
											</Button>
										</Top>
										<Bottom>
											<AddOrRemoveButton
												size={Size.Small}
												onClick={handleAddOrRemoveMemberClicked}
												disabled={isEditingGroups && !selectedMemberItems.length}
												scheme={isEditingGroups ? theme.schemes.accent2 : undefined}
											>
												{isEditingGroups ? "Remove" : "Add"}
											</AddOrRemoveButton>
											<StyledSearchInput placeholder="Search groups" onChange={handleSearchChanged} />
										</Bottom>
									</SearchControls>
									<StyledSelectableList
										items={characterGroupsItems}
										type={characterGroupsItems.length < 3 ? SelectableListType.SingleColumn : SelectableListType.Display}
										onChange={handleGroupMemberSelected}
										verticalAlign={"top"}
										multiSelect={isEditingGroups}
										selectedItems={selectedMemberItems}
									/>
									<Padding>
										<Button scheme={theme.schemes.accent2} onClick={handleAddGroupClicked} isResponsive={true}>
											Create new group
										</Button>
									</Padding>
								</GroupMembers>
							),
						},
				  ]
				: [],
		[
			availableRaces,
			availableCultures,
			availablePersonalities,
			availableProfessions,
			character,
			characterGroupsItems,
			characterService,
			handleAddGroupClicked,
			handleAddOrRemoveMemberClicked,
			handleEditMembersClicked,
			handleGroupMemberSelected,
			handleInfoBlockChanged,
			isEditingGroups,
			selectedMemberItems,
			theme,
		],
	);

	const coverImage = character?.images?.at(0);

	return (
		<StyledCharacterDetailsPage className={`CharacterDetailsPage ${props.className || ""}`} tabBar={coverArtState != CoverArtState.Expanded} padding="0">
			{isLoading && <Loader size={Size.Large} layoutType={LoaderLayout.FullScreenOverlay} key="loading-character-details" />}
			{character && (
				<>
					<StyledCoverArt
						title={`${character.name}`}
						subtitle={character.title}
						description={`${character.race?.name ?? "No race"} - ${character.profession?.name ?? "No profession"}`}
						backgroundPosition={`50% ${coverImage?.positionY ?? 0}%`}
						backgroundSrc={coverImage ? uploadService.getImage(coverImage.imageId!) : undefined}
						leftIcon={IconName.ArrowLeft}
						onLeftIconClicked={() => navigate(-1)}
						rightIcon={IconName.Edit}
						onRightIconClicked={() => navigate(generatePath(NestedRoutes_Characters.Edit, { id: character.id }))}
						state={coverArtState}
						onStateChanged={setCoverArtState}
					>
						<Pivot items={pivotItems} />
					</StyledCoverArt>
				</>
			)}
		</StyledCharacterDetailsPage>
	);
}

const StyledCoverArt = styled(CoverArt)``;

const StyledCharacterDetailsPage = styled(Page)``;

const GroupMembers = styled.div``;

const SearchControls = styled(Padding)``;

const StyledSelectableList = styled(SelectableList)`
	padding: ${(props) => props.theme.spacing.xxs};
	box-sizing: border-box;
`;

const Top = styled.div`
	${({ theme }) => css`
		display: flex;
		gap: ${theme.spacing.md};
		align-items: center;
		margin-bottom: ${theme.spacing.md};
	`}
`;

const Bottom = styled.div`
	display: flex;
	gap: ${(props) => props.theme.spacing.md};
	align-items: center;
`;

const Label = styled.h3`
	${({ theme }) => css`
		flex-grow: 1;
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;

const StyledSearchInput = styled(SearchInput)`
	flex-grow: 1;
`;

const AddOrRemoveButton = styled(Button)`
	${({ theme }) => css`
		// These match the values of the search/text input
		height: calc(${theme.spacing.md} * 2 + ${theme.typography.fontSizes.medium});
		border-radius: 18px;
	`}
`;
