import React, { useRef, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import Page from "Component/Global/Navigation/Page";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import ModelBoundTextInput from "Component/Global/Input/ModelBoundTextInput";
import AuthenticationService from "Services/AuthenticationService";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { ValidationError } from "validate";
import ForgotPasswordRequest, { ForgotPasswordRequestValidation } from "Models/Request/ForgotPasswordRequest";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import StackedButtonGroup from "Component/Global/Content/StackedButtonGroup";
import Button from "Component/Global/Input/Button";
import { PageRoutes } from "Utils/Navigation";

export interface ForgotPasswordPageProps {
	className?: string;
}

export default function ForgotPasswordPage(props: ForgotPasswordPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const authenticationService = useDi(AuthenticationService);

	const formEl = useRef<HTMLFormElement | null>(null);

	const [isLoading, setIsLoading] = useState(false);

	const model = useRef<ForgotPasswordRequest>({
		email: "",
	});

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault();

			const errors = ForgotPasswordRequestValidation.validate(model.current);
			setValidationErrors(errors);

			if (errors.length === 0) {
				setIsLoading(true);

				await authenticationService.sendPasswordResetEmail(model.current.email);

				modalManager.showAlert("Please check your email for further instructions.");
				navigate(PageRoutes.Login, { replace: true });
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSendEmailClicked = () => {
		if (formEl.current) {
			formEl.current.requestSubmit();
		}
	};
	return (
		<StyledForgotPasswordPage
			titleBar={{
				title: "Forgot",
				leftIcon: IconName.ArrowLeft,
				onLeftIconClicked: () => navigate(-1),
			}}
			padding={"0"}
			className={`ForgotPasswordPage ${props.className || ""}`}
		>
			<StackedButtonGroup
				contentPadding={theme.spacing.sm}
				buttons={[
					<SendEmailButton key="submit" isResponsive scheme={theme.schemes.accent2} onClick={handleSendEmailClicked} tapAnimation={false} disabled={isLoading}>
						Send reset email {isLoading && <Loader layoutType={LoaderLayout.InlineButton} />}
					</SendEmailButton>,
				]}
			>
				<form ref={formEl} onSubmit={handleSubmit} noValidate>
					<ModelBoundTextInput
						type="email"
						label="Email address"
						model={model.current}
						modelProp="email"
						validationSchema={ForgotPasswordRequestValidation}
						validationErrors={validationErrors}
						disabled={isLoading}
					/>
					<HiddenSubmit type="submit" />
				</form>
			</StackedButtonGroup>
		</StyledForgotPasswordPage>
	);
}

const HiddenSubmit = styled.input`
	display: none;
`;

const SendEmailButton = styled(Button)`
	border-radius: unset;
`;

const StyledForgotPasswordPage = styled(Page)``;
