import React from "react";
import Button from "Component/Global/Input/Button";
import styled, { css, useTheme } from "styled-components";
import { ModalProps } from "./Modal";
import { useModalManager } from "Utils/ModalManager";
import { IScheme } from "Style/Color";
import Padding from "Component/Global/Content/Padding";

export type ConfirmModalProps = ModalProps & {
	cancelText?: string;
	confirmText?: string;
	scheme?: IScheme;
	onConfirm?: () => void;
};

export default function ConfirmModal(props: ConfirmModalProps) {
	const theme = useTheme();
	const modalManager = useModalManager();

	const cancelText = props.cancelText ?? "Cancel";
	const confirmText = props.confirmText ?? "Confirm";
	const confirmScheme = props.scheme ?? theme.schemes.accent1;

	const handleConfirmClicked = () => {
		props.onConfirm?.();
		modalManager.hide(props.id);
	};
	return (
		<StyledConfirmModal className={`ConfirmModal ${props.className || ""}`}>
			<Padding>
				{props.children && <Content>{props.children}</Content>}
				<ConfirmText>Are you sure?</ConfirmText>
				<Button onClick={() => modalManager.hide(props.id)} scheme={theme.schemes.shade1} isResponsive>
					{cancelText}
				</Button>
			</Padding>
			<ConfirmButton scheme={confirmScheme} onClick={handleConfirmClicked} isResponsive>
				{confirmText}
			</ConfirmButton>
		</StyledConfirmModal>
	);
}

const StyledConfirmModal = styled.div`
	text-align: center;
	width: 100%;
`;
const Content = styled.div`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.small};
		margin-bottom: ${theme.spacing.xl};
	`}
`;
const ConfirmText = styled.p`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
		margin-bottom: ${theme.spacing.xl};
	`}
`;
const ConfirmButton = styled(Button)`
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;
