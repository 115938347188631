import Schema from "validate";

export default interface SignupRequest {
	name: string;
	email: string;
	password: string;
	confirmPassword: string;
}

export const SignupRequestValidation = new Schema({
	name: {
		required: true,
		message: {
			required: "Name is required",
		},
	},
	email: {
		required: true,
		match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
		message: {
			required: "Email is required",
			match: "Email is not valid",
		},
	},
	password: {
		required: true,
		length: { min: 8 },
		message: {
			required: "Password is required",
			length: "Password must be at least 8 characters",
		},
	},
	confirmPassword: {
		required: true,
		length: { min: 8 },
		use: {
			sameAs: (val, obj: unknown) => {
				return val === (obj as SignupRequest).password;
			},
		},
		message: {
			required: "Password is required",
			length: "Password must be at least 8 characters",
			sameAs: "Password and confirm password must match",
		},
	},
});
