import React, { ChangeEventHandler, useMemo, useState, useEffect, useRef, PropsWithChildren, HTMLInputTypeAttribute, forwardRef, KeyboardEventHandler, FocusEventHandler, MouseEventHandler } from "react";
import autosize from "autosize";
import { transparentize } from "Style/Color";
import { ITheme } from "Style/Theme";
import styled, { css } from "styled-components";
import { v4 as uuid } from "uuid";
import { motion } from "framer-motion";
import FocusedTextInputStore from "Stores/FocusedTextInputStore";
import { useDi } from "Utils/DependencyInjection";

export type TextInputProps = PropsWithChildren<{
	className?: string;
	value?: string;
	onChange?: (val: string) => void;
	label?: string;
	placeholder?: string;
	multiline?: boolean;
	readonly?: boolean;
	disabled?: boolean;
	type?: HTMLInputTypeAttribute;
	onEnterPressed?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onClick?: () => void;
}>;

const TextInput = forwardRef<HTMLDivElement, TextInputProps>((props, ref) => {
	const focusedTextInputStore = useDi(FocusedTextInputStore);
	const inputRef = useRef<HTMLInputElement>(null);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const fieldId = useMemo(() => uuid(), []);

	const [value, setValue] = useState(props.value ?? "");

	useEffect(() => {
		setValue(props.value ?? "");
		if (textareaRef.current) {
			autosize.update(textareaRef.current);
		}
	}, [props.value, props.readonly, props.multiline]);

	useEffect(() => {
		const el = textareaRef.current;
		if (el) {
			autosize(el);
		}
		return () => {
			if (el) {
				autosize.destroy(el);
			}
		};
	}, [textareaRef, props.multiline]);

	const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		const val = e.target.value;
		setValue(val);
		props.onChange?.(val);
	};

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "Enter") {
			props.onEnterPressed?.();
		}
	};

	const handleFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		if (!e.target.readOnly && !e.target.disabled) {
			focusedTextInputStore.element = e.target;
			props.onFocus?.();
		}
	};

	const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		if (focusedTextInputStore.element === e.target) {
			focusedTextInputStore.element = undefined;
		}
		props.onBlur?.();
	};

	const handleClick: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
		props.onClick?.();
	};

	const commonProps = {
		className: "Input",
		value,
		readOnly: props.readonly,
		disabled: props.disabled,
		onChange: handleChange,
		placeholder: props.placeholder,
		id: fieldId,
		name: fieldId,
		onFocus: handleFocus,
		onBlur: handleBlur,
		onClick: handleClick,
	};

	return (
		<StyledTextInput className={`TextInput ${props.className || ""}`} ref={ref}>
			{props.label && <StyledLabel htmlFor={fieldId}>{props.label}</StyledLabel>}
			{props.multiline ? <StyledTextArea ref={textareaRef} {...commonProps} /> : <StyledInput type={props.type} ref={inputRef} {...commonProps} onKeyDown={handleKeyDown} />}
			{props.children}
		</StyledTextInput>
	);
});

export default motion(TextInput);

const StyledTextInput = styled.div``;
const StyledLabel = styled.label`
	${({ theme }) => css`
		display: block;
		color: ${theme.schemes.shade2.text};
		font-size: ${theme.typography.fontSizes.small};
		font-weight: ${theme.typography.fontWeight.bold};
		margin-bottom: ${theme.spacing.xs};
	`}
`;

const getCommonStyles = (theme: ITheme) => {
	return css`
		outline: none;
		border: none;
		border-radius: 18px;
		width: 100%;
		box-sizing: border-box;
		padding: ${theme.spacing.md};
		background: ${theme.palette.shade2};
		color: ${theme.schemes.shade2.text};
		font-size: ${theme.typography.fontSizes.small};
		line-height: ${theme.typography.fontSizes.small};
		font-family: ${theme.typography.fontFamily};
		height: calc(${theme.typography.fontSizes.small} + calc(${theme.spacing.md} * 2));

		&::placeholder,
		&:disabled {
			color: ${theme.palette.shade1};
		}

		&:read-only {
			background: ${transparentize(theme.palette.shade2, 1)};
			padding: 0;
			border-radius: 0;
		}
	`;
};

const StyledTextArea = styled.textarea`
	${({ theme }) => css`
		${getCommonStyles(theme)}
		resize: none;
		overflow-y: hidden;
	`}
`;
const StyledInput = styled.input`
	${({ theme }) => css`
		${getCommonStyles(theme)}
	`}
`;
