import dayjs from "dayjs";
import { motion, PanHandlers } from "framer-motion";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import DateUtil from "Utils/DateUtil";
import DomUtil from "Utils/DomUtil";

export interface Category<T, K> {
	name: string;
	shortName?: string;
	value?: T;
	disabled?: boolean;
	items?: K[];
}

export type CategoryScrollerOnChangeHandler<T, K> = (item: Category<T, K>) => void;

export type CategoryScrollerProps<T, K> = PropsWithChildren<{
	className?: string;
	items: Category<T, K>[];
	selectedItem?: Category<T, K>;
	onChange?: CategoryScrollerOnChangeHandler<T, K>;
	vertical?: boolean;
	gap?: string;
}>;

export default function CategoryScroller<T, K>(props: CategoryScrollerProps<T, K>) {
	const scrollerEl = useRef<HTMLDivElement>(null);

	const itemEls = useRef<Array<HTMLElement | null>>([]);

	const [position, setPosition] = useState<number>();

	const [isDragging, setIsDragging] = useState(false);
	const [selectedItem, setSelectedItem] = useState<Category<T, K>>();

	useEffect(() => {
		setSelectedItem(props.selectedItem);
	}, [props.selectedItem]);

	const handlePanStart: PanHandlers["onPanStart"] = () => {
		setIsDragging(true);
	};

	const handlePanEnd: PanHandlers["onPanEnd"] = () => {
		setIsDragging(false);
		setPosition(undefined);
	};

	const handlePan: PanHandlers["onPan"] = (e, info) => {
		e.preventDefault();
		e.stopPropagation();

		if (!isDragging) {
			return;
		}

		const x = (scrollerEl.current?.offsetLeft ?? 0) + (scrollerEl.current?.offsetWidth ?? 0) / 2;
		const y = info.point.y;

		const target = document.elementFromPoint(x, y) as HTMLElement;

		if (!target) {
			return;
		}

		const index = itemEls.current.indexOf(target);

		if (index >= 0) {
			const item = props.items[index];
			handleItemSelected(item);

			setIsDragging(true);
			setPosition(() => {
				if (!scrollerEl.current) {
					return 0;
				}

				let newPosition = info.point.y - DomUtil.getOffsetTop(scrollerEl.current);

				if (newPosition < 0) {
					newPosition = 0;
				} else if (newPosition > scrollerEl.current.offsetHeight - target.offsetHeight) {
					newPosition = scrollerEl.current.offsetHeight - target.offsetHeight;
				}

				return newPosition;
			});
		}
	};

	const handleItemSelected = (item: Category<T, K>) => {
		if (!item.disabled && item !== selectedItem) {
			setSelectedItem(item);
			props.onChange?.(item);
		}
	};

	return (
		<StyledCategoryScroller className={`CategoryScroller ${props.className || ""}`} onPanEnd={handlePanEnd} onPan={handlePan}>
			<Content>{props.children}</Content>
			<Scroller ref={scrollerEl} $vertical={props.vertical} $gap={props.gap} onPanStart={handlePanStart}>
				{selectedItem && isDragging && <Indicator style={{ y: position, right: scrollerEl.current?.offsetWidth ?? 0 }}>{selectedItem.shortName ?? selectedItem.name}</Indicator>}
				{props.items.map((item, i) => (
					<Item key={i} ref={(el) => (itemEls.current[i] = el)} $selected={item === selectedItem} $disabled={item.disabled} $vertical={props.vertical} onClick={() => handleItemSelected(item)}>
						{item.shortName ?? item.name}
					</Item>
				))}
			</Scroller>
		</StyledCategoryScroller>
	);
}

const StyledCategoryScroller = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr;
`;

const Content = styled.div`
	overflow: auto;
`;

const Scroller = styled(motion.div)<{ $vertical?: boolean; $gap?: string }>`
	${({ theme, $vertical, $gap }) => css`
		position: relative;
		height: 100%;
		width: auto;
		display: flex;
		flex-direction: column;
		gap: ${$gap ? $gap : $vertical ? theme.spacing.xs : theme.spacing.xxs};
		padding:
		touch-action: none;
		box-sizing: border-box;
		padding: ${theme.spacing.xs} ${theme.spacing.sm};
		${theme.typography.noSelect}
	`}
`;

const Item = styled.div<{ $disabled?: boolean; $selected?: boolean; $vertical?: boolean }>`
	${({ theme, $disabled, $selected, $vertical }) => css`
		display: inline;
		padding: 0;
		margin: 0;
		text-align: center;
		font-size: ${theme.typography.fontSizes.small};
		line-height: ${theme.typography.fontSizes.small};
		opacity: ${$disabled ? 0.5 : 1};
		text-decoration: ${$selected ? "underline" : "none"};
		touch-action: none;
		${$vertical
			? css`
					writing-mode: vertical-rl;
					text-orientation: mixed;
			  `
			: ""}
	`}
`;

const Indicator = styled(motion.div)`
	${({ theme }) => css`
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		font-size: ${theme.typography.fontSizes.large};
		background: ${theme.schemes.accent1.passive};
		color: ${theme.schemes.accent1.text};
		border-radius: ${theme.spacing.xs};
		min-width: ${theme.spacing.xxl};
		width: max-content;
		height: ${theme.spacing.xxl};
		line-height: ${theme.spacing.xxl};
		padding: 0 ${theme.spacing.md};
		box-sizing: border-box;
	`}
`;

export function createAlphabeticalCategories<T>(items: T[], getName: (item: T) => string): Category<number, T>[] {
	const alphabet: Record<string, T[]> = {};

	for (let i = 65; i <= 90; i++) {
		alphabet[String.fromCharCode(i)] = [];
	}

	alphabet["#"] = [];

	items.forEach((item) => {
		const name = getName(item);
		const firstLetter = name[0].toUpperCase();

		if (alphabet[firstLetter]) {
			alphabet[firstLetter].push(item);
		} else {
			alphabet["#"].push(item);
		}
	});

	return Object.keys(alphabet).map((key) => {
		return {
			name: key,
			disabled: alphabet[key].length === 0,
			items: alphabet[key],
			value: alphabet[key].length ? items.indexOf(alphabet[key][0]) : undefined,
		} as Category<number, T>;
	});
}

export function createDateCategories<T>(items: T[], getDate: (item: T) => string): Category<number, T>[] {
	const dates: Record<keyof typeof dateDisplays, T[]> = {
		Today: [],
		Yesterday: [],
		Week: [],
		Month: [],
		Year: [],
		Older: [],
	};

	items.forEach((item) => {
		const now = dayjs();
		const date = DateUtil.parse(getDate(item));

		const diffInDays = now.diff(date, "day");

		if (diffInDays === 0) {
			dates.Today.push(item);
		} else if (diffInDays === 1) {
			dates.Yesterday.push(item);
		} else if (diffInDays <= 7) {
			dates.Week.push(item);
		} else if (diffInDays <= 30) {
			dates.Month.push(item);
		} else if (diffInDays <= 365) {
			dates.Year.push(item);
		} else {
			dates.Older.push(item);
		}
	});

	return Object.keys(dates).map((k) => {
		const key = k as keyof typeof dateDisplays;
		return {
			name: dateDisplays[key],
			shortName: key,
			disabled: dates[key].length === 0,
			items: dates[key],
			value: dates[key].length ? items.indexOf(dates[key][0]) : -1,
		} as Category<number, T>;
	});
}

const dateDisplays = {
	Today: "Today",
	Yesterday: "Yesterday",
	Week: "This Week",
	Month: "This Month",
	Year: "This Year",
	Older: "Older",
};
