import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { useDi } from "Utils/DependencyInjection";
import Background from "Static/Image/Groups/empty.jpg";
import Button from "Component/Global/Input/Button";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import { IconName } from "Component/Global/Content/Icon";
import { generatePath, useNavigate } from "react-router-dom";
import { NestedRoutes_Groups } from "Utils/Navigation";
import SelectableList, { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import { Group } from "Models/Group";
import EmptyPrompt from "Component/Global/Content/EmptyPrompt";
import GroupService from "Services/GroupService";
import modalManager from "Utils/ModalManager";

export interface GroupsListPageProps {
	className?: string;
}

export default function GroupsListPage(props: GroupsListPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const groupService = useDi(GroupService);
	const [isLoading, setIsLoading] = useState(false);
	const [groups, setGroups] = useState<Group[]>([]);

	useEffect(() => {
		setIsLoading(true);
		groupService
			.fetchForList()
			.then(setGroups)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => setIsLoading(false));
	}, [groupService]);

	const isEmpty = isLoading || !groups.length;

	const groupItems = useMemo(() => {
		return groupService.asSelectableList(groups, (g) => g.name);
	}, [groupService, groups]);

	const handleGroupSelected: SelectableListOnChangeHandler = (selection) => {
		if (selection) {
			navigate(generatePath(NestedRoutes_Groups.Details, { id: selection.at(0)!.id }));
		}
	};

	return (
		<StyledGroupsListPage
			className={`GroupsListPage ${props.className || ""}`}
			background={groupItems.length ? undefined : Background}
			titleBar={{
				title: "Groups",
				leftIcon: isEmpty ? undefined : IconName.PlusCircle,
				onLeftIconClicked: isEmpty ? undefined : () => navigate(NestedRoutes_Groups.New),
				rightIcon: isEmpty ? undefined : IconName.Search,
				onRightIconClicked: isEmpty ? undefined : () => navigate(NestedRoutes_Groups.Search),
				transparentIcons: true,
			}}
		>
			{isLoading ? (
				<Loader layoutType={LoaderLayout.CenterStretchHeight} />
			) : (
				<>
					{groups.length ? (
						<SelectableList key="groups-list" items={groupItems} type={SelectableListType.SingleColumn} onChange={handleGroupSelected} verticalAlign={"bottom"} />
					) : (
						<EmptyPrompt prompt="Groups help you organise your characters together.">
							<Button scheme={theme.schemes.accent2} onClick={() => navigate(NestedRoutes_Groups.New)}>
								Create group
							</Button>
						</EmptyPrompt>
					)}
				</>
			)}
		</StyledGroupsListPage>
	);
}

const StyledGroupsListPage = styled(Page)``;
