import Gender from "Enums/Gender";
import { AnnotationsMap } from "mobx";
import { Culture } from "Models/Culture";
import { Personality } from "Models/Personality";
import { Profession } from "Models/Profession";
import { Race } from "Models/Race";

export type AbstractTraitsStorePrivateProps =
	| "_selectedRaceIds"
	| "_selectedCultureIds"
	| "_selectedGenders"
	| "_selectedPersonalityIds"
	| "_selectedProfessionIds"
	| "_races"
	| "_cultures"
	| "_personalities"
	| "_professions";

export const AbstractTraitsStoreProps: AnnotationsMap<AbstractTraitsStore, AbstractTraitsStorePrivateProps> = {
	_selectedRaceIds: true,
	_selectedCultureIds: true,
	_selectedGenders: true,
	_selectedPersonalityIds: true,
	_selectedProfessionIds: true,
	_races: true,
	_cultures: true,
	_personalities: true,
	_professions: true,
	cultures: true,
	personalities: true,
	professions: true,
	races: true,
	selectedCultureIds: true,
	selectedCultures: true,
	selectedGenders: true,
	selectedPersonalities: true,
	selectedPersonalityIds: true,
	selectedProfessionIds: true,
	selectedProfessions: true,
	selectedRaceIds: true,
	selectedRaces: true,
};

export default abstract class AbstractTraitsStore {
	private _selectedRaceIds: string[] = [];
	private _selectedCultureIds: string[] = [];
	private _selectedGenders: Gender[] = [];
	private _selectedPersonalityIds: string[] = [];
	private _selectedProfessionIds: string[] = [];

	private _races: Partial<Race>[] = [];
	private _cultures: Partial<Culture>[] = [];
	private _personalities: Partial<Personality>[] = [];
	private _professions: Partial<Profession>[] = [];

	get selectedRaceIds() {
		return this._selectedRaceIds;
	}
	set selectedRaceIds(selectedRaceIds: string[]) {
		this._selectedRaceIds = selectedRaceIds;
	}

	get selectedCultureIds() {
		return this._selectedCultureIds;
	}
	set selectedCultureIds(selectedCultureIds: string[]) {
		this._selectedCultureIds = selectedCultureIds;
	}

	get selectedGenders() {
		return this._selectedGenders;
	}
	set selectedGenders(selectedGenders: Gender[]) {
		this._selectedGenders = selectedGenders;
	}

	get selectedPersonalityIds() {
		return this._selectedPersonalityIds;
	}
	set selectedPersonalityIds(selectedPersonalityIds: string[]) {
		this._selectedPersonalityIds = selectedPersonalityIds;
	}

	get selectedProfessionIds() {
		return this._selectedProfessionIds;
	}
	set selectedProfessionIds(selectedProfessionIds: string[]) {
		this._selectedProfessionIds = selectedProfessionIds;
	}

	get selectedRaces() {
		return this._races.filter((race) => this._selectedRaceIds.indexOf(race.id!) >= 0);
	}

	get selectedCultures() {
		return this._cultures.filter((culture) => this._selectedCultureIds.indexOf(culture.id!) >= 0);
	}
	get selectedProfessions() {
		return this._professions.filter((profession) => this._selectedProfessionIds.indexOf(profession.id!) >= 0);
	}

	get selectedPersonalities() {
		return this._personalities.filter((personality) => this._selectedPersonalityIds.indexOf(personality.id!) >= 0);
	}

	get races() {
		return this._races;
	}
	set races(races: Partial<Race>[]) {
		this._races = races;
	}

	get cultures() {
		return this._cultures;
	}
	set cultures(cultures: Partial<Culture>[]) {
		this._cultures = cultures;
	}

	get professions() {
		return this._professions;
	}
	set professions(professions: Partial<Profession>[]) {
		this._professions = professions;
	}

	get personalities() {
		return this._personalities;
	}
	set personalities(personalities: Partial<Personality>[]) {
		this._personalities = personalities;
	}
}
