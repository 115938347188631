import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import TitleBar from "Component/Global/Content/TitleBar";
import Button from "Component/Global/Input/Button";
import EditableText from "Component/Global/Input/EditableText";
import modalManager from "Utils/ModalManager";
import Padding from "Component/Global/Content/Padding";

export interface CustomNameProps {
	className?: string;
	value?: string;
	onChange: (val?: string) => void;
}

export default function CustomName(props: CustomNameProps) {
	const theme = useTheme();
	const [name, setName] = useState<string>(props.value ?? "");
	const [savedName, setSavedName] = useState<string>(props.value ?? "");

	const handleSaveClicked = async () => {
		const n = name.trim();
		setSavedName(n);
		props.onChange(n ? n : undefined);
	};

	const handleCloseClicked = () => {
		modalManager.hide("filters-name");
	};

	return (
		<StyledCustomName className={`CustomName ${props.className || ""}`}>
			<StyledTitleBar title="Custom name" />
			<Content>
				<EditableText title="Character name" textItems={[{ placeholder: "Tap edit to set name", value: name, onChange: setName }]} onSave={handleSaveClicked} />
			</Content>
			<CloseButton onClick={handleCloseClicked} isResponsive scheme={savedName ? theme.schemes.accent1 : undefined}>
				{savedName ? "Use custom name" : "Close"}
			</CloseButton>
		</StyledCustomName>
	);
}

const StyledTitleBar = styled(TitleBar)`
	flex-grow: 0;
	flex-shrink: 0;
`;

const Content = styled(Padding)`
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const CloseButton = styled(Button)`
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;

const StyledCustomName = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};
`;
