import React, { useEffect } from "react";
import { object, use } from "rsdi";
import AuthenticationService from "Services/AuthenticationService";
import CharacterService from "Services/CharacterService";
import ConfigService from "Services/ConfigService";
import GroupService from "Services/GroupService";
import TraitsService from "Services/TraitsService";
import UploadService from "Services/UploadService";
import UserService from "Services/UserService";
import CharacterPageStore from "Stores/CharacterPageStore";
import FocusedTextInputStore from "Stores/FocusedTextInputStore";
import GeneratorStore from "Stores/GeneratorStore";
import GroupArchetypeStore from "Stores/GroupArchetypeStore";
import TabMenuStore from "Stores/TabMenuStore";
import UserStore from "Stores/UserStore";
import styled from "styled-components";
import ApolloClient from "Utils/ApolloClient";
import AxiosClient from "Utils/AxiosClient";
import { injectedServices, useDi } from "Utils/DependencyInjection";
import Desktop from "./Desktop";
import ModalContainer from "./Global/Interactive/Modal/ModalContainer";
import Mobile from "./Mobile";
import "Utils/ExtensionMethods";
// import useInApp from "Hooks/UseInApp";

injectedServices.add({
	ConfigService: object(ConfigService),
	AuthenticationService: object(AuthenticationService),
	AxiosClient: object(AxiosClient).construct(use(ConfigService), use(AuthenticationService)),
	ApolloClient: object(ApolloClient).construct(use(ConfigService), use(AuthenticationService)),
	FocusedTextInputStore: object(FocusedTextInputStore),
	UserStore: object(UserStore),
	TabMenuStore: object(TabMenuStore),
	GroupArchetypeStore: object(GroupArchetypeStore),
	GeneratorStore: object(GeneratorStore),
	CharacterPageStore: object(CharacterPageStore),
	UploadService: object(UploadService).construct(use(ConfigService), use(AxiosClient), use(AuthenticationService)),
	TraitsService: object(TraitsService).construct(use(ApolloClient)),
	CharacterService: object(CharacterService).construct(use(ApolloClient), use(AxiosClient), use(UploadService)),
	GroupService: object(GroupService).construct(use(ApolloClient), use(UploadService)),
	UserService: object(UserService).construct(use(ApolloClient)),
});

export interface AppProps {
	className?: string;
}

export default function App(props: AppProps) {
	// const { isDesktop } = useInApp();
	const isDesktop = false;

	const authService = useDi(AuthenticationService);
	const userStore = useDi(UserStore);

	useEffect(() => {
		const unsubscribe = authService.onIdTokenChanged(async (user) => {
			// We also check the display name, since it is a required field for us
			// During email/password signup, this functional is called twice, once without a displayName, and once with
			userStore.currentUser = user && user?.displayName ? await authService.fetchCurrentUser() : undefined;
		});
		return unsubscribe;
	}, [authService, userStore]);

	return (
		<>
			<StyledApp className={`App ${props.className || ""}`}>{isDesktop ? <Desktop /> : <Mobile />}</StyledApp>
			<ModalContainer />
		</>
	);
}

const StyledApp = styled.div`
	height: 100%;
	width: 100vw;
`;
