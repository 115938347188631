export const _FIELDS_CHARACTER_LIST = `
id
created
modified
isSaved
name
title
gender
cultureId
culture {
	name
}
raceId
race {
	name
}
images(where: {isCover: {eq: true}}) {
	positionX
	positionY
	imageId
	isCover
}
professionId
profession {
	name
}
personalityId
notes {
	id
}
details {
	id
}
roleplay {
	id
}
`;

export const _FIELDS_CHARACTER_LIST_WITH_GROUPS = `
${_FIELDS_CHARACTER_LIST}
groups {
	id
	name
}
`;

export const _FIELDS_CHARACTER_DETAIL = `
id
description
appearance
personality
bonds
flaws
ideals
`;

export const _FIELDS_CHARACTER_NOTE = `
id
notes
items
`;

export const _FIELDS_CHARACTER_ROLEPLAY = `
id
backstory
goals
secrets
`;

export const _FIELDS_CHARACTER_DETAILED = `
id
name
title
age
gender
isSaved

details {
	${_FIELDS_CHARACTER_DETAIL}
}
notes {
	${_FIELDS_CHARACTER_NOTE}
}
roleplay {
	${_FIELDS_CHARACTER_ROLEPLAY}
}

race {
	id
	name
}
culture {
	id
	name
}
personality {
	id
	name
}
profession {
	id
	name
}

images(where: {isCover: {eq: true}}) {
	positionX
	positionY
	imageId
	isCover
}

groups {
	id
	name
	images(where: {isCover: {eq: true}}) {
		positionX
		positionY
		imageId
		isCover
	}
}
`;

export const _FIELDS_GROUP_LIST = `
	id
	name
	images(where: {isCover: {eq: true}}) {
		positionX
		positionY
		imageId
		isCover
	}
`;

export const _FIELDS_GROUP_DETAILS = `
	id
	name
	details
	images(where: {isCover: {eq: true}}) {
		positionX
		positionY
		imageId
		isCover
	}
	members (where: {isSaved: {eq: true}}) {
		${_FIELDS_CHARACTER_LIST}
	}
	races {
		id
		name
	}
	cultures {
		id
		name
		raceId
	}
	personalities {
		id
		name
	}
	professions {
		id
		name
	}
	genders {
		gender
	}
`;
