/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import * as GraphQLQuery from "GraphQL/GraphQLQuery";
import { GraphQLQueryResponse, PaginatedGraphQLResponse } from "GraphQL/GraphQLResponse";
import { User } from "Models/User";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

export type GetUserByIdInput = {
	id: string;
};
export type GetUserByIdOutput = GraphQLQueryResponse<User, "getUserById">;

export const getUserById = async (apollo: Promise<Apollo<unknown>>, returnFields: string, id: string) => {
	const response = await (
		await apollo
	).query<GetUserByIdOutput, GetUserByIdInput>({
		variables: {
			id,
		},
		query: gql`
			fragment UserOutput on User {
				${returnFields}
			}
			query getUserById($id : UUID!) {
				getUserById(id: $id) {
					...UserOutput
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

export type UserInputFilter = {
	and?: UserInputFilter[];
	or?: UserInputFilter[];
	id?: GraphQLQuery.ComparableNullableOfGuidOperationFilterInput;
	created?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	modified?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	uid?: GraphQLQuery.StringOperationFilterInput;
	displayName?: GraphQLQuery.StringOperationFilterInput;
	email?: GraphQLQuery.StringOperationFilterInput;
	photoUrl?: GraphQLQuery.StringOperationFilterInput;
};
export type GetUsersInput = GraphQLQuery.PaginatedQueryInput<User, UserInputFilter>;
export type GetUsersOutput = PaginatedGraphQLResponse<User, "getUsers">;
const GetUsersInputTypes: Record<keyof GetUsersInput, string> = {
	first: "Int",
	after: "String",
	last: "Int",
	before: "String",
	where: "UserFilterInput",
	order: "[UserSortInput!]",
};
export const getUsers = async (apollo: Promise<Apollo<unknown>>, returnFields: string, variables: GetUsersInput = {}) => {
	const [operationVars, queryVars] = GraphQLQuery.getQueryVars(variables, GetUsersInputTypes);
	const response = await (
		await apollo
	).query<GetUsersOutput, GetUsersInput>({
		variables,
		query: gql`
			fragment UserOutput on User {
				${returnFields}
			}
			query getUsers${operationVars} {
				getUsers${queryVars} {
					totalCount
					pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					edges {
						cursor
						node {
							...UserOutput
						}
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
