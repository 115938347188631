import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, PanInfo, Transition, Variants } from "framer-motion";
import styled from "styled-components";
import { PivotChildProps, PivotItem } from "./Pivot";
import { DefaultTransition } from "Style/Animation";

export interface PivotContentProps extends PivotChildProps {
	className?: string;
	previousItem?: PivotItem;
}

export default function PivotContent(props: PivotContentProps) {
	const elRef = useRef<HTMLDivElement>(null);

	const previousItem = props.items.isFirst() ? undefined : props.items.peekPrevious();
	const currentItem = props.items.current();
	const nextItem = props.items.isLast() ? undefined : props.items.peekNext();

	const [panXPercentages, setPanXPercentages] = useState<PanXPercentages>(PAN_X_DEFAULT_POSITION);
	const panInProgress = useRef(false);
	const transitionInProgress = useRef<TransitionDirection>(undefined);
	const previousPanXPercentages = useRef(PAN_X_DEFAULT_POSITION);

	useEffect(() => {
		setPanXPercentages(PAN_X_DEFAULT_POSITION);
	}, [currentItem]);

	const transitionToItem = (item: PivotItem) => {
		transitionInProgress.current = item === previousItem ? "LeftToRight" : "RightToLeft";
		previousPanXPercentages.current = [...panXPercentages];
		setPanXPercentages(PAN_X_DEFAULT_POSITION);
		props.onRequestUpdate(item);
	};

	const handlePan: PanEventHandler = (e, info) => {
		if (elRef.current) {
			let lock: PanXLocking;
			if (!previousItem) {
				lock = "left";
			} else if (!nextItem) {
				lock = "right";
			}

			const percentages = calculatePanXPercentages(info, elRef.current, lock);

			panInProgress.current = true;
			setPanXPercentages(percentages);
		}
	};

	const handlePanEnd: PanEventHandler = (e, info) => {
		if (elRef.current) {
			panInProgress.current = false;

			// If we're moving faster in the Y direction than the X direction,
			// then we must be vertically scrolling, so don't do anything further
			if (Math.abs(info.velocity.y) > Math.abs(info.velocity.x)) {
				return;
			}

			// If this is a quick "swipe" (high velocity)
			if (Math.abs(info.velocity.x) > 30) {
				if (info.offset.x > 0 && previousItem) {
					// left to right
					transitionToItem(previousItem);
				} else if (info.offset.x < 0 && nextItem) {
					// right to left
					transitionToItem(nextItem);
				}
			} else {
				// Otherwise, we will detect based on the percentage of scrolling when the pan finished
				const percentages = calculatePanXPercentages(info, elRef.current);

				if (percentages[1] > 0.5 && previousItem) {
					// bring the left one in
					transitionToItem(previousItem);
				} else if (percentages[1] < -0.5 && nextItem) {
					// bring the right one in
					transitionToItem(nextItem);
				} else {
					// Reset to center
					transitionInProgress.current = percentages[1] < 0 ? "LeftToRight" : "RightToLeft";
					setPanXPercentages(PAN_X_DEFAULT_POSITION);
				}
			}
		}
	};

	const innerTransition: Transition = {
		duration: panInProgress.current ? 0 : DefaultTransition.duration,
		ease: panInProgress.current ? "linear" : DefaultTransition.ease,
	};

	const outerTransition: Transition = {
		duration: transitionInProgress.current ? DefaultTransition.duration : 0,
		ease: transitionInProgress.current ? DefaultTransition.ease : "linear",
	};

	const variants: Variants = {
		bumpIn: () => {
			if (props.previousItem) {
				if (panXPercentages[1] > 0.5) {
					return {
						x: `${panXPercentages[0] * 100}%`,
					};
				} else if (panXPercentages[1] < -0.5) {
					return {
						x: `${panXPercentages[2] * 100}%`,
					};
				} else if (transitionInProgress.current !== undefined) {
					return {
						x: `${(transitionInProgress.current == "LeftToRight" ? previousPanXPercentages.current[0] : previousPanXPercentages.current[2]) * 100}%`,
					};
				} else {
					const previousIndex = props.items.indexOf(props.previousItem);
					const currentIndex = props.items.getCurrentIndex();
					return {
						x: currentIndex > previousIndex ? "100%" : "-100%",
					};
				}
			}
			return {};
		},
		bumpOut: (newItem?: PivotItem) => {
			if (newItem) {
				const newIndex = props.items.indexOf(newItem);
				const currentIndex = props.items.getCurrentIndex();

				return {
					x: currentIndex > newIndex ? "100%" : "-100%",
				};
			}
			return {};
		},
	};

	return (
		<StyledPivotContent ref={elRef} className={`PivotContent ${props.className || ""}`}>
			{previousItem && (
				<PivotBody animate={{ x: `${panXPercentages[0] * 100}%` }} transition={transitionInProgress.current === "RightToLeft" ? outerTransition : { duration: 0 }} initial={false} key={previousItem.id}>
					{previousItem.component()}
				</PivotBody>
			)}
			<AnimatePresence custom={currentItem} initial={false}>
				<PivotBody
					key={currentItem.id}
					initial="bumpIn"
					animate={{ x: `${panXPercentages[1] * 100}%` }}
					exit="bumpOut"
					variants={variants}
					transition={innerTransition}
					onPan={handlePan}
					onPanEnd={handlePanEnd}
					onAnimationComplete={() => (transitionInProgress.current = undefined)}
				>
					<Scrollable>{currentItem.component()}</Scrollable>
				</PivotBody>
			</AnimatePresence>
			{nextItem && (
				<PivotBody animate={{ x: `${panXPercentages[2] * 100}%` }} transition={transitionInProgress.current === "LeftToRight" ? outerTransition : { duration: 0 }} initial={false} key={nextItem.id}>
					{nextItem.component()}
				</PivotBody>
			)}
		</StyledPivotContent>
	);
}

type PanEventHandler = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
type PanXPercentages = [number, number, number];
type PanXLocking = undefined | "left" | "right";
type TransitionDirection = undefined | "LeftToRight" | "RightToLeft";

const Scrollable = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	touch-action: pan-y;
`;

const PivotBody = styled(motion.div)`
	position: absolute;
	height: 100%;
	width: 100%;
	touch-action: pan-x;
`;

const StyledPivotContent = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	color: ${(props) => props.theme.palette.secondary};
`;

const PAN_X_DEFAULT_POSITION: PanXPercentages = [-1, 0, 1];

function calculatePanXPercentages(info: PanInfo, wrapperEl: HTMLDivElement, lock?: PanXLocking): PanXPercentages {
	const totalWidth = wrapperEl.clientWidth;
	const isLeftToRight = info.offset.x > 0;

	if ((lock === "left" && isLeftToRight) || (lock === "right" && !isLeftToRight)) {
		return PAN_X_DEFAULT_POSITION;
	}

	const percentage = info.offset.x / totalWidth;
	const leftPercentage = isLeftToRight ? percentage - 1 : (1 - percentage) * -1;
	const centerPercentage = isLeftToRight ? percentage : percentage;
	const rightPercentage = isLeftToRight ? 1 + percentage : 1 + percentage;

	return [leftPercentage, centerPercentage, rightPercentage];
}
