import React, { ChangeEventHandler, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { v4 as uuid } from "uuid";

export interface CheckboxProps {
	className?: string;
	value?: boolean;
	onChange?: (checked: boolean) => void;
	label?: string;
	readonly?: boolean;
	disabled?: boolean;
}

export default function Checkbox(props: CheckboxProps) {
	const [checked, setChecked] = useState(props.value ?? false);
	const fieldId = useMemo(() => uuid(), []);

	const handleCheckboxChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
		const checked = e.target.checked;
		setChecked(checked);
		props.onChange?.(checked);
	};

	return (
		<StyledCheckbox className={`Checkbox ${props.className || ""}`}>
			<StyledInput type="checkbox" checked={checked} onChange={handleCheckboxChanged} />
			{props.label && <StyledLabel htmlFor={fieldId}>{props.label}</StyledLabel>}
		</StyledCheckbox>
	);
}

const StyledInput = styled.input`
	${({ theme }) => css`
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0;
		padding: 0;
		appearance: none;
		background-color: transparent;
		display: inline-block;
		width: ${theme.spacing.xl};
		height: ${theme.spacing.xl};
		border: 4px solid ${theme.palette.secondary};
		border-radius: 8px;
		cursor: pointer;

		&:checked {
			background-color: ${theme.palette.accent2};
		}
	`}
`;

const StyledLabel = styled.label`
	${({ theme }) => css`
		display: block;
		color: ${theme.schemes.shade2.text};
		font-size: ${theme.typography.fontSizes.small};
		font-weight: ${theme.typography.fontWeight.bold};
		margin-left: ${theme.spacing.xs};
	`}
`;

const StyledCheckbox = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		margin-bottom: ${theme.spacing.xs};
	`}
`;
