import { ApolloError, gql } from "@apollo/client";
import AllFilterDataResponse from "Models/Response/AllFilterDataResponse";
import ApolloClient from "Utils/ApolloClient";

export default class TraitsService {
	private readonly _apolloClient: ApolloClient;

	constructor(apolloClient: ApolloClient) {
		this._apolloClient = apolloClient;
	}

	public async getAllFilterData() {
		const apollo = await this._apolloClient.instance();

		const response = await apollo.query<AllFilterDataResponse>({
			query: QUERY_GET_ALL_FILTER_DATA,
		});

		if (response.errors) {
			throw new ApolloError({ graphQLErrors: response.errors });
		}

		return response.data;
	}
}

const _PARTIAL_FILTER_PAGING = `(first: 99, order: {name: ASC})`;

const QUERY_GET_ALL_FILTER_DATA = gql`
	query getAllFilterData {
		getRaces${_PARTIAL_FILTER_PAGING} {
			nodes {
				id
				name
			}
		}
		getCultures${_PARTIAL_FILTER_PAGING} {
			nodes {
				id
				name
				raceId
			}
		}
		getProfessions${_PARTIAL_FILTER_PAGING} {
			nodes {
				id
				name
			}
		}
		getPersonalities${_PARTIAL_FILTER_PAGING} {
			nodes {
				id
				name
			}
		}
	}
`;
