import React, { useCallback, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Search from "Component/Global/Interactive/Search";
import { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Page from "Component/Global/Navigation/Page";
import { generatePath, useNavigate } from "react-router-dom";
import GroupService from "Services/GroupService";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import { NestedRoutes_Groups } from "Utils/Navigation";
import modalManager from "Utils/ModalManager";

export interface GroupSearchPageProps {
	className?: string;
}

export default function GroupSearchPage(props: GroupSearchPageProps) {
	const navigate = useNavigate();
	const groupService = useDi(GroupService);
	const [isLoading, setIsLoading] = useState(false);

	const handleGroupSelected: SelectableListOnChangeHandler = (selectedItems) => {
		if (selectedItems.length === 1) {
			navigate(generatePath(NestedRoutes_Groups.Details, { id: selectedItems[0].id }));
		}
	};

	const performSearch = useCallback(
		async (query: string) => {
			try {
				setIsLoading(true);
				const groups = await groupService.searchForList(query);
				return groupService.asSelectableList(groups, (g) => g.name);
			} catch (err) {
				modalManager.showError(err as Error);
				return [];
			} finally {
				setIsLoading(false);
			}
		},
		[groupService],
	);

	return (
		<StyledGroupSearchPage
			className={`GroupSearchPage ${props.className || ""}`}
			titleBar={{
				title: "Search",
				rightIcon: IconName.X,
				onRightIconClicked: () => navigate(-1),
				transparentIcons: true,
			}}
		>
			{isLoading && <Loader layoutType={LoaderLayout.FullScreenOverlay} />}
			<Search performSearch={performSearch} selectableListOptions={{ type: SelectableListType.SingleColumn, onChange: handleGroupSelected }} />
		</StyledGroupSearchPage>
	);
}

const StyledGroupSearchPage = styled(Page)``;
