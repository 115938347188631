import React, { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { IconName } from "Component/Global/Content/Icon";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "Component/Global/Input/Button";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import modalManager from "Utils/ModalManager";
import { useDi } from "Utils/DependencyInjection";
import CharacterService from "Services/CharacterService";
import FileSaver from "file-saver";
import UserStore from "Stores/UserStore";
import fileDialog from "file-dialog";
import { NestedRoutes_Characters } from "Utils/Navigation";

export interface NewCharacterPageProps {
	className?: string;
}

export default function NewCharacterPage(props: NewCharacterPageProps) {
	const navigate = useNavigate();
	const theme = useTheme();

	const userStore = useDi(UserStore);
	const characterService = useDi(CharacterService);

	const [isLoading, setIsLoading] = useState(false);

	const spaceId = userStore.currentUser?.userSpaces?.at(0)?.spaceId;

	const handleImportClicked = async () => {
		try {
			if (!spaceId) {
				throw new Error("No space selected.");
			}
			const csvFiles = await fileDialog({ accept: "text/csv", multiple: false });
			if (csvFiles.length) {
				setIsLoading(true);
				await characterService.importFromCsv(spaceId, csvFiles[0]);
				modalManager.showAlert("Characters imported!", "Success", theme.schemes.success);
				navigate(-1);
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleExportClicked = async () => {
		try {
			if (!spaceId) {
				throw new Error("No space selected.");
			}
			setIsLoading(true);
			const template = await characterService.exportToCsv(spaceId);
			FileSaver.saveAs(template, "characters.csv");
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDownloadTemplateClicked = async () => {
		try {
			setIsLoading(true);
			const template = await characterService.downloadCsvTemplate();
			FileSaver.saveAs(template, "characters-template.csv");
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRandomClicked = async () => {
		try {
			setIsLoading(true);
			const character = await characterService.generateCharacter({
				save: true,
			});
			navigate(generatePath(NestedRoutes_Characters.Details, { id: character!.id }));
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleBlankClicked = async () => {
		try {
			setIsLoading(true);
			const character = await characterService.generateCharacter({
				blank: true,
				save: true,
			});
			navigate(generatePath(NestedRoutes_Characters.Details, { id: character!.id }));
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<StyledNewCharacterPage
			padding={theme.spacing.lg}
			className={`NewCharacterPage ${props.className || ""}`}
			titleBar={{
				title: "New character",
				rightIcon: IconName.X,
				onRightIconClicked: () => {
					navigate(-1);
				},
			}}
		>
			{isLoading && <Loader layoutType={LoaderLayout.FullScreenOverlay} key="loading-character-new" />}
			<ImportButtonGroup>
				<Button isResponsive scheme={theme.schemes.accent2} onClick={handleImportClicked}>
					Import characters
				</Button>
				<Button isResponsive onClick={handleExportClicked}>
					Export characters
				</Button>
				<Button isResponsive onClick={handleDownloadTemplateClicked}>
					Download CSV template
				</Button>
			</ImportButtonGroup>
			<StyledButton isResponsive onClick={handleRandomClicked}>
				Random character
			</StyledButton>
			<Button isResponsive scheme={theme.schemes.accent2} onClick={handleBlankClicked}>
				Blank character
			</Button>
		</StyledNewCharacterPage>
	);
}

const StyledButton = styled(Button)`
	margin: ${(props) => props.theme.spacing.lg} 0;
`;

const ImportButtonGroup = styled.div`
	${({ theme }) => css`
		display: flex;
		gap: ${theme.spacing.lg};
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: flex-end;

		&:before {
			content: "Upload CSV of your characters";
			font-size: ${theme.typography.fontSizes.small};
			color: ${theme.palette.shade1};
		}
	`}
`;

const StyledNewCharacterPage = styled(Page)`
	.PageContent {
		display: grid;
		gap: ${(props) => props.theme.spacing.xl};
		height: 100%;
		width: 100%;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto auto;
	}
`;
