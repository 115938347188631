import { createGlobalStyle, css } from "styled-components";

export const GlobalStyles = createGlobalStyle`
	${({ theme }) => css`
		html,
		body {
			touch-action: none;
		}

		html {
			overflow: hidden;
			width: 100%;
			height: 100%;
		}

		body {
			margin: 0;
			padding: 0;
			height: 100%;
			width: 100%;
			background: ${theme.palette.primary};
			color: ${theme.palette.secondary};
			font-size: ${theme.typography.fontSizes.small};
			font-family: ${theme.typography.fontFamily};
			font-weight: ${theme.typography.fontWeight.regular};

			/* prevent overscroll bounce*/
			position: fixed;
			overflow: hidden;
			-webkit-overflow-scrolling: touch;
		}

		#root {
			height: 100%;
			width: 100%;
			position: fixed;
		}

		h1 {
			font-size: ${theme.typography.fontSizes.xxLarge};
			margin: 0;
		}
		h2 {
			font-size: ${theme.typography.fontSizes.xLarge};
			margin: 0;
		}
		h3 {
			font-size: ${theme.typography.fontSizes.large};
			margin: 0;
		}
		h3 {
			font-size: ${theme.typography.fontSizes.medium};
			margin: 0;
		}

		p {
			margin: 0;
		}

		/* width */
		::-webkit-scrollbar {
			width: ${theme.spacing.xs};
		}

		/* Track */
		::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: ${theme.schemes.shade1.passive};
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: ${theme.schemes.shade1.hover};
		}
	`}
`;
