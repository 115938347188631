import { useMemo, useEffect, useRef } from "react";

function useResizeObserver(element: HTMLElement | null, callback: (width: number, entries: ResizeObserverEntry[]) => void) {
	const previousWidth = useRef<number>();

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const width = entries[0].borderBoxSize[0].inlineSize;
				if (width !== previousWidth.current) {
					previousWidth.current = width;
					callback(width, entries);
				}
			}),
		[callback],
	);

	useEffect(() => {
		const currentElRef = element;
		if (currentElRef) {
			resizeObserver.observe(currentElRef);
		}
		return () => {
			if (currentElRef) {
				resizeObserver.unobserve(currentElRef);
			}
		};
	}, [element, resizeObserver]);
}

export default useResizeObserver;
