export default class DomUtil {
	public static getOffsetTop(element: HTMLElement): number {
		const parent = element.parentElement;
		if (parent) {
			return element.offsetTop + DomUtil.getOffsetTop(parent);
		} else {
			return element.offsetTop;
		}
	}
}
