import React, { PropsWithChildren } from "react";
import { VerticalAlignment } from "Style/Alignment";
import styled from "styled-components";

export type VerticalStackProps = PropsWithChildren<{
	className?: string;
	align?: VerticalAlignment;
}>;

export default function VerticalStack(props: VerticalStackProps) {
	const align = props.align ?? VerticalAlignment.Top;

	return (
		<StyledVerticalStack className={`VerticalStack ${props.className || ""}`} $align={align}>
			{props.children}
		</StyledVerticalStack>
	);
}

const StyledVerticalStack = styled.div<{ $align: VerticalAlignment }>`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => (props.$align == VerticalAlignment.Top ? "flex-start" : "flex-end")};
	align-items: center;
`;
