import React, { useState } from "react";
import TraitFilter from "Component/Generator/TraitFilter";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import SelectableList, { SelectableListItem, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Gender, { GenderDisplayName } from "Enums/Gender";
import { Character } from "Models/Character";
import { Personality } from "Models/Personality";
import { Profession } from "Models/Profession";
import styled, { css, useTheme } from "styled-components";
import modalManager from "Utils/ModalManager";
import AgeEditModal from "./AgeEditModal";
import { Textfit } from "react-textfit";
import { Race } from "Models/Race";
import { Culture } from "Models/Culture";

export interface CharacterInfoBlockProps {
	className?: string;
	character: Partial<Character>;
	races: Partial<Race>[];
	cultures: Partial<Culture>[];
	professions: Partial<Profession>[];
	personalities: Partial<Personality>[];
	onInfoBlockChanged: () => void;
}

export default function CharacterInfoBlock(props: CharacterInfoBlockProps) {
	const [selectedItems, setSelectedItems] = useState<SelectableListItem[]>([]);

	const resetSelectedItems = () => {
		setSelectedItems([]);
	};

	const items: SelectableListItem[] = [
		{
			id: "gender",
			isSelectable: true,
			children: <InfoBlock label="Gender" value={props.character.gender ? GenderDisplayName[props.character.gender] : "-"} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-gender",
					type: ModalType.FullScreen,
					children: (
						<TraitFilter
							modalId="character-edit-gender"
							title="Select gender"
							multiSelect={false}
							showSelectAll={false}
							selectedItemIds={[props.character.gender!]}
							onItemsSelected={(items) => {
								if (items.length > 0) {
									props.character.gender = items[0].id as Gender;
									props.onInfoBlockChanged();
								}
								resetSelectedItems();
							}}
							items={
								Object.keys(Gender).map((gender) => {
									return {
										id: gender,
										children: GenderDisplayName[gender as Gender],
									};
								}) ?? []
							}
						/>
					),
				});
			},
		},
		{
			id: "age",
			isSelectable: true,
			children: <InfoBlock label="Age" value={props.character.age} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-age",
					type: ModalType.FullScreen,
					children: (
						<AgeEditModal
							value={props.character.age}
							onChange={(age) => {
								props.character.age = age;
								resetSelectedItems();
								props.onInfoBlockChanged();
							}}
						/>
					),
				});
			},
		},
		{
			id: "race",
			isSelectable: true,
			children: <InfoBlock label="Race" value={props.character.race?.name} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-race",
					type: ModalType.FullScreen,
					children: (
						<TraitFilter
							modalId="character-edit-race"
							title="Select race"
							multiSelect={false}
							showSelectAll={false}
							selectedItemIds={[props.character.raceId ?? props.character.race?.id ?? ""]}
							onItemsSelected={(items) => {
								if (items.length > 0) {
									props.character.raceId = items[0].id;
									props.character.race = props.races.find((p) => p.id === items[0].id);

									// If there's only a single culture for this race, automatically select it
									const raceCultures = props.cultures.filter((c) => c.raceId == props.character.race?.id);

									if (raceCultures.length === 1) {
										props.character.culture = raceCultures[0];
									}
									// Otherwise, we need to unset the culture, since we don't know what the user wants
									else {
										props.character.cultureId = null;
										props.character.culture = undefined;
									}

									props.onInfoBlockChanged();
								}
								resetSelectedItems();
							}}
							items={
								props.races.map((race) => {
									return {
										id: race.id!,
										children: race.name,
									};
								}) ?? []
							}
						/>
					),
				});
			},
		},
		{
			id: "culture",
			isSelectable: true,
			children: <InfoBlock label="Culture" value={props.character.culture?.name} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-culture",
					type: ModalType.FullScreen,
					children: (
						<TraitFilter
							modalId="character-edit-culture"
							title="Select culture"
							multiSelect={false}
							showSelectAll={false}
							selectedItemIds={[props.character.cultureId ?? props.character.culture?.id ?? ""]}
							onItemsSelected={(items) => {
								if (items.length > 0) {
									props.character.cultureId = items[0].id;
									props.character.culture = props.cultures.find((p) => p.id === items[0].id);
									props.onInfoBlockChanged();
								}
								resetSelectedItems();
							}}
							items={
								props.cultures
									.filter((culture) => {
										const raceId = props.character.raceId ?? props.character.race?.id;
										if (raceId) {
											return culture.raceId === raceId;
										} else {
											return true;
										}
									})
									.map((culture) => {
										return {
											id: culture.id!,
											children: culture.name,
										};
									}) ?? []
							}
						/>
					),
				});
			},
		},
		{
			id: "personality",
			isSelectable: true,
			children: <InfoBlock label="Personality" value={props.character.personality?.name} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-personality",
					type: ModalType.FullScreen,
					children: (
						<TraitFilter
							modalId="character-edit-personality"
							title="Select personality"
							multiSelect={false}
							showSelectAll={false}
							selectedItemIds={[props.character.personalityId ?? props.character.personality?.id ?? ""]}
							onItemsSelected={(items) => {
								if (items.length > 0) {
									props.character.personalityId = items[0].id;
									props.character.personality = props.personalities.find((p) => p.id === items[0].id);
									props.onInfoBlockChanged();
								}
								resetSelectedItems();
							}}
							items={
								props.personalities.map((personality) => {
									return {
										id: personality.id!,
										children: personality.name,
									};
								}) ?? []
							}
						/>
					),
				});
			},
		},
		{
			id: "profession",
			isSelectable: true,
			children: <InfoBlock label="Profession" value={props.character.profession?.name} />,
			onClick: () => {
				modalManager.show({
					id: "character-edit-profession",
					type: ModalType.FullScreen,
					children: (
						<TraitFilter
							modalId="character-edit-profession"
							title="Select profession"
							multiSelect={false}
							showSelectAll={false}
							selectedItemIds={[props.character.professionId ?? props.character.profession?.id ?? ""]}
							onItemsSelected={(items) => {
								if (items.length > 0) {
									props.character.professionId = items[0].id;
									props.character.profession = props.professions.find((p) => p.id === items[0].id);
									props.onInfoBlockChanged?.();
								}
								resetSelectedItems();
							}}
							items={
								props.professions.map((profession) => {
									return {
										id: profession.id!,
										children: profession.name,
									};
								}) ?? []
							}
						/>
					),
				});
			},
		},
	];
	return (
		<StyledCharacterInfoBlock
			className={`CharacterInfoBlock ${props.className || ""}`}
			type={SelectableListType.MultiColumns}
			items={items}
			selectedItems={selectedItems}
			onChange={setSelectedItems}
		></StyledCharacterInfoBlock>
	);
}

interface InfoBlockProps {
	label: string;
	value?: string | number;
}

function InfoBlock(props: InfoBlockProps) {
	const theme = useTheme();

	const minSize = parseInt(theme.typography.fontSizes.xxxSmall);
	const maxLabelSize = parseInt(theme.typography.fontSizes.medium);
	const maxValueSize = parseInt(theme.typography.fontSizes.xLarge);

	return (
		<>
			<Label>
				<Textfit mode="single" min={minSize} max={maxLabelSize}>
					{props.label}
				</Textfit>
			</Label>
			<Value>
				<Textfit mode="single" min={minSize} max={maxValueSize}>
					{props.value || "-"}
				</Textfit>
			</Value>
		</>
	);
}

const StyledCharacterInfoBlock = styled(SelectableList)`
	margin-bottom: ${(props) => props.theme.spacing.md};
`;

const Label = styled.p`
	${({ theme }) => css`
		color: ${theme.palette.shade1};
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.medium};
	`}
`;
const Value = styled.p`
	${({ theme }) => css`
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.xLarge};
	`}
`;
