import { action, makeAutoObservable } from "mobx";

export default class TabMenuStore {
	private _isVisible = true;

	public constructor() {
		makeAutoObservable(this, {
			showMenu: action,
			hideMenu: action,
		});
	}

	get isVisible() {
		return this._isVisible;
	}

	set isVisible(isVisible: boolean) {
		this._isVisible = isVisible;
	}

	public showMenu() {
		this._isVisible = true;
	}

	public hideMenu() {
		this._isVisible = false;
	}
}
