import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { IconName } from "Component/Global/Content/Icon";
import { useNavigate } from "react-router-dom";
import StackedButtonGroup from "Component/Global/Content/StackedButtonGroup";
import Button from "Component/Global/Input/Button";
import EditableText from "Component/Global/Input/EditableText";
import { User } from "Models/User";
import UserService from "Services/UserService";
import { useDi } from "Utils/DependencyInjection";
import UserStore from "Stores/UserStore";
import { observer } from "mobx-react-lite";
import { PageRoutes } from "Utils/Navigation";
import modalManager from "Utils/ModalManager";
import ModelUtils from "../../Utils/ModelUtils";

export interface SettingsPageProps {
	className?: string;
}

export default observer(SettingsPage);
function SettingsPage(props: SettingsPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();

	const userStore = useDi(UserStore);
	const userService = useDi(UserService);

	const [isLoading, setIsLoading] = useState(userStore.isLoading);
	const [user, setUser] = useState<User | undefined>(userStore.currentUser);

	const handleBackClicked = () => {
		setTimeout(() => navigate(-1), 0);
	};

	const handleLogoutClicked = () => {
		setTimeout(() => navigate(PageRoutes.Logout), 0);
	};

	const handleFeedbackClicked = () => {
		window.open("https://forms.gle/15h7Etz9Tb4TbaXE7", "_blank");
	};

	const handleSaveFields = async () => {
		if (!user) {
			return;
		}

		try {
			setIsLoading(true);
			const fieldsToUpdate: (keyof User)[] = ["displayName", "email"];
			const updatedUser = await userService.saveUser(ModelUtils.pick(user, ["id", "uid", ...fieldsToUpdate]), fieldsToUpdate);

			if (userStore.currentUser && updatedUser) {
				userStore.currentUser = {
					...userStore.currentUser,
					...updatedUser,
				};
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (!userStore.currentUser?.id) {
			return;
		}
		try {
			setIsLoading(true);

			userService.fetchUser(userStore.currentUser?.id).then(setUser);
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	}, [userService, userStore.currentUser?.id]);

	return (
		<StyledSettingsPage
			className={`SettingsPage ${props.className || ""}`}
			titleBar={{
				title: "Settings",
				leftIcon: IconName.ArrowLeft,
				onLeftIconClicked: handleBackClicked,
			}}
		>
			<StyledStackedButtonGroup
				buttons={
					<Button isResponsive onClick={handleLogoutClicked}>
						Logout
					</Button>
				}
				contentPadding={theme.spacing.md}
			>
				<EditableText
					disabled={isLoading}
					title="My Profile"
					onSave={handleSaveFields}
					textItems={[
						{
							label: "Display Name",
							placeholder: "Tap edit to edit your display name",
							multiline: false,
							value: user?.displayName,
							onChange: (value) => (user ? (user.displayName = value) : null),
						},
						{
							label: "Email",
							placeholder: "Tap edit to edit your email address",
							multiline: false,
							value: user?.email,
							onChange: (value) => (user ? (user.email = value) : null),
						},
					]}
				/>
				<Feedback>
					<h3>
						Found a bug or have some feedback?
						<br />
						We&apos;d love to hear from you!
					</h3>
					<Button scheme={theme.schemes.accent2} onClick={handleFeedbackClicked}>
						Leave us feedback
					</Button>
				</Feedback>
			</StyledStackedButtonGroup>
		</StyledSettingsPage>
	);
}

const Feedback = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
	gap: ${(props) => props.theme.spacing.sm};
	margin: ${(props) => props.theme.spacing.sm} 0;
`;

const StyledStackedButtonGroup = styled(StackedButtonGroup)`
	> div {
		display: flex;
	}
`;

const StyledSettingsPage = styled(Page)``;
