import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import useDebouncedValue from "Hooks/UseDebouncedValue";
import styled, { css } from "styled-components";
import TextInput from "../Input/TextInput";
import SelectableList, { SelectableListItem, SelectableListProps } from "./SelectableList/SelectableList";
import { DefaultTransition } from "Style/Animation";

export interface SearchProps {
	className?: string;
	performSearch: (search: string) => Promise<SelectableListItem[]>;
	selectableListOptions: Omit<SelectableListProps, "items">;
}

export default function Search(props: SearchProps) {
	const [query, setQuery] = useState("");
	const [items, setItems] = useState<SelectableListItem[]>([]);

	const [inputRef, setInputRef] = useState<HTMLElement | SVGElement | null>(null);

	const performSearch = props.performSearch;

	const executeSearchAndSetItems = useCallback(
		(text: string) => {
			performSearch(text).then(setItems);
		},
		[performSearch],
	);

	const [debouncedQuery, cancelDebounce] = useDebouncedValue(query);

	useEffect(() => {
		if (debouncedQuery) {
			executeSearchAndSetItems(debouncedQuery);
		} else {
			setItems([]);
		}
	}, [debouncedQuery, executeSearchAndSetItems]);

	const handleEnterPressed = () => {
		cancelDebounce();
		if (query) {
			executeSearchAndSetItems(query);
		} else {
			setItems([]);
		}
	};

	const handleSearchChanged = (text: string) => {
		if (text == "") {
			cancelDebounce();
			setItems([]);
		}

		setQuery(text);
	};

	useLayoutEffect(() => {
		if (inputRef) {
			inputRef.querySelector<HTMLInputElement>("input")?.focus();
		}
	}, [inputRef]);

	return (
		<StyledSearch className={`Search ${props.className || ""}`} $justifyTop={items.length > 0}>
			<SearchInput ref={setInputRef} placeholder="Type anything" value={query} onChange={handleSearchChanged} onEnterPressed={handleEnterPressed} layoutId="searchInput" transition={DefaultTransition} />
			<StyledSelectableList {...props.selectableListOptions} items={items} layoutId="searchResults" transition={DefaultTransition} />
		</StyledSearch>
	);
}

const SearchInput = styled(TextInput)`
	${({ theme }) => css`
		flex-grow: 0;
		flex-shrink: 0;
		> input {
			background: none;
			border-radius: unset;
			margin: ${theme.spacing.lg} 0;
			padding: 0;
			text-align: center;
			color: ${theme.palette.secondary};
			font-size: ${theme.typography.fontSizes.xxLarge};
			&::placeholder {
				font-style: italic;
			}
		}
	`}
`;

const StyledSelectableList = styled(SelectableList)`
	min-height: unset;
`;

const StyledSearch = styled.div<{ $justifyTop: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: ${(props) => (props.$justifyTop ? "flex-start" : "center")};
`;
