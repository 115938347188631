import Schema from "validate";

export default interface LoginRequest {
	email: string;
	password: string;
}

export const LoginRequestValidation = new Schema({
	email: {
		required: true,
		match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
		message: {
			required: "Email is required",
			match: "Email is not valid",
		},
	},
	password: {
		required: true,
		length: { min: 8 },
		message: {
			required: "Password is required",
			length: "Password must be at least 8 characters",
		},
	},
});
