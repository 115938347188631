import React, { useState, useEffect, CSSProperties, forwardRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IScheme } from "Style/Color";
import styled, { css, useTheme } from "styled-components";
import { ITheme } from "Style/Theme";
import { DefaultTransition } from "Style/Animation";
import Selectable, { SelectableProps } from "./Selectable";

export enum SelectableListType {
	SingleColumn = "SingleColumn",
	MultiColumns = "MultiColumns",
	Display = "Display",
}

export type SelectableListItem = SelectableProps & {
	id: string;
};

export type SelectableListVerticalAlign = "top" | "bottom";

export type SelectableListOnChangeHandler = (selectedItems: SelectableListItem[]) => void;

export interface SelectableListProps {
	className?: string;
	type: SelectableListType;
	items: SelectableListItem[];
	onChange: SelectableListOnChangeHandler;
	verticalAlign?: SelectableListVerticalAlign;
	scheme?: IScheme;
	selectedScheme?: IScheme;
	selectedItems?: SelectableListItem[];
	gap?: string;
	multiSelect?: boolean;
}

const SelectableList = forwardRef<HTMLDivElement, SelectableListProps>((props, ref) => {
	const theme = useTheme();

	const type = props.type == SelectableListType.Display && props.items.length < 2 ? SelectableListType.SingleColumn : props.type;
	const gap = props.gap ?? theme.spacing.xxs;
	const verticalAlign = props.verticalAlign ?? "bottom";

	const [selectedItems, setSelectedItems] = useState<SelectableListItem[]>(props.selectedItems ?? []);

	useEffect(() => {
		setSelectedItems(props.selectedItems ?? []);
	}, [props.selectedItems]);

	const handleSelectionChange = (item: SelectableListItem, isSelected: boolean) => {
		if (isSelected) {
			if (props.multiSelect) {
				setSelectedItems((selectedItems) => {
					const newSelectedItems = [...selectedItems, item];
					setTimeout(() => props.onChange(newSelectedItems), 0);
					return newSelectedItems;
				});
			} else {
				const newSelectedItems = [item];
				setTimeout(() => props.onChange(newSelectedItems), 0);
				setSelectedItems(newSelectedItems);
			}
		} else {
			setSelectedItems((selectedItems) => {
				const index = selectedItems.findIndex((x) => x.id == item.id);
				if (index >= 0) {
					const newSelectedItems = [...selectedItems];
					newSelectedItems.splice(index, 1);
					setTimeout(() => props.onChange(newSelectedItems), 0);
					return newSelectedItems;
				} else {
					return selectedItems;
				}
			});
		}
	};

	return (
		<StyledSelectableList className={`SelectableList ${props.className || ""}`} $verticalAlign={verticalAlign} $type={type} $gap={gap}>
			<AnimatePresence>
				{props.items?.map((item) => {
					const isSelected = selectedItems.find((x) => x.id === item.id) ? true : false;
					return (
						<Selectable
							{...item}
							ref={ref}
							key={item.id}
							isSelected={isSelected}
							scheme={props.scheme}
							selectedScheme={props.selectedScheme}
							onChange={handleSelectionChange.bind(null, item)}
							animate={{
								opacity: selectedItems.length > 0 && !isSelected ? 0.5 : 1,
							}}
							transition={DefaultTransition}
							initial={false}
						/>
					);
				})}
			</AnimatePresence>
		</StyledSelectableList>
	);
});
export default motion(SelectableList);

const getStyles = (theme: ITheme, verticalAlign: SelectableListVerticalAlign, gap: string): Record<SelectableListType, CSSProperties> => {
	return {
		SingleColumn: {
			flexDirection: "column",
			flexGrow: 0,
			width: "100%",
			flexBasis: "auto",
			flexWrap: "nowrap",
			justifyContent: verticalAlign == "top" ? "flex-start" : "flex-end",
			alignContent: "normal",
		},
		MultiColumns: {
			flexDirection: "row",
			flexGrow: 1,
			flexBasis: `calc(50% - calc(${gap} / 2))`,
			flexWrap: "wrap",
			justifyContent: "normal",
			alignContent: verticalAlign == "top" ? "flex-start" : "flex-end",
		},
		Display: {
			flexDirection: "row",
			flexGrow: 1,
			flexBasis: `calc(50% - calc(${gap} / 2))`,
			flexWrap: "wrap",
			justifyContent: "normal",
			alignContent: verticalAlign == "top" ? "flex-start" : "flex-end",
		},
	};
};

const StyledSelectableList = styled(motion.div)<{
	$verticalAlign: SelectableListVerticalAlign;
	$type: SelectableListType;
	$gap: string;
}>`
	${({ theme, $verticalAlign, $type, $gap }) => {
		const typeStyle = getStyles(theme, $verticalAlign, $gap)[$type];

		return css`
			width: 100%;
			min-height: 100%;
			display: flex;
			flex-wrap: ${typeStyle.flexWrap};
			gap: ${$gap};
			justify-content: ${typeStyle.justifyContent};
			flex-direction: ${typeStyle.flexDirection};
			align-content: ${typeStyle.alignContent};

			> * {
				flex-grow: ${typeStyle.flexGrow};
				width: ${typeStyle.width};
				flex-basis: ${typeStyle.flexBasis};
				max-width: ${typeStyle.flexBasis};

				${$type == SelectableListType.Display
					? css`
							&:first-child {
								max-width: 100%;
								width: 100%;
								flex-basis: 100%;
							}
					  `
					: ""}
			}
		`;
	}}
`;
