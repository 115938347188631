import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { useDi } from "Utils/DependencyInjection";
import { IconName } from "Component/Global/Content/Icon";
import Background from "Static/Image/Generator/empty.jpg";
import { observer } from "mobx-react-lite";
import GeneratorStore from "Stores/GeneratorStore";
import Generator from "./Generator";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "Utils/Navigation";
import modalManager from "Utils/ModalManager";
import { ConfirmModalProps } from "Component/Global/Interactive/Modal/ConfirmModal";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import useLoader from "Hooks/UseLoader";
import CharacterService from "Services/CharacterService";

export interface GeneratorPageProps {
	className?: string;
}

function GeneratorPage(props: GeneratorPageProps) {
	const theme = useTheme();
	const generatorStore = useDi(GeneratorStore);
	const characterService = useDi(CharacterService);
	const navigate = useNavigate();

	const { addLoader, removeLoader, isLoading } = useLoader();

	const [isSaveMode, setIsSaveMode] = useState<boolean>(false);
	const [selectedCharacterIds, setSelectedCharacterIds] = useState<string[]>([]);

	const handleCharactersSelected = (characterIds: string[]) => {
		if (characterIds.length > 0) {
			setIsSaveMode(true);
		}
		setSelectedCharacterIds(characterIds);
	};

	const clearGeneratedCharacters = async () => {
		const loaderId = addLoader();
		try {
			await characterService.deleteCharacters(generatorStore.generatedCharacters ?? []);
			generatorStore.generatedCharacters = [];
			setSelectedCharacterIds([]);
			setIsSaveMode(false);
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			removeLoader(loaderId);
		}
	};

	const handleLeftIconClicked = () => {
		if (isSaveMode) {
			const modalProps: ConfirmModalProps = {
				id: "confirm-reset-characters",
				type: ModalType.Confirm,
				scheme: { ...theme.schemes.error, text: "white" },
				children: <p>Clear all characters from list</p>,
				confirmText: "Clear",
				onConfirm: clearGeneratedCharacters,
			};
			modalManager.show(modalProps);
		} else {
			setTimeout(() => navigate(PageRoutes.Settings), 0);
		}
	};

	const handleRightIconClicked = () => {
		if (generatorStore.generatedCharacters.length === 0) {
			return;
		}
		if (isSaveMode) {
			setIsSaveMode(false);
			setSelectedCharacterIds([]);
		} else {
			setIsSaveMode(true);
		}
	};

	const handleCharactersDeleted = () => {
		setIsSaveMode(false);
	};

	const getLeftIcon = () => {
		if (generatorStore.generatedCharacters.length === 0) {
			return IconName.Settings;
		} else {
			return isSaveMode ? IconName.Rotate : IconName.Settings;
		}
	};

	const getRightIcon = () => {
		if (generatorStore.generatedCharacters.length === 0) {
			return undefined;
		} else {
			return isSaveMode ? IconName.X : IconName.Save;
		}
	};

	return (
		<StyledGeneratorPage
			className={`GeneratorPage ${props.className || ""}`}
			background={generatorStore.generatedCharacters.length || isLoading ? undefined : Background}
			titleBar={{
				title: generatorStore.generatedCharacters.length ? "How about" : "Generator",
				leftIcon: getLeftIcon(),
				onLeftIconClicked: handleLeftIconClicked,
				rightIcon: getRightIcon(),
				onRightIconClicked: handleRightIconClicked,
				transparentIcons: true,
			}}
		>
			<Generator isLoading={isLoading} isSaveMode={isSaveMode} selectedCharacterIds={selectedCharacterIds} setSelectedCharacterIds={handleCharactersSelected} onCharactersDeleted={handleCharactersDeleted} />
		</StyledGeneratorPage>
	);
}
export default observer(GeneratorPage);

const StyledGeneratorPage = styled(Page)``;
