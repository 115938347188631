import React, { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import TitleBar from "Component/Global/Content/TitleBar";
import Button from "Component/Global/Input/Button";
import modalManager from "Utils/ModalManager";
import SelectableList, { SelectableListItem, SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import { IconName } from "Component/Global/Content/Icon";

export type CultureSelectableListItem = SelectableListItem & {
	raceName: string;
};

export interface CultureFilterProps {
	className?: string;
	items: CultureSelectableListItem[];
	onItemsSelected: SelectableListOnChangeHandler;
	selectedItemIds?: string[];
}

export default function CultureFilter(props: CultureFilterProps) {
	const theme = useTheme();
	const [selectedItems, setSelectedItems] = useState<CultureSelectableListItem[]>([]);

	const updateSelectedItems = (raceName: string, items: CultureSelectableListItem[]) => {
		setSelectedItems((existingItems) => {
			const updatedItems = [...existingItems.filter((x) => x.raceName !== raceName), ...items];
			return updatedItems;
		});
	};

	useEffect(() => {
		if (props.selectedItemIds) {
			setSelectedItems(props.items.filter((x) => props.selectedItemIds!.indexOf(x.id) >= 0));
		}
	}, [props.items, props.selectedItemIds]);

	const handleCloseClicked = () => {
		props.onItemsSelected(selectedItems);
		modalManager.hide("filters-culture");
	};

	const handleSelectAllClicked = () => {
		let updatedItems: CultureSelectableListItem[];

		if (props.items.length === selectedItems.length) {
			updatedItems = [];
		} else {
			updatedItems = [...props.items];
		}

		setSelectedItems(updatedItems);
	};

	const groupedItems = useMemo(() => {
		const itemMap: Record<string, CultureSelectableListItem[]> = {};

		props.items.forEach((item) => {
			if (!itemMap[item.raceName]) {
				itemMap[item.raceName] = [];
			}
			itemMap[item.raceName].push(item);
		});

		return itemMap;
	}, [props.items]);

	return (
		<StyledCultureFilter className={`CultureFilter ${props.className || ""}`}>
			<StyledTitleBar title="Select culture" leftIcon={IconName.CheckSquare} onLeftIconClicked={handleSelectAllClicked} />
			<Content>
				{Object.keys(groupedItems).map((raceName) => {
					return (
						<GroupedList key={raceName}>
							<p>{raceName}</p>
							<StyledSelectableList
								items={groupedItems[raceName]}
								type={groupedItems[raceName].length < 2 ? SelectableListType.SingleColumn : SelectableListType.MultiColumns}
								selectedItems={selectedItems.filter((x) => groupedItems[raceName].find((y) => y.id === x.id))}
								onChange={(items) =>
									updateSelectedItems(
										raceName,
										items.map((item) => ({ ...item, raceName })),
									)
								}
								gap={theme.spacing.xxs}
								multiSelect
							/>
						</GroupedList>
					);
				})}
			</Content>
			<CloseButton onClick={handleCloseClicked} isResponsive scheme={false ? theme.schemes.accent1 : undefined}>
				{false ? "Use custom name" : "Close"}
			</CloseButton>
		</StyledCultureFilter>
	);
}

const GroupedList = styled.div`
	p {
		margin: ${(props) => props.theme.spacing.md};
	}
`;

const StyledSelectableList = styled(SelectableList)`
	height: auto;
	min-height: unset;
`;

const StyledTitleBar = styled(TitleBar)`
	flex-grow: 0;
	flex-shrink: 0;
`;

const Content = styled.div`
	flex-grow: 1;
	width: 100%;
	height: 100%;
	min-height: min-content;
	overflow-y: auto;
	/* display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};
	justify-content: flex-end; */
`;

const CloseButton = styled(Button)`
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;

const StyledCultureFilter = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};
`;
