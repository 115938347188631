import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { User } from "Models/User";
import DateUtil from "Utils/DateUtil";

export default class UserStore {
	private _completedOnboarding = false;
	private _isLoading = true; // Assume we're loading when the class is initialised
	private _currentUser?: User;

	constructor() {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._isLoading;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoggedIn() {
		return !this._isLoading && this._currentUser !== undefined;
	}

	get currentUser() {
		return this._currentUser;
	}

	set currentUser(user: User | undefined) {
		this._currentUser = user;
		this._isLoading = false;
	}

	get hasCompletedOnboarding() {
		if (this._completedOnboarding) {
			return true;
		}

		if (this._currentUser?.created) {
			const created = DateUtil.parse(this._currentUser?.created);
			const recordAge = dayjs().diff(created, "seconds");
			const isNewUser = recordAge < 10;
			return !isNewUser;
		}

		return false;
	}

	set hasCompletedOnboarding(completedOnboarding: boolean) {
		this._completedOnboarding = completedOnboarding;
	}
}
