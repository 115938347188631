import Button from "Component/Global/Input/Button";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import Loader, { LoaderLayout } from "../Content/Loader";
import TextInput, { TextInputProps } from "./TextInput";

export interface EditableTextProps {
	className?: string;
	title?: string;
	textItems?: TextInputProps[];
	onSave?: () => Promise<unknown>;
	disabled?: boolean;
	editOnClick?: boolean;
}

export default function EditableText(props: EditableTextProps) {
	const theme = useTheme();
	const [isEditing, setIsEditing] = useState(props.disabled ?? false);
	const [isSaving, setIsSaving] = useState(false);

	const editOnClick = props.editOnClick ?? false;

	useEffect(() => {
		if (props.disabled) {
			setIsEditing(false);
		}
	}, [props.disabled]);

	const toggleEditing = async () => {
		if (isEditing) {
			if (props.onSave !== undefined) {
				try {
					setIsSaving(true);
					await props.onSave();
				} finally {
					setIsSaving(false);
				}
			}
		}
		setIsEditing(!isEditing);
	};

	const [firstInputRef, setFirstInputRef] = useState<HTMLElement | SVGElement | null>(null);

	useLayoutEffect(() => {
		if (isEditing && firstInputRef) {
			firstInputRef.querySelector<HTMLInputElement | HTMLTextAreaElement>("input,textarea")?.focus();
		}
	}, [firstInputRef, isEditing]);

	return (
		<StyledEditableText className={`EditableText ${props.className || ""}`}>
			{props.title && <Title>{props.title}</Title>}
			{!editOnClick && (
				<Button size={Size.Small} onClick={toggleEditing} scheme={isEditing ? theme.schemes.accent1 : undefined} disabled={props.disabled || isSaving}>
					{isEditing ? "Save" : "Edit"}
					{isSaving && <Loader size={Size.Small} layoutType={LoaderLayout.InlineButton} />}
				</Button>
			)}
			<TextItems>
				{props.textItems?.map((t, i) => (
					<StyledTextInput
						ref={(ref) => i === 0 && setFirstInputRef(ref)}
						key={i}
						{...t}
						multiline={t.multiline ?? true}
						readonly={!isEditing}
						disabled={props.disabled || isSaving}
						onEnterPressed={toggleEditing}
						onClick={() => {
							if (editOnClick) {
								setIsEditing(true);
							}
						}}
					/>
				))}
			</TextItems>
		</StyledEditableText>
	);
}

const StyledEditableText = styled.div`
	${({ theme }) => css`
		width: 100%;
		display: grid;
		grid-template-areas:
			"title button"
			"items items";
		grid-template-columns: 1fr auto;
		grid-template-rows: auto auto;
		row-gap: ${theme.spacing.sm};

		& > .Button {
			grid-area: button;
		}
	`}
`;
const TextItems = styled.div`
	grid-area: items;
`;
const Title = styled.h3`
	${({ theme }) => css`
		grid-area: title;
		align-self: center;
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;
const StyledTextInput = styled(TextInput)`
	margin-bottom: ${(props) => props.theme.spacing.sm};
`;
