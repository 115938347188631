/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { CharacterDetail } from "Models/CharacterDetail";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddCharacterDetailInput = {
	characterDetail: Partial<CharacterDetail>;
};
export type AddCharacterDetailOutput = GraphQLMutationResponse<CharacterDetail, "addCharacterDetail", "characterDetail">;
export type AddCharacterDetailsInput = {
	characterDetails: Partial<CharacterDetail>[];
};
export type AddCharacterDetailsOutput = GraphQLMutationResponse<CharacterDetail[], "addCharacterDetails", "characterDetails">;

// Update Types
export type UpdateCharacterDetailInput = {
	characterDetail: Partial<CharacterDetail>;
	fieldsToUpdate?: Array<keyof CharacterDetail>;
	replaceReferences?: boolean;
};
export type UpdateCharacterDetailOutput = GraphQLMutationResponse<CharacterDetail, "updateCharacterDetail", "characterDetail">;
export type UpdateCharacterDetailsInput = {
	characterDetails: Partial<CharacterDetail>[];
	fieldsToUpdate?: Array<keyof CharacterDetail>;
	replaceReferences?: boolean;
};
export type UpdateCharacterDetailsOutput = GraphQLMutationResponse<CharacterDetail[], "updateCharacterDetails", "characterDetails">;

// Delete Types
export type DeleteCharacterDetailInput = {
	id: string;
};
export type DeleteCharacterDetailOutput = GraphQLDeleteResponse<"deleteCharacterDetail">;
export type DeleteCharacterDetailsInput = {
	ids: string[];
};
export type DeleteCharacterDetailsOutput = GraphQLBulkDeleteResponse<"deleteCharacterDetails">;

// Add Mutations
export const addCharacterDetail = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterDetail: Partial<CharacterDetail>) => {
	const response = await (
		await apollo
	).mutate<AddCharacterDetailOutput, AddCharacterDetailInput>({
		variables: {
			characterDetail,
		},
		mutation: gql`
			fragment CharacterDetailOutput on CharacterDetail {
				${returnFields}
			}
			mutation addCharacterDetail($characterDetail: CharacterDetailInput!) {
				addCharacterDetail(input: { characterDetail: $characterDetail }) {
					characterDetail {
						...CharacterDetailOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addCharacterDetails = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterDetails: Partial<CharacterDetail>[]) => {
	const response = await (
		await apollo
	).mutate<AddCharacterDetailsOutput, AddCharacterDetailsInput>({
		variables: {
			characterDetails,
		},
		mutation: gql`
			fragment CharacterDetailsOutput on CharacterDetail {
				${returnFields}
			}
			mutation addCharacterDetails($characterDetails: [CharacterDetailInput!]!) {
				addCharacterDetails(input: { characterDetails: $characterDetails }) {
					characterDetail {
						...CharacterDetailsOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateCharacterDetail = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterDetail: Partial<CharacterDetail>, fieldsToUpdate: Array<keyof CharacterDetail>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterDetailOutput, UpdateCharacterDetailInput>({
		variables: {
			characterDetail,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterDetailOutput on CharacterDetail {
				${returnFields}
			}
			mutation updateCharacterDetail($characterDetail: CharacterDetailInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateCharacterDetail(input: { characterDetail: $characterDetail, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterDetail {
						...CharacterDetailOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateCharacterDetails = async (
	apollo: Promise<Apollo<unknown>>,
	returnFields: string,
	characterDetails: Partial<CharacterDetail>[],
	fieldsToUpdate: Array<keyof CharacterDetail>,
	replaceReferences = false,
) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterDetailsOutput, UpdateCharacterDetailsInput>({
		variables: {
			characterDetails,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterDetailsOutput on CharacterDetail {
				${returnFields}
			}
			mutation updateCharacterDetails($characterDetails: [CharacterDetailInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateCharacterDetails(input: { characterDetails: $characterDetails, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterDetail {
						...CharacterDetailsOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteCharacterDetail = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterDetailOutput, DeleteCharacterDetailInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteCharacterDetail($id: UUID!) {
				deleteCharacterDetail(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteCharacterDetails = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterDetailsOutput, DeleteCharacterDetailsInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteCharacterDetails($ids: [UUID!]) {
				deleteCharacterDetails(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
