import React, { useCallback, useEffect, useRef } from "react";
import { elementScroll, useVirtualizer, VirtualItem, Virtualizer, VirtualizerOptions } from "@tanstack/react-virtual";
import styled from "styled-components";

export type VirtualScrollOnScrollHandler = (index: number, instance: Virtualizer<HTMLDivElement, HTMLDivElement>) => void;
export type VirtualScrollOnItemScrollHandler = (index: number, instance: Virtualizer<HTMLDivElement, HTMLDivElement>) => void;
export type VirtualScrollItem = (item: VirtualItem) => React.ReactNode;
export interface VirtualScrollProps {
	className?: string;
	items: VirtualScrollItem[];
	overscan?: number;
	itemHeight?: number;
	scrollDurationMs?: number;
	scrollToIndex?: number;
	onScroll?: VirtualScrollOnScrollHandler;
	onItemScroll?: VirtualScrollOnScrollHandler;
}

function easeInOutQuint(t: number) {
	return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

export default function VirtualScroll(props: VirtualScrollProps) {
	const scrollDurationMs = props.scrollDurationMs ?? 100;
	const overscan = props.overscan ?? 5;
	const itemHeight = props.itemHeight ?? 35;

	const parentRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const scrollingRef = useRef<number>();
	const currentIndexRef = useRef<number>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const scrollToFn: VirtualizerOptions<any, any>["scrollToFn"] = useCallback(
		(offset, canSmooth, instance) => {
			const start = parentRef.current?.scrollTop ?? 0;
			const startTime = (scrollingRef.current = Date.now());

			const run = () => {
				if (scrollingRef.current !== startTime) return;
				const now = Date.now();
				const elapsed = now - startTime;
				const progress = easeInOutQuint(Math.min(elapsed / scrollDurationMs, 1));
				const interpolated = start + (offset - start) * progress;

				if (elapsed < scrollDurationMs) {
					elementScroll(interpolated, canSmooth, instance);
					requestAnimationFrame(run);
				} else {
					elementScroll(interpolated, canSmooth, instance);
				}
			};

			requestAnimationFrame(run);
		},
		[scrollDurationMs],
	);

	const handleOnChange: VirtualizerOptions<HTMLDivElement, HTMLDivElement>["onChange"] = (instance) => {
		const scrollOffset = instance["scrollOffset"] as number;

		const currentMeasurement = instance.measurementsCache.find((m) => {
			const withinBounds = scrollOffset >= m.start && scrollOffset < m.end;

			if (withinBounds) {
				if (currentIndexRef.current !== m.index) {
					props.onItemScroll?.(m.index, instance);
				}
				currentIndexRef.current = m.index;
			}
			return withinBounds;
		}) ?? {
			index: 0,
		};

		props.onScroll?.(currentMeasurement.index, instance);
	};

	const rowVirtualizer = useVirtualizer({
		count: props.items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => itemHeight,
		overscan,
		scrollToFn,
		onChange: handleOnChange,
	});

	useEffect(() => {
		if (props.scrollToIndex !== undefined) {
			rowVirtualizer.scrollToIndex(props.scrollToIndex, {
				align: "start",
			});
		}
	}, [props.scrollToIndex, rowVirtualizer]);

	return (
		<StyledVirtualScroll className={`VirtualScroll ${props.className || ""}`} ref={parentRef}>
			<Wrapper
				style={{
					height: `${rowVirtualizer.getTotalSize()}px`,
				}}
				ref={wrapperRef}
			>
				{rowVirtualizer.getVirtualItems().map((virtualRow) => (
					<Item
						key={virtualRow.key}
						data-index={virtualRow.index}
						ref={rowVirtualizer.measureElement}
						style={{
							transform: `translateY(${virtualRow.start}px)`,
						}}
					>
						{props.items[virtualRow.index](virtualRow)}
					</Item>
				))}
			</Wrapper>
		</StyledVirtualScroll>
	);
}

const StyledVirtualScroll = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;
`;

const Wrapper = styled.div`
	width: 100%;
	position: relative;
`;

const Item = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;
