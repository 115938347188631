/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { CharacterNote } from "Models/CharacterNote";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddCharacterNoteInput = {
	characterNote: Partial<CharacterNote>;
};
export type AddCharacterNoteOutput = GraphQLMutationResponse<CharacterNote, "addCharacterNote", "characterNote">;
export type AddCharacterNotesInput = {
	characterNotes: Partial<CharacterNote>[];
};
export type AddCharacterNotesOutput = GraphQLMutationResponse<CharacterNote[], "addCharacterNotes", "characterNotes">;

// Update Types
export type UpdateCharacterNoteInput = {
	characterNote: Partial<CharacterNote>;
	fieldsToUpdate?: Array<keyof CharacterNote>;
	replaceReferences?: boolean;
};
export type UpdateCharacterNoteOutput = GraphQLMutationResponse<CharacterNote, "updateCharacterNote", "characterNote">;
export type UpdateCharacterNotesInput = {
	characterNotes: Partial<CharacterNote>[];
	fieldsToUpdate?: Array<keyof CharacterNote>;
	replaceReferences?: boolean;
};
export type UpdateCharacterNotesOutput = GraphQLMutationResponse<CharacterNote[], "updateCharacterNotes", "characterNotes">;

// Delete Types
export type DeleteCharacterNoteInput = {
	id: string;
};
export type DeleteCharacterNoteOutput = GraphQLDeleteResponse<"deleteCharacterNote">;
export type DeleteCharacterNotesInput = {
	ids: string[];
};
export type DeleteCharacterNotesOutput = GraphQLBulkDeleteResponse<"deleteCharacterNotes">;

// Add Mutations
export const addCharacterNote = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterNote: Partial<CharacterNote>) => {
	const response = await (
		await apollo
	).mutate<AddCharacterNoteOutput, AddCharacterNoteInput>({
		variables: {
			characterNote,
		},
		mutation: gql`
			fragment CharacterNoteOutput on CharacterNote {
				${returnFields}
			}
			mutation addCharacterNote($characterNote: CharacterNoteInput!) {
				addCharacterNote(input: { characterNote: $characterNote }) {
					characterNote {
						...CharacterNoteOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addCharacterNotes = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterNotes: Partial<CharacterNote>[]) => {
	const response = await (
		await apollo
	).mutate<AddCharacterNotesOutput, AddCharacterNotesInput>({
		variables: {
			characterNotes,
		},
		mutation: gql`
			fragment CharacterNotesOutput on CharacterNote {
				${returnFields}
			}
			mutation addCharacterNotes($characterNotes: [CharacterNoteInput!]!) {
				addCharacterNotes(input: { characterNotes: $characterNotes }) {
					characterNote {
						...CharacterNotesOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateCharacterNote = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterNote: Partial<CharacterNote>, fieldsToUpdate: Array<keyof CharacterNote>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterNoteOutput, UpdateCharacterNoteInput>({
		variables: {
			characterNote,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterNoteOutput on CharacterNote {
				${returnFields}
			}
			mutation updateCharacterNote($characterNote: CharacterNoteInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateCharacterNote(input: { characterNote: $characterNote, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterNote {
						...CharacterNoteOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateCharacterNotes = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterNotes: Partial<CharacterNote>[], fieldsToUpdate: Array<keyof CharacterNote>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterNotesOutput, UpdateCharacterNotesInput>({
		variables: {
			characterNotes,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterNotesOutput on CharacterNote {
				${returnFields}
			}
			mutation updateCharacterNotes($characterNotes: [CharacterNoteInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateCharacterNotes(input: { characterNotes: $characterNotes, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterNote {
						...CharacterNotesOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteCharacterNote = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterNoteOutput, DeleteCharacterNoteInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteCharacterNote($id: UUID!) {
				deleteCharacterNote(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteCharacterNotes = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterNotesOutput, DeleteCharacterNotesInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteCharacterNotes($ids: [UUID!]) {
				deleteCharacterNotes(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
