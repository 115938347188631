export interface AbstractModel {
	id: string;
	created: string;
	modified: string;
}

export type ModelValidationErrors<T extends AbstractModel> = {
	[key in keyof T]?: string;
};

export function validateAbstractModel(model: Partial<AbstractModel>, validateSystemFields = false): ModelValidationErrors<AbstractModel> | undefined {
	const validationErrors: ModelValidationErrors<AbstractModel> = {};

	if (validateSystemFields) {
		if (!model.id) {
			validationErrors.id = "ID is a required field.";
		}
		if (!model.created) {
			validationErrors.created = "Created is a required field.";
		}
		if (!model.modified) {
			validationErrors.modified = "Modified is a required field.";
		}
	}

	return Object.keys(validationErrors).length > 0 ? validationErrors : undefined;
}
