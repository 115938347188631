import React, { forwardRef, PropsWithChildren, useEffect } from "react";
import { motion } from "framer-motion";
import styled, { css, useTheme } from "styled-components";
import TitleBar, { TitleBarProps } from "../Content/TitleBar";
import TabMenuStore from "Stores/TabMenuStore";
import { useDi } from "Utils/DependencyInjection";
import { transparentize } from "Style/Color";

export type PageProps = PropsWithChildren<{
	className?: string;
	titleBar?: TitleBarProps;
	tabBar?: boolean;
	padding?: string;
	background?: string;
}>;

const Page = forwardRef<HTMLDivElement, PageProps>((props, ref) => {
	const theme = useTheme();
	const tabMenuStore = useDi(TabMenuStore);

	const padding = props.padding ?? theme.spacing.xxs;
	const tabBar = props.tabBar ?? true;

	useEffect(() => {
		tabMenuStore.isVisible = tabBar;
	}, [tabBar, tabMenuStore]);

	return (
		<StyledPage ref={ref} className={`Page ${props.className || ""}`} $background={props.background}>
			{props.titleBar ? <TitleBar {...props.titleBar} /> : <div />}
			<PageContent className="PageContent" $padding={padding}>
				{props.children}
			</PageContent>
		</StyledPage>
	);
});

export default motion(Page);

export const PageWrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: auto;
`;

const PageContent = styled.div<{ $padding: string }>`
	${(props) => css`
		box-sizing: border-box;
		padding: ${props.$padding};
		width: 100%;
		height: 100%;
		overflow: auto;
	`}
`;

const StyledPage = styled.div<{ $background?: string }>`
	${({ $background }) => css`
		position: relative;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		overflow-y: auto;
		display: grid;
		grid-template-rows: auto 1fr;
		background-size: cover;
		background-position: center;
		${$background ? `background-image: url(${$background});` : ""}

		> * {
			z-index: 1;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: ${(props) => transparentize(props.theme.palette.primary, 0.2)};
			z-index: 0;
		}
	`}
`;
