import Padding from "Component/Global/Content/Padding";
import TitleBar from "Component/Global/Content/TitleBar";
import Button from "Component/Global/Input/Button";
import EditableText from "Component/Global/Input/EditableText";
import { CharacterAgeValidation } from "Models/Character";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import modalManager from "Utils/ModalManager";
import { ValidationError } from "validate";

export interface AgeEditModalProps {
	className?: string;
	value?: number;
	onChange: (val?: number) => void;
}

export default function AgeEditModal(props: AgeEditModalProps) {
	const theme = useTheme();
	const [age, setAge] = useState<string>(props.value ? props.value.toString() : "");

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const performValidation = (val: string | undefined) => {
		const errors = CharacterAgeValidation.validate({ age: val });
		setValidationErrors(errors);
		return errors;
	};

	const handleChanged = (val: string) => {
		setAge(val);
		// If we're already invalid, try to validate again
		if (validationErrors.length > 0) {
			performValidation(val);
		}
	};

	const handleBlur = () => {
		performValidation(age);
	};

	const handleCloseClicked = () => {
		const isValid = performValidation(age).length === 0;

		if (isValid) {
			if (age.trim() === "") {
				props.onChange(undefined);
			} else {
				const parsedAge = parseFloat(age);
				props.onChange(parsedAge);
			}

			modalManager.hide("character-edit-age");
		}
	};

	const isValid = validationErrors.length === 0;

	return (
		<StyledAgeEditModal className={`AgeEditModal ${props.className || ""}`}>
			<StyledTitleBar title="Age" />
			<Content>
				<EditableText
					title="Character age"
					textItems={[
						{
							placeholder: "Tap edit to set age",
							value: age,
							onChange: handleChanged,
							onBlur: handleBlur,
							type: "number",
							multiline: false,
						},
					]}
					editOnClick
				/>
			</Content>
			<CloseButton onClick={handleCloseClicked} isResponsive scheme={isValid ? theme.schemes.accent1 : undefined}>
				{isValid ? "Save age" : "Invalid age"}
			</CloseButton>
		</StyledAgeEditModal>
	);
}

const StyledTitleBar = styled(TitleBar)`
	flex-grow: 0;
	flex-shrink: 0;
`;

const Content = styled(Padding)`
	flex-grow: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const CloseButton = styled(Button)`
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;

const StyledAgeEditModal = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacing.xxs};
`;
