/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import * as GraphQLQuery from "GraphQL/GraphQLQuery";
import { GraphQLQueryResponse, PaginatedGraphQLResponse } from "GraphQL/GraphQLResponse";
import { Character } from "Models/Character";
import Gender from "Enums/Gender";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

export type GetCharacterByIdInput = {
	id: string;
};
export type GetCharacterByIdOutput = GraphQLQueryResponse<Character, "getCharacterById">;

export const getCharacterById = async (apollo: Promise<Apollo<unknown>>, returnFields: string, id: string) => {
	const response = await (
		await apollo
	).query<GetCharacterByIdOutput, GetCharacterByIdInput>({
		variables: {
			id,
		},
		query: gql`
			fragment CharacterOutput on Character {
				${returnFields}
			}
			query getCharacterById($id : UUID!) {
				getCharacterById(id: $id) {
					...CharacterOutput
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

export type CharacterInputFilter = {
	and?: CharacterInputFilter[];
	or?: CharacterInputFilter[];
	id?: GraphQLQuery.ComparableNullableOfGuidOperationFilterInput;
	created?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	modified?: GraphQLQuery.ComparableNullableOfDateTimeOperationFilterInput;
	spaceId?: GraphQLQuery.ComparableGuidOperationFilterInput;
	name?: GraphQLQuery.StringOperationFilterInput;
	gender?: GraphQLQuery.EnumOperationFilterInput<Gender>;
	title?: GraphQLQuery.StringOperationFilterInput;
	age?: GraphQLQuery.ComparableDecimalOperationFilterInput;
	isSaved?: GraphQLQuery.BooleanOperationFilterInput;
};
export type GetCharactersInput = GraphQLQuery.PaginatedQueryInput<Character, CharacterInputFilter>;
export type GetCharactersOutput = PaginatedGraphQLResponse<Character, "getCharacters">;
const GetCharactersInputTypes: Record<keyof GetCharactersInput, string> = {
	first: "Int",
	after: "String",
	last: "Int",
	before: "String",
	where: "CharacterFilterInput",
	order: "[CharacterSortInput!]",
};
export const getCharacters = async (apollo: Promise<Apollo<unknown>>, returnFields: string, variables: GetCharactersInput = {}) => {
	const [operationVars, queryVars] = GraphQLQuery.getQueryVars(variables, GetCharactersInputTypes);
	const response = await (
		await apollo
	).query<GetCharactersOutput, GetCharactersInput>({
		variables,
		query: gql`
			fragment CharacterOutput on Character {
				${returnFields}
			}
			query getCharacters${operationVars} {
				getCharacters${queryVars} {
					totalCount
					pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					edges {
						cursor
						node {
							...CharacterOutput
						}
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] on begin */
export type SearchCharacterInput = Omit<GraphQLQuery.PaginatedQueryInput<Character, undefined>, "where"> & {
	query: string;
};
const SearchCharacterInputTypes: Record<keyof SearchCharacterInput, string> = {
	first: "Int",
	after: "String",
	last: "Int",
	before: "String",
	query: "String",
	order: "[CharacterSortInput!]",
};

export type SearchCharactersOutput = PaginatedGraphQLResponse<Character, "searchCharacters">;

export const searchCharacters = async (apollo: Promise<Apollo<unknown>>, returnFields: string, variables: SearchCharacterInput) => {
	const [operationVars, queryVars] = GraphQLQuery.getQueryVars(variables, SearchCharacterInputTypes);
	return (await apollo).query<SearchCharactersOutput, SearchCharacterInput>({
		variables,
		query: gql`
			fragment SearchCharactersOutput on Character {
				${returnFields}
			}
			query searchCharacters${operationVars} {
				searchCharacters${queryVars} {
					totalCount
					pageInfo {
						hasNextPage
						hasPreviousPage
						startCursor
						endCursor
					}
					edges {
						cursor
						node {
							...SearchCharactersOutput
						}
					}
				}
			}
		`,
	});
};

/* protected region [Anything else] end */
