/**
 * @generated
 */

enum Gender {
	GENDERLESS = "GENDERLESS",
	MALE = "MALE",
	FEMALE = "FEMALE",
}

export default Gender;

export const GenderDisplayName: Record<Gender, string> = {
	GENDERLESS: "Genderless",
	MALE: "Male",
	FEMALE: "Female",
};
