export default class WrappedArray<T> {
	private arr: T[] = [];
	private currentIndex = 0;

	constructor(existing?: T[], startingIndex?: number) {
		if (existing) {
			this.arr = [...existing];
		}
		if (startingIndex !== undefined) {
			this.currentIndex = startingIndex;
		}
	}

	public toArray(startingIndex = 0): T[] {
		const originalIndex = this.currentIndex;
		this.currentIndex = startingIndex;

		const arr: T[] = [this.arr[startingIndex]];

		const total = this.getLength() - 1;
		for (let i = 0; i < total; i++) {
			arr.push(this.next());
		}

		this.currentIndex = originalIndex;

		return arr;
	}

	public isFirst() {
		return this.currentIndex === 0;
	}

	public isLast() {
		return this.currentIndex === this.arr.length - 1;
	}

	public getCurrentIndex() {
		return this.currentIndex;
	}

	public getLength() {
		return this.arr.length;
	}

	public peekNext(): T {
		const i = this.getNextIndex();
		return this.arr[i];
	}

	public peekPrevious(): T {
		const i = this.getPreviousIndex();
		return this.arr[i];
	}

	public current(): T {
		return this.arr[this.currentIndex];
	}

	public next(): T {
		const i = this.getNextIndex();
		this.currentIndex = i;
		return this.arr[i];
	}

	public previous(): T {
		const i = this.getPreviousIndex();
		this.currentIndex = i;
		return this.arr[i];
	}

	public reset(index = 0) {
		this.currentIndex = index;
	}

	public indexOf(item: T) {
		return this.arr.indexOf(item);
	}

	public setCurrentItem(item: T) {
		this.currentIndex = this.arr.indexOf(item);
	}

	private getNextIndex(): number {
		if (this.currentIndex === this.arr.length - 1) {
			return 0;
		} else {
			return this.currentIndex + 1;
		}
	}

	private getPreviousIndex() {
		if (this.currentIndex === 0) {
			return this.arr.length - 1;
		} else {
			return this.currentIndex - 1;
		}
	}
}
