import dayjs, { Dayjs } from "dayjs";

export default class DateUtil {
	public static readonly FORMAT_DATE = "DD-MM-YYYY";
	public static readonly FORMAT_DATE_REVERSE = "YYYY-MM-DD";
	public static readonly FORMAT_DATE_ISO_DATE = "YYYY-MM-DDT00:00:00.000[Z]";

	public static parse(date: string | undefined): Dayjs {
		return date ? dayjs(date) : dayjs();
	}

	public static format(date: string | Dayjs | undefined, format: string): string {
		if (!date) {
			return "";
		}

		if (typeof date == "string") {
			date = this.parse(date);
		}

		return date.format(format);
	}
}
