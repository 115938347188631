import React, { useState } from "react";
import styled, { css } from "styled-components";
import Icon, { IconName } from "Component/Global/Content/Icon";
import CharacterService from "Services/CharacterService";
import { useDi } from "Utils/DependencyInjection";
import { RoundButton } from "./CharacterCover";
import Button from "Component/Global/Input/Button";
import Pivot, { PivotItem } from "Component/Global/Navigation/Pivot/Pivot";
import CharacterDetailsAbout from "./CharacterDetailsAbout";
import CharacterPageStore from "Stores/CharacterPageStore";
import { observer } from "mobx-react";
import useBreakpoints, { BreakpointResult } from "Hooks/UseBreakpoints";
import Padding from "Component/Global/Content/Padding";
import TextInput from "Component/Global/Input/TextInput";
import modalManager from "Utils/ModalManager";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import { Size } from "Style/Sizing";
import AsyncImage from "Component/Global/Content/AsyncImage";

export interface CharacterDetailsProps {
	className?: string;
}

export default observer(CharacterDetails);
function CharacterDetails(props: CharacterDetailsProps) {
	const store = useDi(CharacterPageStore);
	const characterService = useDi(CharacterService);
	const character = store.character;

	const [isLoading, setIsLoading] = useState(false);

	// const imageSrc = useAsyncValue(() => characterService.getCoverImageSrc(character ?? {}), [character, characterService]);
	const imageSrc = characterService.getCoverImageSrc(character ?? {});

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const breakpoints = useBreakpoints(store.listEl);

	if (!character) {
		return null;
	}

	const handleToggleExpand = () => {
		store.patchColumnLayout({ details: store.columnLayout.details === "expanded" ? true : "expanded" });
	};

	const handleCollapseClicked = () => {
		store.patchColumnLayout({ details: false });
	};

	const handleToggleEdit = async () => {
		if (store.isEditing) {
			try {
				setIsLoading(true);
				const savedCharacter = await characterService.save(character);
				store.character = {
					...character,
					...savedCharacter,
				};
			} catch (err) {
				modalManager.showError(err as Error);
			} finally {
				setIsLoading(false);
				store.isEditing = false;
			}
		} else {
			store.isEditing = true;
		}
	};

	const handleNameChanged = (name: string) => {
		store.character = {
			...store.character!,
			name,
		};
	};

	const handleTitleChanged = (title: string) => {
		store.character = {
			...store.character!,
			title,
		};
	};

	const isExpanded = store.columnLayout.details === "expanded";

	const pivotItems: PivotItem[] = [
		{
			component: () => <CharacterDetailsAbout />,
			id: "about",
			menuTitle: "About",
		},
		{
			component: () => <p>Story</p>,
			id: "story",
			menuTitle: "Story",
		},
		{
			component: () => <p>Connections</p>,
			id: "connections",
			menuTitle: "Connections",
		},
	];

	return (
		<StyledCharacterDetails className={`CharacterDetails ${props.className || ""}`} ref={(ref) => (store.detailsEl = ref)} $breakpoints={breakpoints} $isEditing={store.isEditing}>
			<Controls>
				<RoundButton iconOnly onClick={handleToggleExpand}>
					<Icon name={isExpanded ? IconName.Minimize : IconName.Maximize} />
				</RoundButton>
				<EditButton iconOnly onClick={handleToggleEdit} $editing={store.isEditing} disabled={isLoading}>
					{isLoading ? <StyledLoader layoutType={LoaderLayout.InlineButton} size={Size.Small} /> : <Icon name={store.isEditing ? IconName.Save : IconName.Edit} />}
				</EditButton>

				<Close onClick={handleCollapseClicked}>
					Collapse
					<Image src={imageSrc} />
				</Close>
			</Controls>
			<Details>
				<Padding>
					{store.isEditing ? <TextInput label="Name" value={character.name} onChange={handleNameChanged} /> : <Name>{character.name}</Name>}
					{store.isEditing ? <TextInput label="Title" value={character.title} onChange={handleTitleChanged} /> : <Title>{character.title}</Title>}
				</Padding>
				<StyledPivot items={pivotItems} />
			</Details>
		</StyledCharacterDetails>
	);
}

const StyledLoader = styled(Loader)`
	margin: 0;
`;

const Close = styled(Button)`
	background: none;
	font-weight: ${(props) => props.theme.typography.fontWeight.regular};
	font-size: ${(props) => props.theme.typography.fontSizes.medium};
	&:hover {
		background: none;
	}
`;

const Image = styled(AsyncImage)`
	${({ theme }) => css`
		object-fit: cover;
		border-radius: 9999px;
		vertical-align: middle;
		width: ${theme.spacing.xxl};
		height: ${theme.spacing.xxl};
		margin-left: ${theme.spacing.md};
	`}
`;

const EditButton = styled(RoundButton)<{ $editing: boolean }>`
	${(props) =>
		props.$editing &&
		css`
			background: ${props.theme.palette.accent1};
			&:hover {
				background: ${props.theme.palette.accent1};
			}
		`}
`;

const Controls = styled.div`
	${({ theme }) => css`
		display: grid;
		gap: ${theme.spacing.md};
		grid-template-columns: auto 1fr auto;
		box-sizing: border-box;
		padding: ${theme.spacing.lg};
	`}
`;

const Name = styled.h2`
	${({ theme }) => css`
		text-align: center;
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;
const Title = styled.h3`
	${({ theme }) => css`
		text-align: center;
		font-style: italic;
		font-weight: ${theme.typography.fontWeight.light};
		font-size: ${theme.typography.fontSizes.medium};
		margin: ${theme.spacing.sm} 0;
	`}
`;

const StyledPivot = styled(Pivot)`
	${({ theme }) => css`
		background: ${theme.palette.shade3};
	`}
`;
const Details = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
`;

const StyledCharacterDetails = styled.div<{ $breakpoints?: BreakpointResult; $isEditing: boolean }>`
	${({ theme, $breakpoints, $isEditing }) => css`
		height: 100%;
		width: 100%;
		display: grid;
		grid-template-rows: auto 1fr;

		${$isEditing &&
		css`
			${Details} > .Padding {
				display: flex;
				flex-direction: column;
				gap: ${theme.spacing.md};
				> * {
					flex-grow: 1;
				}
			}
		`}

		${$breakpoints?.largerThan?.xs &&
		css`
			${$isEditing &&
			css`
				${Details} > .Padding {
					flex-direction: row;
				}
			`}
			${Name} {
				text-align: left;
				font-size: ${theme.typography.fontSizes.xxLarge};
			}
			${Title} {
				text-align: left;
				font-size: ${theme.typography.fontSizes.xLarge};
				margin-top: 0;
			}
			.CharacterDetailsAbout {
				.Padding {
					display: flex;
					flex-direction: row-reverse;
					gap: ${theme.spacing.md};
				}
				p {
					flex-grow: 1;
					margin-top: 0;
				}
			}
		`}
	`}
`;
