import React, { useLayoutEffect, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { PivotChildProps } from "./Pivot";
import PivotMenuItem from "./PivotMenuItem";

export interface PivotMenuProps extends PivotChildProps {
	className?: string;
	centerAlign?: boolean;
}

export default function PivotMenu(props: PivotMenuProps) {
	const theme = useTheme();
	const items = props.items.toArray();

	const elRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (elRef.current) {
			const el = elRef.current?.querySelector(`[data-id="${props.items.current().id}"]`) as HTMLElement;

			elRef.current?.scrollTo({
				behavior: "smooth",
				left: el?.offsetLeft - elRef.current.offsetLeft - parseInt(theme.spacing.md),
				top: 0,
			});
		}
	}, [props.items, theme.spacing.md]);

	return (
		<StyledPivotMenu ref={elRef} className={`PivotMenu ${props.className || ""}`}>
			<ItemWrapper className="ItemWrapper" $centerAlign={props.centerAlign}>
				{items.map((item) => (
					<PivotMenuItem key={item.id} id={item.id} text={item.menuTitle} isSelected={item === props.items.current()} onClick={() => props.onRequestUpdate(item)} />
				))}
			</ItemWrapper>
		</StyledPivotMenu>
	);
}

const ItemWrapper = styled.div<{ $centerAlign?: boolean }>`
	${({ theme, $centerAlign }) => css`
		display: flex;
		flex-wrap: none;
		gap: ${theme.spacing.md};
		width: ${$centerAlign ? "100%" : "fit-content"};
		justify-content: ${$centerAlign ? "center" : "flex-start"};
	`}
`;

const StyledPivotMenu = styled.div`
	position: relative;
	overflow-x: scroll;
	overflow-y: hidden;

	padding: ${(props) => props.theme.spacing.md};
	max-width: 100vw;
	box-sizing: border-box;

	/* width */
	::-webkit-scrollbar {
		display: none;
	}

	/* Track */
	::-webkit-scrollbar-track {
		display: none;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		display: none;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		display: none;
	}
`;
