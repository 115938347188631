import React from "react";
import styled from "styled-components";
import Page from "./Global/Navigation/Page";

export interface NotFoundPageProps {
	className?: string;
}

export default function NotFoundPage(props: NotFoundPageProps) {
	return (
		<StyledNotFoundPage className={`NotFoundPage ${props.className || ""}`}>
			<Page titleBar={{ title: "Not found" }}>TODO</Page>
		</StyledNotFoundPage>
	);
}

const StyledNotFoundPage = styled.div``;
