/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { CharacterRoleplay } from "Models/CharacterRoleplay";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddCharacterRoleplayInput = {
	characterRoleplay: Partial<CharacterRoleplay>;
};
export type AddCharacterRoleplayOutput = GraphQLMutationResponse<CharacterRoleplay, "addCharacterRoleplay", "characterRoleplay">;
export type AddCharacterRoleplaysInput = {
	characterRoleplays: Partial<CharacterRoleplay>[];
};
export type AddCharacterRoleplaysOutput = GraphQLMutationResponse<CharacterRoleplay[], "addCharacterRoleplays", "characterRoleplays">;

// Update Types
export type UpdateCharacterRoleplayInput = {
	characterRoleplay: Partial<CharacterRoleplay>;
	fieldsToUpdate?: Array<keyof CharacterRoleplay>;
	replaceReferences?: boolean;
};
export type UpdateCharacterRoleplayOutput = GraphQLMutationResponse<CharacterRoleplay, "updateCharacterRoleplay", "characterRoleplay">;
export type UpdateCharacterRoleplaysInput = {
	characterRoleplays: Partial<CharacterRoleplay>[];
	fieldsToUpdate?: Array<keyof CharacterRoleplay>;
	replaceReferences?: boolean;
};
export type UpdateCharacterRoleplaysOutput = GraphQLMutationResponse<CharacterRoleplay[], "updateCharacterRoleplays", "characterRoleplays">;

// Delete Types
export type DeleteCharacterRoleplayInput = {
	id: string;
};
export type DeleteCharacterRoleplayOutput = GraphQLDeleteResponse<"deleteCharacterRoleplay">;
export type DeleteCharacterRoleplaysInput = {
	ids: string[];
};
export type DeleteCharacterRoleplaysOutput = GraphQLBulkDeleteResponse<"deleteCharacterRoleplays">;

// Add Mutations
export const addCharacterRoleplay = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterRoleplay: Partial<CharacterRoleplay>) => {
	const response = await (
		await apollo
	).mutate<AddCharacterRoleplayOutput, AddCharacterRoleplayInput>({
		variables: {
			characterRoleplay,
		},
		mutation: gql`
			fragment CharacterRoleplayOutput on CharacterRoleplay {
				${returnFields}
			}
			mutation addCharacterRoleplay($characterRoleplay: CharacterRoleplayInput!) {
				addCharacterRoleplay(input: { characterRoleplay: $characterRoleplay }) {
					characterRoleplay {
						...CharacterRoleplayOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addCharacterRoleplays = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characterRoleplays: Partial<CharacterRoleplay>[]) => {
	const response = await (
		await apollo
	).mutate<AddCharacterRoleplaysOutput, AddCharacterRoleplaysInput>({
		variables: {
			characterRoleplays,
		},
		mutation: gql`
			fragment CharacterRoleplaysOutput on CharacterRoleplay {
				${returnFields}
			}
			mutation addCharacterRoleplays($characterRoleplays: [CharacterRoleplayInput!]!) {
				addCharacterRoleplays(input: { characterRoleplays: $characterRoleplays }) {
					characterRoleplay {
						...CharacterRoleplaysOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateCharacterRoleplay = async (
	apollo: Promise<Apollo<unknown>>,
	returnFields: string,
	characterRoleplay: Partial<CharacterRoleplay>,
	fieldsToUpdate: Array<keyof CharacterRoleplay>,
	replaceReferences = false,
) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterRoleplayOutput, UpdateCharacterRoleplayInput>({
		variables: {
			characterRoleplay,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterRoleplayOutput on CharacterRoleplay {
				${returnFields}
			}
			mutation updateCharacterRoleplay($characterRoleplay: CharacterRoleplayInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateCharacterRoleplay(input: { characterRoleplay: $characterRoleplay, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterRoleplay {
						...CharacterRoleplayOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateCharacterRoleplays = async (
	apollo: Promise<Apollo<unknown>>,
	returnFields: string,
	characterRoleplays: Partial<CharacterRoleplay>[],
	fieldsToUpdate: Array<keyof CharacterRoleplay>,
	replaceReferences = false,
) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterRoleplaysOutput, UpdateCharacterRoleplaysInput>({
		variables: {
			characterRoleplays,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterRoleplaysOutput on CharacterRoleplay {
				${returnFields}
			}
			mutation updateCharacterRoleplays($characterRoleplays: [CharacterRoleplayInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateCharacterRoleplays(input: { characterRoleplays: $characterRoleplays, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					characterRoleplay {
						...CharacterRoleplaysOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteCharacterRoleplay = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterRoleplayOutput, DeleteCharacterRoleplayInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteCharacterRoleplay($id: UUID!) {
				deleteCharacterRoleplay(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteCharacterRoleplays = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterRoleplaysOutput, DeleteCharacterRoleplaysInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteCharacterRoleplays($ids: [UUID!]) {
				deleteCharacterRoleplays(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] off begin */
/* protected region [Anything else] end */
