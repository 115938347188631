import { createContext, useContext, useMemo } from "react";
import DIContainer from "rsdi";
import { ResolverName, ResolvedType } from "rsdi/types";

export const injectedServices = new DIContainer();
export const DiContext = createContext(injectedServices);

export function useNamedDi<T>(name: string): T {
	const diContext = useContext(DiContext);
	const resolvedInjection = useMemo(() => diContext.get<T>(name), [diContext, name]);
	return resolvedInjection;
}

export function useDi<Custom = void, Name extends ResolverName = string>(dependencyName: Name, parentDeps?: string[]): ResolvedType<Custom, Name> {
	const diContext = useContext(DiContext);
	const resolvedInjection = useMemo<ResolvedType<Custom, Name>>(() => diContext.get(dependencyName, parentDeps), [dependencyName, diContext, parentDeps]);
	return resolvedInjection;
}

export function resolveDi<Custom = void, Name extends ResolverName = string>(dependencyName: Name, parentDeps?: string[]): ResolvedType<Custom, Name> {
	return injectedServices.get(dependencyName, parentDeps);
}

export function resolveNamedDi<T>(name: string): T {
	return injectedServices.get<T>(name);
}
