import { action, AnnotationsMap, makeObservable } from "mobx";
import { Character } from "Models/Character";
import { Group } from "Models/Group";
import AbstractTraitsStore, { AbstractTraitsStorePrivateProps, AbstractTraitsStoreProps } from "./AbstractTraitsStore";

export type GeneratorStorePrivateProps = AbstractTraitsStorePrivateProps | "_name" | "_generatedCharacters";

export const GeneratorStoreProps: AnnotationsMap<GeneratorStore, GeneratorStorePrivateProps> = {
	...AbstractTraitsStoreProps,
	_name: true,
	_generatedCharacters: true,
	name: true,
	generatedCharacters: true,
	addGeneratedCharacter: action,
};

export default class GeneratorStore extends AbstractTraitsStore {
	private _name?: string;

	private _generatedCharacters: Partial<Character>[] = [];

	private _groupArchetype?: Group;

	constructor() {
		super();
		makeObservable(this, GeneratorStoreProps);
	}

	get name() {
		return this._name;
	}
	set name(name: string | undefined) {
		this._name = name;
	}

	get generatedCharacters() {
		return this._generatedCharacters;
	}
	set generatedCharacters(generatedCharacters: Partial<Character>[]) {
		this._generatedCharacters = generatedCharacters;
	}

	get groupArchetype() {
		return this._groupArchetype;
	}

	set groupArchetype(groupArchetype: Group | undefined) {
		this._groupArchetype = groupArchetype;

		if (groupArchetype) {
			this.selectedRaceIds = groupArchetype.races?.map((x) => x.id!) ?? [];
			this.selectedCultureIds = groupArchetype.cultures?.map((x) => x.id!) ?? [];
			this.selectedPersonalityIds = groupArchetype.personalities?.map((x) => x.id!) ?? [];
			this.selectedProfessionIds = groupArchetype.professions?.map((x) => x.id!) ?? [];
			this.selectedGenders = groupArchetype.genders?.map((x) => x.gender!) ?? [];
		}
	}

	public addGeneratedCharacter(character: Partial<Character>) {
		this._generatedCharacters = [character, ...this._generatedCharacters];
	}
}
