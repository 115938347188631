/**
 * @generated
 */
import { AbstractModel, ModelValidationErrors, validateAbstractModel } from "Models/AbstractModel";
import Gender from "Enums/Gender";
import { CharacterNote } from "Models/CharacterNote";
import { CharacterDetail } from "Models/CharacterDetail";
import { CharacterRoleplay } from "Models/CharacterRoleplay";
import { CharacterImage } from "Models/CharacterImage";
import { Culture } from "Models/Culture";
import { Race } from "Models/Race";
import { Group } from "Models/Group";
import { Personality } from "Models/Personality";
import { Profession } from "Models/Profession";
import { MembersGroups } from "Models/MembersGroups";
/* protected region [Any additional imports] on begin */
import Schema from "validate";
/* protected region [Any additional imports] end */

export interface Character extends AbstractModel {
	name: string;
	gender: Gender;
	title?: string;
	age: number;
	isSaved: boolean;
	spaceId?: string;

	// Outgoing one-to-one
	notes?: Partial<CharacterNote>;

	// Outgoing one-to-one
	details?: Partial<CharacterDetail>;

	// Outgoing one-to-one
	roleplay?: Partial<CharacterRoleplay>;

	// Outgoing one-to-many
	images?: Partial<CharacterImage>[];

	// Incoming one-to-many
	cultureId?: string | null;
	culture?: Partial<Culture> | null;

	// Incoming one-to-many
	raceId?: string | null;
	race?: Partial<Race> | null;

	// Incoming many-to-many
	membersGroups?: Partial<MembersGroups>[];
	groups?: Partial<Group>[];

	// Incoming one-to-many
	personalityId?: string | null;
	personality?: Partial<Personality> | null;

	// Incoming one-to-many
	professionId?: string | null;
	profession?: Partial<Profession> | null;

	/* protected region [Any additional model configuration] off begin */
	/* protected region [Any additional model configuration] end */
}

export function validateCharacter(model: Partial<Character>) {
	const validationErrors: ModelValidationErrors<Character> = validateAbstractModel(model) ?? {};

	/* protected region [Customise the required validation logic here] on begin */
	if (!model.name) {
		validationErrors.name = "Name is required";
	}
	/* protected region [Customise the required validation logic here] end */

	/* protected region [Customise additional validation logic here] off begin */
	/* protected region [Customise additional validation logic here] end */

	return Object.keys(validationErrors).length > 0 ? validationErrors : undefined;
}

/* protected region [Anything else] on begin */
export const CharacterAgeValidation = new Schema({
	age: {
		match: /^[0-9.]*$/,
		message: "Invalid age",
	},
});
/* protected region [Anything else] end */
