/**
 * @generated
 */
import { ApolloError, ApolloClient as Apollo, gql } from "@apollo/client";
import { GraphQLMutationResponse, GraphQLDeleteResponse, GraphQLBulkDeleteResponse } from "GraphQL/GraphQLResponse";
import { Character } from "Models/Character";
/* protected region [Any additional imports] off begin */
/* protected region [Any additional imports] end */

// Add Types
export type AddCharacterInput = {
	character: Partial<Character>;
};
export type AddCharacterOutput = GraphQLMutationResponse<Character, "addCharacter", "character">;
export type AddCharactersInput = {
	characters: Partial<Character>[];
};
export type AddCharactersOutput = GraphQLMutationResponse<Character[], "addCharacters", "characters">;

// Update Types
export type UpdateCharacterInput = {
	character: Partial<Character>;
	fieldsToUpdate?: Array<keyof Character>;
	replaceReferences?: boolean;
};
export type UpdateCharacterOutput = GraphQLMutationResponse<Character, "updateCharacter", "character">;
export type UpdateCharactersInput = {
	characters: Partial<Character>[];
	fieldsToUpdate?: Array<keyof Character>;
	replaceReferences?: boolean;
};
export type UpdateCharactersOutput = GraphQLMutationResponse<Character[], "updateCharacters", "characters">;

// Delete Types
export type DeleteCharacterInput = {
	id: string;
};
export type DeleteCharacterOutput = GraphQLDeleteResponse<"deleteCharacter">;
export type DeleteCharactersInput = {
	ids: string[];
};
export type DeleteCharactersOutput = GraphQLBulkDeleteResponse<"deleteCharacters">;

// Add Mutations
export const addCharacter = async (apollo: Promise<Apollo<unknown>>, returnFields: string, character: Partial<Character>) => {
	const response = await (
		await apollo
	).mutate<AddCharacterOutput, AddCharacterInput>({
		variables: {
			character,
		},
		mutation: gql`
			fragment CharacterOutput on Character {
				${returnFields}
			}
			mutation addCharacter($character: CharacterInput!) {
				addCharacter(input: { character: $character }) {
					character {
						...CharacterOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const addCharacters = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characters: Partial<Character>[]) => {
	const response = await (
		await apollo
	).mutate<AddCharactersOutput, AddCharactersInput>({
		variables: {
			characters,
		},
		mutation: gql`
			fragment CharactersOutput on Character {
				${returnFields}
			}
			mutation addCharacters($characters: [CharacterInput!]!) {
				addCharacters(input: { characters: $characters }) {
					character {
						...CharactersOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Update Mutations
export const updateCharacter = async (apollo: Promise<Apollo<unknown>>, returnFields: string, character: Partial<Character>, fieldsToUpdate: Array<keyof Character>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateCharacterOutput, UpdateCharacterInput>({
		variables: {
			character,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharacterOutput on Character {
				${returnFields}
			}
			mutation updateCharacter($character: CharacterInput!, $fieldsToUpdate : [String!], $replaceReferences: Boolean) {
				updateCharacter(input: { character: $character, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					character {
						...CharacterOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const updateCharacters = async (apollo: Promise<Apollo<unknown>>, returnFields: string, characters: Partial<Character>[], fieldsToUpdate: Array<keyof Character>, replaceReferences = false) => {
	const response = await (
		await apollo
	).mutate<UpdateCharactersOutput, UpdateCharactersInput>({
		variables: {
			characters,
			fieldsToUpdate,
			replaceReferences,
		},
		mutation: gql`
			fragment CharactersOutput on Character {
				${returnFields}
			}
			mutation updateCharacters($characters: [CharacterInput!]!, $fieldsToUpdate: [String!], $replaceReferences: Boolean) {
				updateCharacters(input: { characters: $characters, fieldsToUpdate: $fieldsToUpdate, replaceReferences: $replaceReferences }) {
					character {
						...CharactersOutput
					}
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

// Delete Mutations
export const deleteCharacter = async (apollo: Promise<Apollo<unknown>>, id: string) => {
	const response = await (
		await apollo
	).mutate<DeleteCharacterOutput, DeleteCharacterInput>({
		variables: {
			id,
		},
		mutation: gql`
			mutation deleteCharacter($id: UUID!) {
				deleteCharacter(input: { id: $id }) {
					id
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};
export const deleteCharacters = async (apollo: Promise<Apollo<unknown>>, ids: string[]) => {
	const response = await (
		await apollo
	).mutate<DeleteCharactersOutput, DeleteCharactersInput>({
		variables: {
			ids,
		},
		mutation: gql`
			mutation deleteCharacters($ids: [UUID!]) {
				deleteCharacters(input: { ids: $ids }) {
					ids
				}
			}
		`,
	});

	if (response.errors) {
		throw new ApolloError({ graphQLErrors: response.errors });
	}

	return response;
};

/* protected region [Anything else] on begin */
export type SetCharacterCoverImageOutput = {
	setCharacterCoverImage: {
		id: string;
	};
};
export type SetCharacterCoverImageInput = {
	characterId: string;
	imageId: string;
};

export const setCharacterCoverImage = async (apollo: Promise<Apollo<unknown>>, characterId: string, imageId: string) => {
	return (await apollo).mutate<SetCharacterCoverImageOutput, SetCharacterCoverImageInput>({
		variables: {
			characterId,
			imageId,
		},
		mutation: gql`
			mutation setCharacterCoverImage($characterId: UUID!, $imageId: UUID!) {
				setCharacterCoverImage(input: { characterId: $characterId, imageId: $imageId }) {
					id
				}
			}
		`,
	});
};
/* protected region [Anything else] end */
