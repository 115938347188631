export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTime: string;
	Decimal: number;
	UUID: string;
};
export type BooleanOperationFilterInput = {
	eq?: Maybe<Scalars["Boolean"]>;
	neq?: Maybe<Scalars["Boolean"]>;
};
export type ComparableDecimalOperationFilterInput = {
	eq?: Maybe<Scalars["Decimal"]>;
	neq?: Maybe<Scalars["Decimal"]>;
	in?: Maybe<Array<Scalars["Decimal"]>>;
	nin?: Maybe<Array<Scalars["Decimal"]>>;
	gt?: Maybe<Scalars["Decimal"]>;
	ngt?: Maybe<Scalars["Decimal"]>;
	gte?: Maybe<Scalars["Decimal"]>;
	ngte?: Maybe<Scalars["Decimal"]>;
	lt?: Maybe<Scalars["Decimal"]>;
	nlt?: Maybe<Scalars["Decimal"]>;
	lte?: Maybe<Scalars["Decimal"]>;
	nlte?: Maybe<Scalars["Decimal"]>;
};

export type ComparableGuidOperationFilterInput = {
	eq?: Maybe<Scalars["UUID"]>;
	neq?: Maybe<Scalars["UUID"]>;
	in?: Maybe<Array<Scalars["UUID"]>>;
	nin?: Maybe<Array<Scalars["UUID"]>>;
	gt?: Maybe<Scalars["UUID"]>;
	ngt?: Maybe<Scalars["UUID"]>;
	gte?: Maybe<Scalars["UUID"]>;
	ngte?: Maybe<Scalars["UUID"]>;
	lt?: Maybe<Scalars["UUID"]>;
	nlt?: Maybe<Scalars["UUID"]>;
	lte?: Maybe<Scalars["UUID"]>;
	nlte?: Maybe<Scalars["UUID"]>;
};

export type ComparableInt32OperationFilterInput = {
	eq?: Maybe<Scalars["Int"]>;
	neq?: Maybe<Scalars["Int"]>;
	in?: Maybe<Array<Scalars["Int"]>>;
	nin?: Maybe<Array<Scalars["Int"]>>;
	gt?: Maybe<Scalars["Int"]>;
	ngt?: Maybe<Scalars["Int"]>;
	gte?: Maybe<Scalars["Int"]>;
	ngte?: Maybe<Scalars["Int"]>;
	lt?: Maybe<Scalars["Int"]>;
	nlt?: Maybe<Scalars["Int"]>;
	lte?: Maybe<Scalars["Int"]>;
	nlte?: Maybe<Scalars["Int"]>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
	eq?: Maybe<Scalars["DateTime"]>;
	neq?: Maybe<Scalars["DateTime"]>;
	in?: Maybe<Array<Maybe<Scalars["DateTime"]>>>;
	nin?: Maybe<Array<Maybe<Scalars["DateTime"]>>>;
	gt?: Maybe<Scalars["DateTime"]>;
	ngt?: Maybe<Scalars["DateTime"]>;
	gte?: Maybe<Scalars["DateTime"]>;
	ngte?: Maybe<Scalars["DateTime"]>;
	lt?: Maybe<Scalars["DateTime"]>;
	nlt?: Maybe<Scalars["DateTime"]>;
	lte?: Maybe<Scalars["DateTime"]>;
	nlte?: Maybe<Scalars["DateTime"]>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
	eq?: Maybe<Scalars["UUID"]>;
	neq?: Maybe<Scalars["UUID"]>;
	in?: Maybe<Array<Maybe<Scalars["UUID"]>>>;
	nin?: Maybe<Array<Maybe<Scalars["UUID"]>>>;
	gt?: Maybe<Scalars["UUID"]>;
	ngt?: Maybe<Scalars["UUID"]>;
	gte?: Maybe<Scalars["UUID"]>;
	ngte?: Maybe<Scalars["UUID"]>;
	lt?: Maybe<Scalars["UUID"]>;
	nlt?: Maybe<Scalars["UUID"]>;
	lte?: Maybe<Scalars["UUID"]>;
	nlte?: Maybe<Scalars["UUID"]>;
};

export type EnumOperationFilterInput<T> = {
	eq?: Maybe<T>;
	neq?: Maybe<T>;
	in?: Maybe<Array<T>>;
	nin?: Maybe<Array<T>>;
};

export type StringOperationFilterInput = {
	and?: Maybe<Array<StringOperationFilterInput>>;
	or?: Maybe<Array<StringOperationFilterInput>>;
	eq?: Maybe<Scalars["String"]>;
	neq?: Maybe<Scalars["String"]>;
	contains?: Maybe<Scalars["String"]>;
	ncontains?: Maybe<Scalars["String"]>;
	in?: Maybe<Array<Maybe<Scalars["String"]>>>;
	nin?: Maybe<Array<Maybe<Scalars["String"]>>>;
	startsWith?: Maybe<Scalars["String"]>;
	nstartsWith?: Maybe<Scalars["String"]>;
	endsWith?: Maybe<Scalars["String"]>;
	nendsWith?: Maybe<Scalars["String"]>;
};

export type QueryOrdering<T> = {
	[key in keyof T]?: "ASC" | "DESC";
}[];

export type PaginatedQueryInput<TModel, TWhereFilter> = {
	before?: string;
	after?: string;
	first?: number;
	last?: number;
	order?: QueryOrdering<TModel>;
	where?: TWhereFilter;
};

export function getQueryVars<T, K extends Record<keyof T, unknown>>(variables: T, types: K) {
	let operationVars = "";
	let queryVars = "";

	if (Object.keys(variables).length > 0) {
		operationVars = `(${Object.keys(variables)
			.map((k) => `$${k}: ${types[k as unknown as keyof T]}`)
			.join(",")})`;
		queryVars = `(${Object.keys(variables)
			.map((k) => `${k}: $${k}`)
			.join(",")})`;
	}

	return [operationVars, queryVars];
}
