import { motion } from "framer-motion";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "Static/Image/logo.svg";

export interface SplashPageProps {
	className?: string;
}

const SplashPage = forwardRef<HTMLDivElement, SplashPageProps>((props, ref) => {
	return (
		<StyledSplashPage ref={ref} className={`SplashPage ${props.className || ""}`}>
			<StyledLogo />
		</StyledSplashPage>
	);
});
export default motion(SplashPage);

const StyledLogo = styled(Logo)`
	${({ theme }) => css`
		align-self: center;
		width: 100%;
		max-width: calc(${theme.spacing.xxxl} * 3);
		height: 100%;
		path,
		rect {
			fill: ${theme.palette.secondary};
		}
	`};
`;
const StyledSplashPage = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	padding: ${(props) => props.theme.spacing.lg};
	box-sizing: border-box;
`;
