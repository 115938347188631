import React from "react";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import Selectable from "Component/Global/Interactive/SelectableList/Selectable";
import Gender, { GenderDisplayName } from "Enums/Gender";
import styled, { css, useTheme } from "styled-components";
import modalManager from "Utils/ModalManager";
import CultureFilter from "./CultureFilter";
import CustomName from "./CustomName";
import TraitFilter from "./TraitFilter";
import GeneratorStore from "Stores/GeneratorStore";
import GroupArchetypeStore from "Stores/GroupArchetypeStore";
import { observer } from "mobx-react";
import { Group } from "Models/Group";
import GroupService from "Services/GroupService";
import { useDi } from "Utils/DependencyInjection";
import { Size } from "Style/Sizing";
import { Textfit, TextfitProps } from "react-textfit";

export interface TraitsFiltersProps {
	className?: string;
	store: GroupArchetypeStore | GeneratorStore;
	onStoreUpdated?: () => void;
	groupArchetype?: Group;
}

function TraitsFilters(props: TraitsFiltersProps) {
	const theme = useTheme();
	const groupService = useDi(GroupService);

	const store = props.store;

	const handleNameFilterClicked = () => {
		if (store instanceof GeneratorStore) {
			modalManager.show({
				id: "filters-name",
				type: ModalType.FullScreen,
				children: <CustomName value={store.name} onChange={(name) => (store.name = name)} />,
			});
		}
	};
	const handleRaceFilterClicked = () => {
		modalManager.show({
			id: "filters-race",
			type: ModalType.FullScreen,
			children: (
				<TraitFilter
					modalId="filters-race"
					title="Select race"
					selectedItemIds={store.selectedRaceIds}
					onItemsSelected={(items) => {
						const raceIds = items.map((i) => i.id);
						store.selectedRaceIds = raceIds;
						props.onStoreUpdated?.();
					}}
					items={
						store.races.map((race) => {
							return {
								id: race.id!,
								children: race.name,
							};
						}) ?? []
					}
				/>
			),
		});
	};
	const handleCultureFilterClicked = () => {
		modalManager.show({
			id: "filters-culture",
			type: ModalType.FullScreen,
			children: (
				<CultureFilter
					selectedItemIds={store.selectedCultureIds}
					onItemsSelected={(items) => {
						store.selectedCultureIds = items.map((i) => i.id);
						props.onStoreUpdated?.();
					}}
					items={
						store.cultures
							.filter((culture) => {
								if (store.selectedRaceIds.length > 0) {
									return store.selectedRaceIds.indexOf(culture.raceId!) >= 0;
								} else {
									return true;
								}
							})
							.map((culture) => {
								return {
									id: culture.id!,
									children: culture.name,
									raceName: store.races?.find((x) => x.id === culture.raceId!)?.name ?? "",
								};
							}) ?? []
					}
				/>
			),
		});
	};
	const handleGenderFilterClicked = () => {
		modalManager.show({
			id: "filters-gender",
			type: ModalType.FullScreen,
			children: (
				<TraitFilter
					modalId="filters-gender"
					title="Select gender"
					selectedItemIds={store.selectedGenders}
					onItemsSelected={(items) => {
						store.selectedGenders = items.map((item) => item.id as Gender);
						props.onStoreUpdated?.();
					}}
					items={
						Object.keys(Gender).map((gender) => {
							return {
								id: gender,
								children: GenderDisplayName[gender as Gender],
							};
						}) ?? []
					}
				/>
			),
		});
	};
	const handlePersonalityFilterClicked = () => {
		modalManager.show({
			id: "filters-personality",
			type: ModalType.FullScreen,
			children: (
				<TraitFilter
					modalId="filters-personality"
					title="Select personality"
					selectedItemIds={store.selectedPersonalityIds}
					onItemsSelected={(items) => {
						store.selectedPersonalityIds = items.map((i) => i.id);
						props.onStoreUpdated?.();
					}}
					items={
						store.personalities.map((personality) => {
							return {
								id: personality.id!,
								children: personality.name,
							};
						}) ?? []
					}
				/>
			),
		});
	};
	const handleProfessionFilterClicked = () => {
		modalManager.show({
			id: "filters-profession",
			type: ModalType.FullScreen,
			children: (
				<TraitFilter
					modalId="filters-profession"
					title="Select profession"
					selectedItemIds={store.selectedProfessionIds}
					onItemsSelected={(items) => {
						store.selectedProfessionIds = items.map((i) => i.id);
						props.onStoreUpdated?.();
					}}
					items={
						store.professions.map((profession) => {
							return {
								id: profession.id!,
								children: profession.name,
							};
						}) ?? []
					}
				/>
			),
		});
	};

	const [groupArchetypeListItem] = store instanceof GeneratorStore && store.groupArchetype ? groupService.asSelectableList([store.groupArchetype], () => <></>) : [];

	const minSize = parseInt(theme.typography.fontSizes.xxxSmall);
	const maxLabelSize = parseInt(theme.typography.fontSizes.medium);
	const maxValueSize = parseInt(theme.typography.fontSizes.xLarge);

	const labelTextFitProps: TextfitProps = { mode: "single", min: minSize, max: maxLabelSize };
	const valueTextFitProps: TextfitProps = { mode: "single", min: minSize, max: maxValueSize };

	return (
		<StyledTraitsFilters className={`TraitsFilters ${props.className || ""}`} $includeName={store instanceof GeneratorStore} $includeArchetype={store instanceof GeneratorStore && store.groupArchetype !== undefined}>
			{store instanceof GeneratorStore && store.groupArchetype && groupArchetypeListItem && (
				<SelectableArchetype {...groupArchetypeListItem} size={Size.Small} isSelectable={false} isSelected={true}>
					<Label>
						<Textfit {...labelTextFitProps}>Group archetype</Textfit>
					</Label>
					<Value>
						<Textfit {...valueTextFitProps}>{store.groupArchetype.name}</Textfit>
					</Value>
				</SelectableArchetype>
			)}
			{store instanceof GeneratorStore && (
				<SelectableName isSelectable={false} isSelected={store.name ? true : false} onClick={handleNameFilterClicked}>
					<Label>
						<Textfit {...labelTextFitProps}>Name</Textfit>
					</Label>
					<Value>
						<Textfit {...valueTextFitProps}>{store.name ? store.name : "Random"}</Textfit>
					</Value>
				</SelectableName>
			)}
			<SelectableRace isSelectable={false} isSelected={store.selectedRaces.length > 0} onClick={handleRaceFilterClicked}>
				<Label>
					<Textfit {...labelTextFitProps}>Race</Textfit>
				</Label>
				<Value>
					<Textfit {...valueTextFitProps}>{store.selectedRaces.length === 0 ? "Random" : store.selectedRaces.length === 1 ? store.selectedRaces.at(0)?.name : `Multi (${store.selectedRaces.length})`}</Textfit>
				</Value>
			</SelectableRace>
			<SelectableCulture isSelectable={false} isSelected={store.selectedCultures.length > 0} onClick={handleCultureFilterClicked}>
				<Label>
					<Textfit {...labelTextFitProps}>Culture</Textfit>
				</Label>
				<Value>
					<Textfit {...valueTextFitProps}>
						{store.selectedCultures.length === 0 ? "Random" : store.selectedCultures.length === 1 ? store.selectedCultures.at(0)?.name : `Multi (${store.selectedCultures.length})`}
					</Textfit>
				</Value>
			</SelectableCulture>
			<SelectableGender isSelectable={false} isSelected={store.selectedGenders.length > 0} onClick={handleGenderFilterClicked}>
				<Label>
					<Textfit {...labelTextFitProps}>Gender</Textfit>
				</Label>
				<Value>
					<Textfit {...valueTextFitProps}>
						{store.selectedGenders.length === 0 ? "Random" : store.selectedGenders.length === 1 ? GenderDisplayName[store.selectedGenders.at(0) as Gender] : `Multi (${store.selectedGenders.length})`}
					</Textfit>
				</Value>
			</SelectableGender>
			<SelectablePersonality isSelectable={false} isSelected={store.selectedPersonalities.length > 0} onClick={handlePersonalityFilterClicked}>
				<Label>
					<Textfit {...labelTextFitProps}>Personality</Textfit>
				</Label>
				<Value>
					<Textfit {...valueTextFitProps}>
						{store.selectedPersonalities.length === 0 ? "Random" : store.selectedPersonalities.length === 1 ? store.selectedPersonalities.at(0)?.name : `Multi (${store.selectedPersonalities.length})`}
					</Textfit>
				</Value>
			</SelectablePersonality>
			<SelectableProfession isSelectable={false} isSelected={store.selectedProfessions.length > 0} onClick={handleProfessionFilterClicked}>
				<Label>
					<Textfit {...labelTextFitProps}>Profession</Textfit>
				</Label>
				<Value>
					<Textfit {...valueTextFitProps}>
						{store.selectedProfessions.length === 0 ? "Random" : store.selectedProfessions.length === 1 ? store.selectedProfessions.at(0)?.name : `Multi (${store.selectedProfessions.length})`}
					</Textfit>
				</Value>
			</SelectableProfession>
		</StyledTraitsFilters>
	);
}
export default observer(TraitsFilters);

const Label = styled.span`
	${({ theme }) => css`
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.medium};
		color: ${theme.palette.shade1};
	`}
`;
const Value = styled.p`
	${({ theme }) => css`
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.xLarge};
	`}
`;

const StyledSelectable = styled(Selectable)`
	.Padding {
		padding: ${(props) => props.theme.spacing.lg};
	}

	${Label} {
		color: ${(props) => (props.isSelected ? props.theme.palette.secondary : props.theme.palette.shade1)};
	}
`;
const SelectableArchetype = styled(StyledSelectable)`
	grid-area: archetype;
	margin-bottom: ${(props) => props.theme.spacing.sm};
`;
const SelectableName = styled(StyledSelectable)`
	grid-area: name;
`;
const SelectableRace = styled(StyledSelectable)`
	grid-area: race;
`;
const SelectableCulture = styled(StyledSelectable)`
	grid-area: culture;
`;
const SelectableGender = styled(StyledSelectable)`
	grid-area: gender;
`;
const SelectablePersonality = styled(StyledSelectable)`
	grid-area: personality;
`;
const SelectableProfession = styled(StyledSelectable)`
	grid-area: profession;
`;

const StyledTraitsFilters = styled.div<{ $includeName: boolean; $includeArchetype: boolean }>`
	${({ theme, $includeName, $includeArchetype }) => css`
		padding: ${theme.spacing.xxs};
		height: calc(100% - ${theme.spacing.xs});
		display: grid;
		gap: ${theme.spacing.xxs};
		align-content: end;
		grid-template-areas:
			${$includeArchetype ? `"archetype archetype"` : ``}
			${$includeName ? `"name name"` : ``}
			"race race"
			"culture gender"
			"personality profession";
	`}
`;
