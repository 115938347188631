import FileUploadRequest from "Models/Request/FileUploadRequest";
import FileUploadResponse from "Models/Response/FileUploadResponse";
import AxiosClient from "Utils/AxiosClient";
import AuthenticationService from "./AuthenticationService";
import ConfigService from "./ConfigService";

/**
 * UploadService
 * @singleton
 */
export default class UploadService {
	private readonly _configService: ConfigService;
	private readonly _axiosClient: AxiosClient;
	private readonly _authService: AuthenticationService;
	private readonly _imageCache: Promise<Cache>;

	private readonly concurrentImageCacheAccessor: Map<string, Promise<string | undefined>>;

	constructor(configService: ConfigService, axiosClient: AxiosClient, authService: AuthenticationService) {
		this._configService = configService;
		this._axiosClient = axiosClient;
		this._authService = authService;

		this._imageCache = caches.open("images");
		this.concurrentImageCacheAccessor = new Map<string, Promise<string | undefined>>();
	}
	public async uploadImage(file: File) {
		const axios = await this._axiosClient.instance();

		const response = await axios.post<FileUploadResponse>("Image", {
			name: file.name,
			mime: file.type,
		} as FileUploadRequest);

		const uploadBody = new Blob([file], { type: file.type });

		await fetch(response.data.uploadUrl, {
			method: "PUT",
			body: uploadBody,
			headers: {
				"Content-Type": file.type,
			},
		});

		return response.data.id;
	}

	public getImage(imageId: string): Promise<string | undefined> {
		if (this.concurrentImageCacheAccessor.has(imageId)) {
			return this.concurrentImageCacheAccessor.get(imageId)!;
		}

		const promise = new Promise<string | undefined>(async (resolve) => {
			const config = await this._configService.getConfig();
			const cache = await this._imageCache;

			let imageResponse = await cache.match(imageId);

			if (!imageResponse || !imageResponse.ok) {
				const tokenPromise = this._authService.getCurrentToken();

				imageResponse = await fetch(`${config.endpoints.api}/Image/${imageId}`, {
					headers: {
						Authorization: `Bearer ${await tokenPromise}`,
					},
				});

				if (imageResponse.ok) {
					await cache.put(imageId, imageResponse);
					// Re-read from the cache. We can't re-use imageResponse now, since its body will already be read
					imageResponse = await cache.match(imageId);
				}
			}

			if (imageResponse && imageResponse.ok) {
				const blob = await imageResponse.blob();
				const url = URL.createObjectURL(blob);

				console.log(url);
				resolve(url);

				return;
			}

			resolve(undefined);
		});

		this.concurrentImageCacheAccessor.set(imageId, promise);
		return promise;
	}
}
