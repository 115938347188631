import React, { PropsWithChildren, ReactNode } from "react";
import { Alignment, VerticalAlignment } from "Style/Alignment";
import styled, { css } from "styled-components";

export type StackedButtonGroupProps = PropsWithChildren<{
	className?: string;
	buttons: ReactNode;
	buttonAlignment?: Alignment;
	contentVerticalAlignment?: VerticalAlignment;
	contentPadding?: string;
}>;

export default function StackedButtonGroup(props: StackedButtonGroupProps) {
	const contentVerticalAlignment = props.contentVerticalAlignment ?? VerticalAlignment.Top;
	const buttonAlignment = props.buttonAlignment ?? Alignment.Horizontal;
	const contentPadding = props.contentPadding ?? "0";

	return (
		<StyledStackedButtonGroup className={`StackedButtonGroup ${props.className || ""}`}>
			<Scrollable $contentVerticalAlignment={contentVerticalAlignment} $contentPadding={contentPadding}>
				{props.children}
			</Scrollable>
			<ButtonGroup $buttonAlignment={buttonAlignment}>{props.buttons}</ButtonGroup>
		</StyledStackedButtonGroup>
	);
}

const Scrollable = styled.div<{ $contentVerticalAlignment: VerticalAlignment; $contentPadding: string }>`
	${({ $contentVerticalAlignment, $contentPadding }) => css`
		flex-grow: 1;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: ${$contentPadding};
		justify-content: ${$contentVerticalAlignment == VerticalAlignment.Top ? "flex-start" : "flex-end"};
	`}
`;

const ButtonGroup = styled.div<{ $buttonAlignment: Alignment }>`
	${({ $buttonAlignment, theme }) => css`
		flex-grow: 0;
		flex-shrink: 0;
		display: flex;
		flex-direction: ${$buttonAlignment == Alignment.Horizontal ? "row" : "column"};
		gap: ${theme.spacing.xxs};
	`}
`;

const StyledStackedButtonGroup = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
