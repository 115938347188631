import React, { FormEventHandler, forwardRef, useRef, useState } from "react";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Button from "Component/Global/Input/Button";
import ModelBoundTextInput from "Component/Global/Input/ModelBoundTextInput";
import Page from "Component/Global/Navigation/Page";
import { motion } from "framer-motion";
import LoginRequest, { LoginRequestValidation } from "Models/Request/LoginRequest";
import { Link } from "react-router-dom";
import AuthenticationService from "Services/AuthenticationService";
import UserStore from "Stores/UserStore";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import { PageRoutes } from "Utils/Navigation";
import { ValidationError } from "validate";
import { ReactComponent as Logo } from "Static/Image/logo.svg";

export interface LoginPageProps {
	className?: string;
}

const LoginPage = forwardRef<HTMLDivElement, LoginPageProps>((props, ref) => {
	const theme = useTheme();
	const authService = useDi(AuthenticationService);
	const userStore = useDi(UserStore);
	const [isLoading, setIsLoading] = useState(false);

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const loginModel = useRef<LoginRequest>({
		email: "",
		password: "",
	});

	const wrapAuthMethod = async (method: () => Promise<void>) => {
		try {
			setIsLoading(true);
			await method();
			userStore.isLoading = false;
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const signInWithEmail: FormEventHandler<HTMLFormElement> = async (e) => {
		try {
			e.preventDefault();

			const errors = LoginRequestValidation.validate(loginModel.current);
			if (errors.length > 0) {
				setValidationErrors(errors);
			} else {
				setIsLoading(true);
				await authService.signInWithUsernameAndPassword(loginModel.current.email, loginModel.current.password);
			}
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
			userStore.isLoading = false;
		}
	};

	return (
		<StyledLoginPage ref={ref} className={`LoginPage ${props.className || ""}`} tabBar={false}>
			{isLoading && <Loader size={Size.Large} layoutType={LoaderLayout.FullScreenOverlay} key="loading-login" />}
			<Login>
				<StyledLogo />
				<LoginWithProviders>
					<Button isResponsive onClick={() => wrapAuthMethod(authService.signInWithGoogle.bind(authService))}>
						Sign in with Google
					</Button>
					<Button isResponsive onClick={() => wrapAuthMethod(authService.signInWithFacebook.bind(authService))}>
						Sign in with Facebook
					</Button>
				</LoginWithProviders>
				<LoginWithEmail onSubmit={signInWithEmail} noValidate>
					<ModelBoundTextInput type="email" label="Email address" model={loginModel.current} modelProp="email" validationSchema={LoginRequestValidation} validationErrors={validationErrors} />
					<ModelBoundTextInput type="password" label="Password" model={loginModel.current} modelProp="password" validationSchema={LoginRequestValidation} validationErrors={validationErrors} />
					<Links>
						<StyledLink to={PageRoutes.Signup}>Sign up with email</StyledLink>
						<StyledLink to={PageRoutes.ForgotPassword}>Forgot password</StyledLink>
					</Links>
					<Button type="submit" isResponsive scheme={theme.schemes.accent2}>
						Enter Saga
					</Button>
				</LoginWithEmail>
			</Login>
		</StyledLoginPage>
	);
});
export default motion(LoginPage);

const Links = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 ${(props) => props.theme.spacing.xxs};
`;

const StyledLink = styled(Link)`
	${({ theme }) => css`
		text-align: center;
		margin-bottom: ${theme.spacing.sm};
		color: ${theme.palette.secondary};
		display: block;
		text-decoration: underline;
	`};
`;

const Login = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 1fr auto auto;
`;
const StyledLogo = styled(Logo)`
	${({ theme }) => css`
		margin: ${theme.spacing.lg} auto;
		text-align: center;
		align-self: center;
		width: 100%;
		max-width: calc(${theme.spacing.xxxl} * 3);
		height: calc(100% - calc(${theme.spacing.lg} * 2));
		path,
		rect {
			fill: ${theme.palette.secondary};
		}
	`};
`;

const LoginWithProviders = styled.div`
	margin-bottom: ${(props) => props.theme.spacing.lg} 0;
`;
const LoginWithEmail = styled.form``;
const StyledLoginPage = styled(Page)`
	.Button,
	.TextInput {
		margin: ${(props) => props.theme.spacing.xs} 0;
	}
`;
