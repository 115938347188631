import CharacterItem from "Component/Character/CharacterItem";
import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Search from "Component/Global/Interactive/Search";
import { SelectableListOnChangeHandler, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import Page from "Component/Global/Navigation/Page";
import React, { useCallback, useState } from "react";
import CharacterService from "Services/CharacterService";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";

export interface GroupMemberSearchProps {
	className?: string;
	existingMemberIds: string[];
	onGroupMemberSelected: (characterId: string) => void;
}

export default function GroupMemberSearch(props: GroupMemberSearchProps) {
	const characterService = useDi(CharacterService);
	const [isLoading, setIsLoading] = useState(false);

	const performSearch = useCallback(
		async (query: string) => {
			try {
				setIsLoading(true);
				const characters = await characterService.searchForList(query);
				// Filter out the existing characters
				const filteredCharacters = characters.filter((c) => props.existingMemberIds.indexOf(c.id) === -1);
				return characterService.asSelectableList(filteredCharacters, (c, i) => <CharacterItem character={c} isDisplay={filteredCharacters.length > 2 ? i === 0 : i < 2} />);
			} catch (err) {
				modalManager.showError(err as Error);
				return [];
			} finally {
				setIsLoading(false);
			}
		},
		[characterService, props.existingMemberIds],
	);

	const handleCloseClicked = () => {
		modalManager.hide("groups-add-member");
	};

	const handleCharacterSelected: SelectableListOnChangeHandler = (selectedCharacters) => {
		if (selectedCharacters.length) {
			props.onGroupMemberSelected(selectedCharacters[0].id);
			handleCloseClicked();
		}
	};

	return (
		<StyledGroupMemberSearch className={`GroupMemberSearch ${props.className || ""}`} titleBar={{ title: "Add to group", rightIcon: IconName.X, onRightIconClicked: handleCloseClicked }}>
			{isLoading && <Loader layoutType={LoaderLayout.FullScreenOverlay} />}
			<Search performSearch={performSearch} selectableListOptions={{ type: SelectableListType.SingleColumn, onChange: handleCharacterSelected }} />
		</StyledGroupMemberSearch>
	);
}

const StyledGroupMemberSearch = styled(Page)``;
