import React from "react";
import ReactDOM from "react-dom";
import App from "./Component/App";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "Style/GlobalStyles";
import { ThemeProvider } from "styled-components";
import Theme from "Style/Theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={Theme}>
			<GlobalStyles />
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root"),
);

serviceWorkerRegistration.register();
