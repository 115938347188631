import React, { useEffect, useState } from "react";
import Gender, { GenderDisplayName } from "Enums/Gender";
import { observer } from "mobx-react";
import CharacterPageStore from "Stores/CharacterPageStore";
import { Alignment } from "Style/Alignment";
import styled, { css } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import useBreakpoints, { BreakpointResult } from "Hooks/UseBreakpoints";
import SelectableList, { SelectableListItem, SelectableListType } from "Component/Global/Interactive/SelectableList/SelectableList";
import InfoBlock from "./InfoBlock";
import modalManager from "Utils/ModalManager";
import TraitFilter from "Component/Generator/TraitFilter";
import { ModalType } from "Component/Global/Interactive/Modal/Modal";
import { Culture } from "Models/Culture";
import { Race } from "Models/Race";
import TraitsService from "Services/TraitsService";
import AllFilterDataResponse from "Models/Response/AllFilterDataResponse";
import { Profession } from "Models/Profession";

export interface CharacterStatBlockProps {
	className?: string;
	alignment?: Alignment;
}

export default observer(CharacterStatBlock);
function CharacterStatBlock(props: CharacterStatBlockProps) {
	const store = useDi(CharacterPageStore);

	const traitsService = useDi(TraitsService);

	const breakpoints = useBreakpoints(store.detailsEl);

	const [isLoading, setIsLoading] = useState(false);
	const [filterData, setFilterData] = useState<AllFilterDataResponse>();
	const [selectedItems, setSelectedItems] = useState<SelectableListItem[]>([]);

	useEffect(() => {
		setIsLoading(true);
		traitsService
			.getAllFilterData()
			.then((data) => {
				setFilterData(data);
			})
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => {
				setIsLoading(false);
			});
	}, [traitsService]);

	const resetSelectedItems = () => {
		setSelectedItems([]);
	};

	const character = store.character;
	if (!character) {
		return null;
	}

	const handleGenderChanged = (gender: Gender) => {
		store.character = {
			...character,
			gender,
		};
		resetSelectedItems();
	};

	const handleRaceChanged = (race?: Partial<Race>) => {
		if (race) {
			// If there's only a single culture for this race, automatically select it
			const raceCultures = filterData?.getCultures.nodes.filter((c) => c.raceId === race.id) ?? [];

			store.character = {
				...character,
				raceId: race.id,
				race: race,
				cultureId: raceCultures.length === 1 ? raceCultures[0].id : null,
				culture: raceCultures.length === 1 ? raceCultures[0] : undefined,
			};
		} else {
			store.character = {
				...character,
				raceId: null,
				race: undefined,
				cultureId: null,
				culture: undefined,
			};
		}

		resetSelectedItems();
	};

	const handleCultureChanged = (culture?: Partial<Culture>) => {
		store.character = {
			...character,
			cultureId: culture?.id,
			culture: culture,
		};
		resetSelectedItems();
	};

	const handleProfessionChanged = (profession?: Partial<Profession>) => {
		store.character = {
			...character,
			professionId: profession?.id,
			profession: profession,
		};
		resetSelectedItems();
	};

	const items: SelectableListItem[] = store.isEditing
		? [
				{
					id: "race",
					isSelectable: true,
					children: <InfoBlock label="Race" value={character.race?.name} />,
					onClick: () => {
						modalManager.show({
							id: "character-edit-race",
							type: ModalType.FullScreen,
							children: (
								<TraitFilter
									isLoading={isLoading}
									modalId="character-edit-race"
									title="Select race"
									multiSelect={false}
									showSelectAll={false}
									selectedItemIds={[character.raceId ?? character.race?.id ?? ""]}
									onItemsSelected={(items) => {
										if (items.length > 0) {
											const raceId = items[0].id;
											const race = filterData?.getRaces?.nodes?.find((r) => r.id === raceId);
											handleRaceChanged(race);
										} else {
											handleRaceChanged(undefined);
										}
									}}
									items={
										filterData?.getRaces.nodes.map((race) => {
											return {
												id: race.id!,
												children: race.name,
											};
										}) ?? []
									}
								/>
							),
						});
					},
				},
				{
					id: "gender",
					isSelectable: true,
					children: <InfoBlock label="Gender" value={character.gender ? GenderDisplayName[character.gender] : "-"} />,
					onClick: () => {
						modalManager.show({
							id: "character-edit-gender",
							type: ModalType.FullScreen,
							children: (
								<TraitFilter
									isLoading={isLoading}
									modalId="character-edit-gender"
									title="Select gender"
									multiSelect={false}
									showSelectAll={false}
									selectedItemIds={[character.gender!]}
									onItemsSelected={(items) => {
										if (items.length > 0) {
											handleGenderChanged(items[0].id as Gender);
										}
									}}
									items={
										Object.keys(Gender).map((gender) => {
											return {
												id: gender,
												children: GenderDisplayName[gender as Gender],
											};
										}) ?? []
									}
								/>
							),
						});
					},
				},
				{
					id: "culture",
					isSelectable: true,
					children: <InfoBlock label="Culture" value={character.culture?.name} />,
					onClick: () => {
						modalManager.show({
							id: "character-edit-culture",
							type: ModalType.FullScreen,
							children: (
								<TraitFilter
									isLoading={isLoading}
									modalId="character-edit-culture"
									title="Select culture"
									multiSelect={false}
									showSelectAll={false}
									selectedItemIds={[character.cultureId ?? character.culture?.id ?? ""]}
									onItemsSelected={(items) => {
										if (items.length > 0) {
											const cultureId = items[0].id;
											const culture = filterData?.getCultures?.nodes?.find((c) => c.id === cultureId);
											handleCultureChanged(culture);
										} else {
											handleCultureChanged(undefined);
										}
									}}
									items={
										filterData?.getCultures?.nodes
											.filter((culture) => {
												const raceId = character.raceId ?? character.race?.id;
												if (raceId) {
													return culture.raceId === raceId;
												} else {
													return true;
												}
											})
											.map((culture) => {
												return {
													id: culture.id!,
													children: culture.name,
												};
											}) ?? []
									}
								/>
							),
						});
					},
				},
				{
					id: "profession",
					isSelectable: true,
					children: <InfoBlock label="Profession" value={character.profession?.name} />,
					onClick: () => {
						modalManager.show({
							id: "character-edit-profession",
							type: ModalType.FullScreen,
							children: (
								<TraitFilter
									isLoading={isLoading}
									modalId="character-edit-profession"
									title="Select profession"
									multiSelect={false}
									showSelectAll={false}
									selectedItemIds={[character.professionId ?? character.profession?.id ?? ""]}
									onItemsSelected={(items) => {
										if (items.length > 0) {
											const professionId = items[0].id;
											const profession = filterData?.getProfessions?.nodes?.find((p) => p.id === professionId);
											handleProfessionChanged(profession);
										} else {
											handleProfessionChanged(undefined);
										}
									}}
									items={
										filterData?.getProfessions?.nodes.map((profession) => {
											return {
												id: profession.id!,
												children: profession.name,
											};
										}) ?? []
									}
								/>
							),
						});
					},
				},
		  ]
		: [];

	const content = store.isEditing ? (
		<EditView type={SelectableListType.MultiColumns} items={items} selectedItems={selectedItems} onChange={setSelectedItems} />
	) : (
		<ReadView $breakpoints={breakpoints}>
			<Label>Race</Label>
			<Value>{character.race?.name}</Value>
			<Spacer />
			<Label>Gender</Label>
			<Value>{GenderDisplayName[character.gender]}</Value>
			<Label>Culture</Label>
			<Value>{character.culture?.name}</Value>
			<Spacer />
			<Label>Profession</Label>
			<Value>{character.profession?.name}</Value>
		</ReadView>
	);

	return <StyledCharacterStatBlock className={`CharacterStatBlock ${props.className || ""}`}>{content}</StyledCharacterStatBlock>;
}

const EditView = styled(SelectableList)``;

const Spacer = styled.div``;
const Label = styled.div`
	${({ theme }) => css`
		font-weight: ${theme.typography.fontWeight.bold};
	`}
`;
const Value = styled.div``;

const ReadView = styled.div<{ $breakpoints?: BreakpointResult }>`
	${({ theme, $breakpoints }) => css`
		display: grid;
		grid-template-columns: auto auto ${theme.spacing.sm} auto auto;
		gap: ${theme.spacing.sm};
		font-size: ${theme.typography.fontSizes.small};
		margin: ${theme.spacing.sm} auto;
		/* max-width: max-content; */

		${($breakpoints?.smallerThan?.xxxs || $breakpoints?.largerThan?.xs) &&
		css`
			grid-template-columns: auto auto;
			margin: 0;

			${Spacer} {
				display: none;
			}
		`}
	`};
`;

const StyledCharacterStatBlock = styled.div``;
