import React, { useState } from "react";
import Icon, { IconName } from "Component/Global/Content/Icon";
import styled, { css, useTheme } from "styled-components";
import TextInput, { TextInputProps } from "./TextInput";
import useDebouncedCallback from "Hooks/UseDebouncedCallback";

export interface SearchInputProps extends TextInputProps {
	onSearchRequested?: (query?: string) => void;
}

export default function SearchInput(props: SearchInputProps) {
	const { onSearchRequested } = props;
	const theme = useTheme();
	const placeholder = props.placeholder ?? "Search";

	const [value, setValue] = useState<string>("");

	const [setDebouncedValue, cancelDebounce] = useDebouncedCallback(onSearchRequested);

	const handleValueChanged = (val: string) => {
		const value = val.trim();

		cancelDebounce();
		setValue(value);

		if (value === "") {
			onSearchRequested?.(undefined);
		} else {
			setDebouncedValue(value);
		}

		props.onChange?.(val);
	};

	const handleClearSearchClicked = () => {
		if (value !== "") {
			cancelDebounce();
			setValue("");
			onSearchRequested?.(undefined);
		}
	};

	const handleEnterPressed = () => {
		cancelDebounce();
		onSearchRequested?.(value);
	};

	return (
		<StyledSearchInput className={`SearchInput ${props.className || ""}`}>
			<StyledTextInput {...props} placeholder={placeholder} value={value} onChange={handleValueChanged} onEnterPressed={handleEnterPressed}>
				<SearchIcon name={value === "" ? IconName.Search : IconName.X} color={theme.palette.shade1} onClick={handleClearSearchClicked} />
			</StyledTextInput>
		</StyledSearchInput>
	);
}

const StyledSearchInput = styled.div``;
const StyledTextInput = styled(TextInput)`
	${({ theme }) => css`
		position: relative;

		> .Input {
			padding-right: calc(${theme.spacing.md} + ${theme.spacing.lg}); // existing padding, plus icon width
		}
	`}
`;

const SearchIcon = styled(Icon)`
	${({ theme }) => css`
		position: absolute;
		right: ${theme.spacing.sm};
		top: ${theme.spacing.sm};
		cursor: pointer;
	`}
`;
