import { ApolloError, ServerError } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import axios from "axios";

export default class ErrorUtil {
	public static getErrorMessage(error: Error) {
		debugger;
		if (error instanceof ApolloError) {
			let graphQLErrors = error.graphQLErrors;

			if ((error.networkError as ServerError)?.result?.errors) {
				graphQLErrors = (error.networkError as ServerError).result.errors as GraphQLErrors;
			}

			if (graphQLErrors.length) {
				return graphQLErrors.map((e) => `[GraphQL error at ${e.path?.join(".")}]: ${e.message}`).join(" ");
			} else {
				return error.message;
			}
		} else if (axios.isAxiosError(error)) {
			const aspNetProblem = error.response?.data as AspNetProblem | undefined;
			if (aspNetProblem?.title) {
				return `[${aspNetProblem.status} - ${aspNetProblem.type}] ${aspNetProblem.title}`;
			} else {
				return error.message;
			}
		}
		return error.message;
	}
}

export interface AspNetProblem {
	detail?: string;
	title: string;
	status: number;
	type: string;
	instance: string;
}
