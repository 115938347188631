import ArrayUtil, { Keyable } from "./ArrayUtil";

export {};

declare global {
	interface Array<T> {
		toRecord<K>(this: K[], idSelector: keyof K | ((item: K) => Keyable)): Record<keyof T | Keyable, K>;
	}
}

Array.prototype.toRecord = function (idSelector) {
	return ArrayUtil.toRecord(this, idSelector);
};
