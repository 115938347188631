export enum PageRoutes {
	Home = "/",
	Login = "/login",
	Logout = "/logout",
	Signup = "/signup",
	ForgotPassword = "/forgot-password",
	Onboarding = "/onboarding",
	Generator = "/generator",
	Character = "/characters",
	Group = "/groups",
	Settings = "/settings",
	Help = "/help",
}

export enum NestedRoutes_Onboarding {
	Welcome = "/onboarding/welcome",
	HowItWorks = "/onboarding/how-it-works",
	Generator = "/onboarding/generator",
}

export enum NestedRoutes_Groups {
	New = "/groups/new",
	Search = "/groups/search",
	Edit = "/groups/edit/:id",
	Details = "/groups/details/:id",
}

export enum NestedRoutes_Characters {
	New = "/characters/new",
	Search = "/characters/search",
	Edit = "/characters/edit/:id",
	Details = "/characters/details/:id",
}
