import { Tween, Spring } from "framer-motion";

export const DefaultTransition: Tween = {
	ease: "easeOut",
	duration: 0.2,
};

export const DefaultSpringTransition: Spring = {
	type: "spring",
	bounce: 0.1,
	delay: 0.24,
};

export const SlideUpInSlideDownOutVariant = {
	bumpIn: {
		y: "100%",
	},
	stable: {
		y: "0",
	},
	bumpOut: {
		y: "100%",
	},
};
