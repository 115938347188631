import { motion } from "framer-motion";
import React from "react";
import { DefaultTransition } from "Style/Animation";
import styled, { css, useTheme } from "styled-components";

export interface PivotMenuItemProps {
	className?: string;
	text: string;
	id: string;
	isSelected?: boolean;
	onClick?: () => void;
}

export default function PivotMenuItem(props: PivotMenuItemProps) {
	const theme = useTheme();

	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		props.onClick?.();
	};

	const color = props.isSelected ? theme.palette.accent2 : theme.palette.shade1;

	return (
		<StyledPivotMenuItem initial={false} animate={{ color }} transition={DefaultTransition} className={`PivotMenuItem ${props.className || ""}`} data-id={props.id} href="#" onClick={handleClick}>
			{props.text}
			{props.isSelected && <Indicator layoutId="pivot-menu-indicator" transition={DefaultTransition} />}
		</StyledPivotMenuItem>
	);
}

const Indicator = styled(motion.div)`
	${({ theme }) => css`
		position: absolute;
		display: block;
		width: ${theme.spacing.lg};
		height: ${theme.spacing.xxs};
		background: ${theme.palette.accent2};
		bottom: -${theme.spacing.xxs};
		left: 0;
	`}
`;

const StyledPivotMenuItem = styled(motion.a)`
	${({ theme }) => css`
		font-size: ${theme.typography.fontSizes.xLarge};
		font-weight: ${theme.typography.fontWeight.bold};
		text-decoration: none;
		position: relative;
	`}
`;
