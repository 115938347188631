import { makeAutoObservable } from "mobx";
import { Character } from "Models/Character";

export type ColumnLayout = {
	list?: boolean;
	cover?: boolean;
	details?: boolean | "expanded";
};

export default class CharacterPageStore {
	private _isEditing = false;
	private _character?: Character;
	private _columnLayout: ColumnLayout = {
		list: true,
	};
	private _detailsEl: HTMLDivElement | null = null;
	private _listEl: HTMLDivElement | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	get character() {
		return this._character;
	}

	set character(character: Character | undefined) {
		this._character = character;
	}

	get columnLayout() {
		return this._columnLayout;
	}

	set columnLayout(layout: ColumnLayout) {
		this._columnLayout = layout;
	}

	public patchColumnLayout(patch: Partial<ColumnLayout>) {
		this._columnLayout = { ...this._columnLayout, ...patch };
	}

	get isEditing() {
		return this._isEditing;
	}

	set isEditing(isEditing: boolean) {
		this._isEditing = isEditing;
	}

	get detailsEl() {
		return this._detailsEl;
	}

	set detailsEl(el: HTMLDivElement | null) {
		if (this._detailsEl !== el) {
			this._detailsEl = el;
		}
	}

	get listEl() {
		return this._listEl;
	}

	set listEl(el: HTMLDivElement | null) {
		if (this._listEl !== el) {
			this._listEl = el;
		}
	}
}
