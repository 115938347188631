import React, { useEffect, useMemo, useRef, useState } from "react";
import CategoryScroller, { Category, CategoryScrollerOnChangeHandler, createAlphabeticalCategories, createDateCategories } from "Component/Global/Content/CategoryScroller";
import Icon, { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import VirtualScroll, { VirtualScrollItem, VirtualScrollOnItemScrollHandler } from "Component/Global/Content/VirtualScroll";
import Button from "Component/Global/Input/Button";
import SearchInput from "Component/Global/Input/SearchInput";
import { observer } from "mobx-react";
import { Character } from "Models/Character";
import CharacterService from "Services/CharacterService";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import modalManager from "Utils/ModalManager";
import CharacterVirtualListItem from "./CharacterVirtualListItem";
import useBreakpoints, { BreakpointResult } from "Hooks/UseBreakpoints";
import CharacterPageStore from "Stores/CharacterPageStore";
import { QueryOrdering } from "GraphQL/GraphQLQuery";
import GroupService from "Services/GroupService";
import { Group } from "Models/Group";
import useLoader from "Hooks/UseLoader";

const LOADER_GROUPS = "groups";
const LOADER_CHARACTERS = "characters";

type GroupByProps = {
	ordering: QueryOrdering<Character>;
	categories: (characters: Character[], groups: Group[]) => Category<number, Character>[];
	categoryHeader: (character: Character, category?: Category<number, Character>) => string;
	displayCategoriesVertical?: boolean;
};

enum GroupBySelection {
	Name = "Name",
	Date = "Date",
	Group = "Group",
}

const GroupByMap: Record<GroupBySelection, GroupByProps> = {
	[GroupBySelection.Name]: {
		ordering: [{ name: "ASC" }],
		categories: (characters) => createAlphabeticalCategories(characters, (c) => c.name),
		categoryHeader: (c) => c.name[0].toUpperCase(),
	},
	[GroupBySelection.Date]: {
		ordering: [{ modified: "DESC" }],
		categories: (characters) => createDateCategories(characters, (c) => c.modified),
		categoryHeader: (_, c) => c?.name ?? "Unknown",
		displayCategoriesVertical: true,
	},
	[GroupBySelection.Group]: {
		ordering: [{ name: "ASC" }],
		categories: (characters, groups) => createGroupCategories(characters, groups),
		categoryHeader: (_, c) => c?.name ?? "Unknown",
		displayCategoriesVertical: true,
	},
};

enum DisplayAsSelection {
	Grid = "Grid",
	List = "List",
}

export interface CharacterVirtualListProps {
	className?: string;
	character?: Character;
	onCharacterSelected?: (character?: Character) => void;
}

export default observer(CharacterVirtualList);
function CharacterVirtualList(props: CharacterVirtualListProps) {
	const theme = useTheme();
	const store = useDi(CharacterPageStore);
	const characterService = useDi(CharacterService);
	const groupService = useDi(GroupService);

	const [groupBySelection, setGroupBySelection] = useState<GroupBySelection>(GroupBySelection.Name);
	const [displayAsSelection, setDisplayAsSelection] = useState<DisplayAsSelection>(DisplayAsSelection.List);

	const { addLoader, removeLoader, isLoading } = useLoader();

	const [groups, setGroups] = useState<Group[]>([]);
	const [characters, setCharacters] = useState<Character[]>([]);

	const [searchQuery, setSearchQuery] = useState<string>();

	const [virtualScrollIndex, setVirtualScrollIndex] = useState<number>();
	const [currentCategory, setCurrentCategory] = useState<Category<number, Character>>();

	useEffect(() => {
		addLoader(LOADER_GROUPS);
		groupService
			.fetchForList([{ name: "ASC" }])
			.then(setGroups)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => removeLoader(LOADER_GROUPS));
	}, [groupService, addLoader, removeLoader]);

	useEffect(() => {
		addLoader(LOADER_CHARACTERS);
		let promise: Promise<Character[]>;

		if (searchQuery) {
			promise = characterService.searchForList(searchQuery, GroupByMap[groupBySelection].ordering, true);
		} else {
			promise = characterService.fetchForList(GroupByMap[groupBySelection].ordering, true);
		}

		promise
			.then(setCharacters)
			.catch((err) => modalManager.showError(err as Error))
			.finally(() => removeLoader(LOADER_CHARACTERS));
	}, [characterService, groupBySelection, addLoader, removeLoader, searchQuery]);

	useEffect(() => {
		setVirtualScrollIndex(undefined);
		setCurrentCategory(undefined);
	}, [groupBySelection]);

	const scrollCategories: Category<number, Character>[] = useMemo(() => GroupByMap[groupBySelection].categories(characters, groups), [characters, groupBySelection, groups]);

	const scrollItems = useMemo(() => {
		return scrollCategories.flatMap((category) => {
			if (!category.items) return [];

			return category.items.map((character, i) => ({
				character,
				category,
				i,
			}));
		});
	}, [scrollCategories]);

	const virtualScrollItems: VirtualScrollItem[] = useMemo(
		() =>
			scrollItems.map((item) => {
				return () => (
					<>
						{item.i === 0 && <h2>{GroupByMap[groupBySelection].categoryHeader(item.character, item.category)}</h2>}
						<CharacterVirtualListItem character={item.character} isSelected={store.character?.id === item.character.id} />
					</>
				);
			}),
		[groupBySelection, scrollItems, store.character?.id],
	);

	const handleScrollerChanged: CategoryScrollerOnChangeHandler<number, Character> = (item) => {
		setCurrentCategory(item);
		setVirtualScrollIndex(scrollItems.findIndex((x) => x.category === item));
	};

	const handleVirtualListItemScrolled: VirtualScrollOnItemScrollHandler = (index) => {
		const currentScrollItem = scrollItems[index];
		setCurrentCategory(currentScrollItem.category);
	};

	const elRef = useRef<HTMLDivElement>(null);
	store.listEl = elRef.current;
	const breakpoints = useBreakpoints(elRef.current);

	return (
		<StyledCharacterVirtualList className={`CharacterVirtualList ${props.className || ""}`} ref={elRef} $breakpoints={breakpoints}>
			<Title>Characters</Title>
			<Controls>
				<GroupByButtons>
					<span>Group by</span>
					<RoundButton size={Size.Small} scheme={groupBySelection == GroupBySelection.Name ? theme.schemes.accent2 : undefined} onClick={() => setGroupBySelection(GroupBySelection.Name)}>
						<span>Name</span>
					</RoundButton>
					<RoundButton size={Size.Small} scheme={groupBySelection == GroupBySelection.Date ? theme.schemes.accent2 : undefined} onClick={() => setGroupBySelection(GroupBySelection.Date)}>
						<span>Date</span>
					</RoundButton>
					<RoundButton size={Size.Small} scheme={groupBySelection == GroupBySelection.Group ? theme.schemes.accent2 : undefined} onClick={() => setGroupBySelection(GroupBySelection.Group)}>
						<span>Group</span>
					</RoundButton>
				</GroupByButtons>
				<GroupByMenu iconOnly size={Size.Small}>
					<Icon name={IconName.Filter} size={theme.spacing.md} />
				</GroupByMenu>
				<Search onSearchRequested={setSearchQuery} />
				<RoundButton iconOnly size={Size.Small} scheme={displayAsSelection == DisplayAsSelection.List ? theme.schemes.accent2 : undefined} onClick={() => setDisplayAsSelection(DisplayAsSelection.List)}>
					<Icon name={IconName.List} size={theme.spacing.md} />
				</RoundButton>
				<RoundButton iconOnly size={Size.Small} scheme={displayAsSelection == DisplayAsSelection.Grid ? theme.schemes.accent2 : undefined} onClick={() => setDisplayAsSelection(DisplayAsSelection.Grid)}>
					<Icon name={IconName.Grid} size={theme.spacing.md} />
				</RoundButton>
			</Controls>
			{isLoading ? (
				<Loader layoutType={LoaderLayout.FullScreenOverlay} />
			) : (
				<CategoryScroller items={scrollCategories} selectedItem={currentCategory} onChange={handleScrollerChanged} vertical={GroupByMap[groupBySelection].displayCategoriesVertical}>
					<StyledVirtualScroll items={virtualScrollItems} scrollToIndex={virtualScrollIndex} itemHeight={parseInt(theme.spacing.xxxl)} onItemScroll={handleVirtualListItemScrolled} />
				</CategoryScroller>
			)}
		</StyledCharacterVirtualList>
	);
}

const StyledVirtualScroll = styled(VirtualScroll)``;

const Title = styled.h1`
	text-transform: uppercase;
	font-size: ${(props) => props.theme.typography.fontSizes.xxxLarge};
`;

const RoundButton = styled(Button)`
	border-radius: 9999px;
	height: 46px;

	&.iconOnly {
		width: 46px;
	}
`;

const GroupByMenu = styled(RoundButton)``;

const GroupByButtons = styled.div`
	display: flex;
	justify-content: end;
	gap: ${(props) => props.theme.spacing.sm};
	align-content: stretch;

	> span {
		line-height: 46px;
	}
`;

const Search = styled(SearchInput)`
	> input {
		border-radius: 9999px;

		&::placeholder {
			color: ${(props) => props.theme.palette.secondary};
		}
	}
`;

const Controls = styled.div`
	display: flex;
	justify-content: end;
	gap: ${(props) => props.theme.spacing.sm};
	align-content: stretch;
`;

const StyledCharacterVirtualList = styled.div<{ $breakpoints?: BreakpointResult }>`
	${({ theme, $breakpoints }) => css`
		height: 100%;
		display: grid;
		grid-template-rows: auto auto 1fr;
		overflow-y: hidden;
		box-sizing: border-box;
		padding: ${theme.spacing.md};
		gap: ${theme.spacing.md};
		padding-bottom: 0;

		${$breakpoints?.atMost?.xs &&
		css`
			${Title} {
				font-size: ${theme.typography.fontSizes.xxLarge};
			}
		`}

		${$breakpoints?.smallerThan?.sm &&
		css`
			${GroupByButtons} {
				display: none;
			}
		`}
		
		${$breakpoints?.atLeast?.sm &&
		css`
			${GroupByMenu} {
				display: none;
			}
		`}
	`}
`;

function createGroupCategories(characters: Character[], groups: Group[]): Category<number, Character>[] {
	const groupsById = groups.toRecord("id");
	const charactersWithoutGroups: Character[] = [];

	characters.forEach((c) => {
		if (c.groups && c.groups.length) {
			c.groups?.forEach((g) => {
				if (g.id) {
					const group = groupsById[g.id];
					if (group != undefined) {
						if (group.members == undefined) {
							group.members = [];
						}
						if (group.members.findIndex((x) => x.id === c.id) < 0) {
							group.members.push(c);
						}
					}
				}
			});
		} else {
			if (charactersWithoutGroups.findIndex((x) => x.id === c.id) < 0) {
				charactersWithoutGroups.push(c);
			}
		}
	});

	const groupCategories = groups.map((g) => {
		return {
			name: g.name,
			items: g.members,
			value: groups.indexOf(g),
			disabled: g.members === undefined || g.members.length == 0,
		} as Category<number, Character>;
	});

	return [
		...groupCategories,
		{
			name: "No Group",
			value: -1,
			disabled: charactersWithoutGroups.length == 0,
			items: charactersWithoutGroups,
		} as Category<number, Character>,
	];
}
