import React from "react";
import { Textfit } from "react-textfit";
import styled, { css, useTheme } from "styled-components";

export interface InfoBlockProps {
	className?: string;
	label: string;
	value?: string | number;
}

export default function InfoBlock(props: InfoBlockProps) {
	const theme = useTheme();

	const minSize = parseInt(theme.typography.fontSizes.xxxSmall);
	const maxLabelSize = parseInt(theme.typography.fontSizes.medium);
	const maxValueSize = parseInt(theme.typography.fontSizes.xLarge);

	return (
		<StyledInfoBlock className={`InfoBlock ${props.className || ""}`}>
			<Label>
				<Textfit mode="single" min={minSize} max={maxLabelSize}>
					{props.label}
				</Textfit>
			</Label>
			<Value>
				<Textfit mode="single" min={minSize} max={maxValueSize}>
					{props.value || "-"}
				</Textfit>
			</Value>
		</StyledInfoBlock>
	);
}

const Label = styled.span`
	${({ theme }) => css`
		display: block;
		color: ${theme.palette.shade1};
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.medium};
	`}
`;
const Value = styled.span`
	${({ theme }) => css`
		display: block;
		font-weight: ${theme.typography.fontWeight.bold};
		font-size: ${theme.typography.fontSizes.xLarge};
	`}
`;

const StyledInfoBlock = styled.div``;
