import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	AuthProvider,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	Auth,
	CompleteFn,
	ErrorFn,
	NextOrObserver,
	User as FirebaseUser,
	Unsubscribe,
	browserLocalPersistence,
	FacebookAuthProvider,
	updateProfile,
} from "firebase/auth";
import { User } from "Models/User";
import AxiosClient from "Utils/AxiosClient";
import { resolveDi } from "Utils/DependencyInjection";
import FirebaseApp from "Utils/FirebaseApp";
import modalManager from "Utils/ModalManager";

/**
 * AuthenticationService
 * @singleton
 */
export default class AuthenticationService {
	private readonly _firebaseAuth: Auth;

	constructor() {
		this._firebaseAuth = getAuth(FirebaseApp);
		this._firebaseAuth.setPersistence(browserLocalPersistence);
	}

	public getCurrentToken() {
		return this._firebaseAuth.currentUser?.getIdToken();
	}

	public async fetchCurrentUser() {
		try {
			const axios = await resolveDi(AxiosClient).instance();
			const response = await axios.get<User>("auth/me");
			return response.data;
		} catch (err) {
			modalManager.showError(err as Error);
		}
		return undefined;
	}

	public onAuthStateChanged(nextOrObserver: NextOrObserver<FirebaseUser | null>, error?: ErrorFn | undefined, completed?: CompleteFn | undefined): Unsubscribe {
		return this._firebaseAuth.onAuthStateChanged(nextOrObserver, error, completed);
	}

	public onIdTokenChanged(nextOrObserver: NextOrObserver<FirebaseUser | null>, error?: ErrorFn | undefined, completed?: CompleteFn | undefined): Unsubscribe {
		return this._firebaseAuth.onIdTokenChanged(nextOrObserver, error, completed);
	}

	public async signOut() {
		return this._firebaseAuth.signOut();
	}

	private async signInWithProvider(provider: AuthProvider) {
		try {
			return await signInWithPopup(this._firebaseAuth, provider);
		} catch (err) {
			modalManager.showError(err as Error);
		}
	}
	public async signInWithGoogle() {
		const provider = new GoogleAuthProvider();
		await this.signInWithProvider(provider);
	}

	public async signInWithFacebook() {
		const provider = new FacebookAuthProvider();
		await this.signInWithProvider(provider);
	}

	public async createUserWithUsernameAndPassword(email: string, password: string, displayName: string) {
		// Create the user. This also logs the user in, but they don't have a displayName, which is a required field for us
		const user = await createUserWithEmailAndPassword(this._firebaseAuth, email, password);
		// Update the displayName
		await updateProfile(user.user, {
			displayName: displayName,
		});
		// Force another login, which will refresh the ID token to include the displayName
		await this.signInWithUsernameAndPassword(email, password);
	}

	public async signInWithUsernameAndPassword(email: string, password: string) {
		await signInWithEmailAndPassword(this._firebaseAuth, email, password);
	}

	public async sendPasswordResetEmail(email: string) {
		await sendPasswordResetEmail(this._firebaseAuth, email, {
			url: window.location.href,
			handleCodeInApp: true,
		});
	}
}
