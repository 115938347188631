import ApolloClient from "Utils/ApolloClient";
import { getUserById } from "GraphQL/Queries/UserQueries";
import { User } from "Models/User";
import { updateUser } from "GraphQL/Mutations/UserMutations";

export default class UserService {
	private readonly _apolloClient: ApolloClient;

	constructor(apolloClient: ApolloClient) {
		this._apolloClient = apolloClient;
	}

	public async fetchUser(id: string) {
		const response = await getUserById(this._apolloClient.instance(), _FIELDS_USER, id);
		return response.data?.getUserById;
	}

	public async saveUser(user: Partial<User>, fieldsToUpdate: Array<keyof User> = []) {
		const response = await updateUser(this._apolloClient.instance(), _FIELDS_USER, user, fieldsToUpdate);
		return response.data?.updateUser.user;
	}
}

const _FIELDS_USER = `
	id
	uid
	displayName
	email
`;
