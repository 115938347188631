import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IconName } from "Component/Global/Content/Icon";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";
import Button from "Component/Global/Input/Button";
import EditableText from "Component/Global/Input/EditableText";
import { IMAGE_HEIGHT_COLLAPSED } from "Component/Global/Interactive/CoverArt";
import Page from "Component/Global/Navigation/Page";
import fileDialog from "file-dialog";
import { motion, MotionStyle, PanInfo, useMotionValue } from "framer-motion";
import { ModelValidationErrors } from "Models/AbstractModel";
import { Character, validateCharacter } from "Models/Character";
import { useNavigate, useParams } from "react-router-dom";
import CharacterService from "Services/CharacterService";
import UploadService from "Services/UploadService";
import { Size } from "Style/Sizing";
import styled, { css, useTheme } from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import ModelUtils from "Utils/ModelUtils";
import modalManager from "Utils/ModalManager";

export interface CharacterEditPageProps {
	className?: string;
}

export default function CharacterEditPage(props: CharacterEditPageProps) {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();

	const uploadService = useDi(UploadService);
	const characterService = useDi(CharacterService);

	const [isLoading, setIsLoading] = useState(false);

	const characterImageRef = useRef<File | null>(null);

	const originalYPosition = useRef("50%");
	const imageYPosition = useMotionValue("50%");

	const [character, setCharacter] = useState<Partial<Character>>({});

	const [characterImage, setCharacterImage] = useState<string>();

	const [validationErrors, setValidationErrors] = useState<ModelValidationErrors<Character> | undefined>(validateCharacter({}));

	const characterId = params.id;

	const validate = useCallback(() => {
		setValidationErrors(validateCharacter(ModelUtils.pick(character!, ["name"])));
	}, [character]);

	useEffect(() => {
		if (characterId) {
			setIsLoading(true);
			characterService
				.fetchDetails(characterId)
				.then(setCharacter)
				.catch((err) => modalManager.showError(err as Error))
				.finally(() => setIsLoading(false));
		}
	}, [characterService, characterId]);

	useEffect(() => {
		characterService
			.getCoverImageSrc(character)
			.then(setCharacterImage)
			.catch((err) => modalManager.showError(err as Error));
		validate();
	}, [character, characterService, validate]);

	const characterImageEl = useMemo(() => {
		if (characterImage) {
			const imageEl = new Image();
			imageEl.src = characterImage;
			return imageEl;
		} else {
			return undefined;
		}
	}, [characterImage]);

	const handleOnAddImage = async () => {
		const images = await fileDialog({ accept: "image/*" });
		if (images.length) {
			characterImageRef.current = images[0];
			setCharacterImage(URL.createObjectURL(images[0]));
		} else {
			setCharacterImage("");
		}
	};

	const handleSaveCharacter = async () => {
		setIsLoading(true);

		try {
			let imageId: string | undefined = undefined;

			if (characterImageRef.current) {
				imageId = await uploadService.uploadImage(characterImageRef.current);
			}

			character.images = imageId
				? [
						{
							imageId: imageId,
							isCover: true,
							positionX: 0,
							positionY: parseInt(imageYPosition.get()),
						},
				  ]
				: character.images ?? [];

			const fieldsToUpdate: (keyof Character)[] = ["name", "title", "images"];
			const savedCharacter = await characterService.save(ModelUtils.pick(character, ["id", ...fieldsToUpdate]), fieldsToUpdate);

			if (imageId) {
				await characterService.setCharacterCoverImage(savedCharacter.id, imageId);
			}
			setTimeout(() => {
				navigate(-1);
			}, 0);
		} catch (err) {
			modalManager.showError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePanStart: PanEventHandler = () => {
		originalYPosition.current = imageYPosition.get();
	};

	const handleImagePan: PanEventHandler = (_, info) => {
		const percentageMovementRelativeToImageHeight = (info.offset.y * 100) / (characterImageEl?.naturalHeight || IMAGE_HEIGHT_COLLAPSED);
		let newYPosition = parseInt(originalYPosition.current) - percentageMovementRelativeToImageHeight;
		if (newYPosition > 100) {
			newYPosition = 100;
		} else if (newYPosition < 0) {
			newYPosition = 0;
		}
		imageYPosition.set(newYPosition + "%");
	};

	const imageStyle: MotionStyle = {
		backgroundImage: `url(${characterImage})`,
		backgroundPositionY: imageYPosition,
	};

	return (
		<StyledNewCharactersPage
			className={`CharacterEditPage ${props.className || ""}`}
			titleBar={{
				title: "Edit character",
				rightIcon: IconName.X,
				onRightIconClicked: () => navigate(-1),
			}}
			padding={theme.spacing.md}
		>
			<StyledImage style={imageStyle} onPan={handleImagePan} onPanStart={handlePanStart}>
				{characterImage && <ImagePosition>Drag to change position</ImagePosition>}
			</StyledImage>
			<AddImageButton isResponsive onClick={handleOnAddImage}>
				{characterImage ? "Change the" : "Add a"} character image
			</AddImageButton>
			<EditableText
				disabled={isLoading}
				title="Character name"
				editOnClick
				textItems={[
					{
						placeholder: "Tap edit to add a character name",
						multiline: false,
						value: character.name,
						onChange: (val) => {
							character.name = val;
							validate();
						},
					},
				]}
			/>
			<EditableText
				disabled={isLoading}
				title="Character title"
				editOnClick
				textItems={[
					{
						placeholder: "Tap edit to add a title",
						value: character.title,
						onChange: (val) => {
							character.title = val;
							validate();
						},
					},
				]}
			/>
			<SaveCharacterButton scheme={theme.schemes.accent2} onClick={handleSaveCharacter} disabled={isLoading || validationErrors !== undefined}>
				{character.id ? "Save" : "Create"} character
				{isLoading && <Loader size={Size.Small} layoutType={LoaderLayout.InlineButton} key="loading-characters-edit" />}
			</SaveCharacterButton>
		</StyledNewCharactersPage>
	);
}

const ImagePosition = styled.p`
	${({ theme }) => css`
		display: inline-block;
		margin: 0 auto;
		background: ${theme.schemes.shade3.passive};
		color: ${theme.schemes.shade3.text};
		padding: ${theme.spacing.xs} ${theme.spacing.sm};
		border-radius: 9999px;
	`}
`;

const StyledImage = styled(motion.div)`
	${({ theme }) => css`
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		touch-action: none;
		height: calc(2 * ${theme.spacing.xxxl});
	`}
`;

const AddImageButton = styled(Button)`
	margin: ${(props) => props.theme.spacing.lg} 0;
`;

const SaveCharacterButton = styled(Button)`
	display: block;
	margin: 0 auto;
	align-self: flex-end;
`;

const StyledNewCharactersPage = styled(Page)`
	> .PageContent {
		display: grid;
		grid-template-rows: auto auto auto auto 1fr;
	}
`;

type PanEventHandler = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
