import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Page from "Component/Global/Navigation/Page";
import { SquareButton } from "Component/Global/Input/Button";
import { Size } from "Style/Sizing";
import { useNavigate } from "react-router-dom";
import { NestedRoutes_Onboarding, PageRoutes } from "Utils/Navigation";
import { useDi } from "Utils/DependencyInjection";
import TabMenuStore from "Stores/TabMenuStore";
import UserStore from "Stores/UserStore";
import Background1 from "Static/Image/Onboarding/onboarding1.jpg";
import Background2 from "Static/Image/Onboarding/onboarding2.jpg";
import EmptyPrompt from "Component/Global/Content/EmptyPrompt";
import StackedButtonGroup from "Component/Global/Content/StackedButtonGroup";
import Generator from "Component/Generator/Generator";
import { v4 as uuid } from "uuid";

export const OnboardingWelcome = () => {
	const theme = useTheme();
	const tabMenuStore = useDi(TabMenuStore);
	const navigate = useNavigate();

	useEffect(() => {
		tabMenuStore.hideMenu();
	}, [tabMenuStore]);

	return (
		<StyledOnboardingWelcome tabBar={false} padding="0" titleBar={{ title: "Welcome storyteller" }} background={Background1}>
			<StackedButtonGroup
				contentPadding={theme.spacing.xxs}
				buttons={
					<SquareButton scheme={theme.schemes.accent2} size={Size.Large} isResponsive onClick={() => navigate(`../${NestedRoutes_Onboarding.HowItWorks}`)}>
						How it works
					</SquareButton>
				}
			/>
		</StyledOnboardingWelcome>
	);
};

export const OnboardingHowItWorks = () => {
	const theme = useTheme();
	const tabMenuStore = useDi(TabMenuStore);
	const navigate = useNavigate();

	const [buttonRef, setButtonRef] = useState<HTMLElement | SVGElement | null>(null);

	useEffect(() => {
		tabMenuStore.hideMenu();
	}, [tabMenuStore]);

	return (
		<StyledOnboardingHowItWorks tabBar={false} padding="0" titleBar={{ title: "How it works" }} background={Background2}>
			<StackedButtonGroup
				contentPadding={theme.spacing.xxs}
				buttons={
					<SquareButton ref={setButtonRef} scheme={theme.schemes.accent1} size={Size.Large} isResponsive onClick={() => navigate(`../${NestedRoutes_Onboarding.Generator}`, { state: { quick: uuid() } })}>
						Create character
					</SquareButton>
				}
			>
				<EmptyPrompt prompt="Lets roll a new character" pointer={{ target: buttonRef }} />
			</StackedButtonGroup>
		</StyledOnboardingHowItWorks>
	);
};

export const OnboardingGenerator = () => {
	const theme = useTheme();
	const userStore = useDi(UserStore);
	const tabMenuStore = useDi(TabMenuStore);
	const navigate = useNavigate();

	useEffect(() => {
		tabMenuStore.hideMenu();
	}, [tabMenuStore]);

	const handleFinishOnboarding = () => {
		userStore.hasCompletedOnboarding = true;
		navigate(PageRoutes.Generator);
	};

	return (
		<StyledOnboardingGenerator tabBar={false} padding="0" titleBar={{ title: "How about" }}>
			<StackedButtonGroup
				contentPadding={theme.spacing.xxs}
				buttons={
					<SquareButton scheme={theme.schemes.accent1} size={Size.Large} isResponsive onClick={handleFinishOnboarding}>
						Finish
					</SquareButton>
				}
			>
				<Generator canSelectAndSave={false} rollPrompt={prompt} savePrompt={prompt} />
			</StackedButtonGroup>
		</StyledOnboardingGenerator>
	);
};

const prompt = "With the filter you can roll with certain parameters.";

const StyledOnboardingWelcome = styled(Page)``;

const StyledOnboardingHowItWorks = styled(Page)``;

const StyledOnboardingGenerator = styled(Page)``;
