import React, { useMemo, useState } from "react";
import Button from "Component/Global/Input/Button";
import styled, { css, useTheme } from "styled-components";
import modalManager from "Utils/ModalManager";
import Pivot, { PivotItem } from "Component/Global/Navigation/Pivot/Pivot";
import TraitsFilters from "./TraitsFilters";
import Gender from "Enums/Gender";
import GeneratorStore from "Stores/GeneratorStore";
import { useDi } from "Utils/DependencyInjection";
import GroupsFilters from "./GroupsFilters";
import { Group } from "Models/Group";
import GroupService from "Services/GroupService";
import Loader, { LoaderLayout } from "Component/Global/Content/Loader";

export type FiltersChangedHandlerProps = {
	raceId?: string;
	cultureId?: string;
	professionId?: string;
	personalityId?: string;
	gender?: Gender;
	name?: string;
};

export type onFiltersChangedHandler = (props: FiltersChangedHandlerProps) => void;

export interface GeneratorFiltersProps {
	className?: string;
	onFiltersChanged?: onFiltersChangedHandler;
}

export default function GeneratorFilters(props: GeneratorFiltersProps) {
	const theme = useTheme();
	const generatorStore = useDi(GeneratorStore);
	const groupService = useDi(GroupService);

	const [isLoading, setIsLoading] = useState(false);
	const [pivotIndex, setPivotIndex] = useState(0);
	const [selectedGroupArchetype, setSelectedGroupArchetype] = useState<Group | undefined>(undefined);

	const handleCloseClicked = () => {
		if (selectedGroupArchetype) {
			setIsLoading(true);
			groupService
				.fetchDetails(selectedGroupArchetype.id)
				.then((group) => {
					generatorStore.groupArchetype = group;
					setSelectedGroupArchetype(undefined);
					setPivotIndex(0);
				})
				.catch((err) => modalManager.showError(err as Error))
				.finally(() => setIsLoading(false));
		} else {
			props.onFiltersChanged?.({});
			modalManager.hide("generator-filters");
		}
	};

	const pivotItems: PivotItem[] = useMemo(
		() => [
			{
				id: "traits",
				menuTitle: "Traits",
				component: () => <TraitsFilters store={generatorStore} onStoreUpdated={() => (generatorStore.groupArchetype = undefined)} />,
			},
			{
				id: "group",
				menuTitle: "Group",
				component: () => <GroupsFilters store={generatorStore} onGroupChanged={setSelectedGroupArchetype} />,
			},
		],
		[generatorStore],
	);

	return (
		<StyledGeneratorFilters className={`GeneratorFilters ${props.className || ""}`}>
			<StyledPivot items={pivotItems} centerAlignNavigation currentIndex={pivotIndex} onIndexChanged={setPivotIndex} />
			<CloseButton onClick={handleCloseClicked} isResponsive scheme={selectedGroupArchetype ? theme.schemes.accent1 : undefined}>
				{selectedGroupArchetype ? "Select" : "Close"}
				{isLoading && <Loader layoutType={LoaderLayout.InlineButton} />}
			</CloseButton>
		</StyledGeneratorFilters>
	);
}

const StyledPivot = styled(Pivot)`
	${({ theme }) => css`
		flex-grow: 1;
		width: 100%;
		height: 100%;

		.PivotMenu {
			.ItemWrapper {
				gap: ${theme.spacing.lg};
			}
			.PivotMenuItem {
				font-size: ${theme.typography.fontSizes.xxLarge};
				font-weight: ${theme.typography.fontWeight.regular};
			}
		}
	`}
`;

const CloseButton = styled(Button)`
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 0;
	height: ${(props) => props.theme.spacing.xxxl};
`;

const StyledGeneratorFilters = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;
