import axios from "axios";
import Config from "Models/Config";

export default class ConfigService {
	private _config?: Config;

	private _configPromise?: Promise<Config>;

	public async getConfig() {
		if (this._config) {
			return this._config;
		}

		if (!this._configPromise) {
			this._configPromise = new Promise((resolve, reject) => {
				axios.get<Config>("/config.json").then((result) => {
					resolve(result.data);
				}, reject);
			});
		}

		return this._configPromise;
	}
}
