import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useModalManager } from "Utils/ModalManager";
import Modal from "./Modal";
import { transparentize } from "Style/Color";
import { DefaultTransition } from "Style/Animation";

export interface ModalContainerProps {
	className?: string;
}

export default observer(ModalContainer);
function ModalContainer(props: ModalContainerProps) {
	const modalManager = useModalManager();

	return (
		<StyledModalContainer className={`ModalContainer ${props.className || ""}`}>
			<Fade
				initial={false}
				transition={DefaultTransition}
				animate={{
					opacity: modalManager.modals.length ? 1 : 0,
					height: modalManager.modals.length ? "100%" : "0",
				}}
			/>
			<AnimatePresence>
				{modalManager.modals.map((modalProps) => (
					<Modal {...modalProps} key={modalProps.id} />
				))}
			</AnimatePresence>
		</StyledModalContainer>
	);
}

const StyledModalContainer = styled.div`
	z-index: 10;
`;
const Fade = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: ${(props) => transparentize(props.theme.palette.primary, 0.5)};
`;
