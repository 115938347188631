export enum Alignment {
	Vertical = "Vertical",
	Horizontal = "Horizontal",
}

export enum VerticalAlignment {
	Top = "Top",
	Bottom = "Bottom",
}

export enum HorizontalAlignment {
	Left = "Left",
	Center = "Center",
	Right = "Right",
}
