import { ApolloClient as Apollo, createHttpLink, DefaultOptions, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import AuthenticationService from "Services/AuthenticationService";
import ConfigService from "Services/ConfigService";

export default class ApolloClient {
	private readonly _configService: ConfigService;
	private readonly _authService: AuthenticationService;
	private _client?: Apollo<NormalizedCacheObject>;
	private _clientPromise?: Promise<Apollo<NormalizedCacheObject>>;

	constructor(configService: ConfigService, authService: AuthenticationService) {
		this._configService = configService;
		this._authService = authService;
	}

	public async instance(): Promise<Apollo<NormalizedCacheObject>> {
		if (this._client) {
			return this._client;
		}

		if (!this._clientPromise) {
			const apiConfig = await this._configService.getConfig();

			this._clientPromise = new Promise<Apollo<NormalizedCacheObject>>((resolve) => {
				const httpLink = createHttpLink({
					uri: apiConfig.endpoints.graphQL,
				});

				const defaultOptions: DefaultOptions = {
					watchQuery: {
						fetchPolicy: "no-cache",
						errorPolicy: "ignore",
					},
					query: {
						fetchPolicy: "no-cache",
						errorPolicy: "all",
					},
				};

				const authLink = this.createApolloAuthLink();

				const apollo = new Apollo({
					cache: new InMemoryCache({ resultCaching: false, addTypename: false }),
					defaultOptions,
					link: authLink.concat(httpLink),
				});

				resolve(apollo);
			});
		}

		return this._clientPromise;
	}

	private createApolloAuthLink() {
		const authContext = setContext(async (_, { headers, ...context }) => {
			const tokenPromise = this._authService.getCurrentToken();

			if (tokenPromise) {
				const token = await tokenPromise;
				return {
					...context,
					headers: {
						...headers,
						authorization: token ? `Bearer ${token}` : "",
					},
				};
			}

			return {
				...context,
				headers,
			};
		});

		return authContext;
	}
}
