import { Character } from "Models/Character";
import React from "react";
import styled, { css } from "styled-components";

export interface CharacterItemProps {
	className?: string;
	character: Partial<Character>;
	isDisplay?: boolean;
}

export default function CharacterItem(props: CharacterItemProps) {
	let characterDescription = "";

	if (props.character.culture?.name) {
		characterDescription += ` ${props.character.culture.name}`;
	}

	if (props.character.race?.name && props.character.race?.name !== props.character.culture?.name) {
		characterDescription += ` ${props.character.race.name}`;
	}

	if (props.character.profession?.name) {
		characterDescription += ` ${props.character.profession.name}`;
	}

	if (props.character.title) {
		characterDescription += ` - ${props.character.title}`;
	}

	return (
		<StyledCharacterItem className={`CharacterItem ${props.className || ""}`}>
			<Name $isDisplay={props.isDisplay}>{props.character.name}</Name>
			<Description $isDisplay={props.isDisplay}>{characterDescription}</Description>
		</StyledCharacterItem>
	);
}

const Name = styled.h4<{ $isDisplay?: boolean }>`
	${({ theme, $isDisplay }) => css`
		margin: 0 0 ${theme.spacing.xs} 0;
		font-size: ${$isDisplay ? theme.typography.fontSizes.xLarge : theme.typography.fontSizes.medium};
	`}
`;

const Description = styled.p<{ $isDisplay?: boolean }>`
	font-size: ${(props) => (props.$isDisplay ? props.theme.typography.fontSizes.medium : props.theme.typography.fontSizes.small)};
`;

const StyledCharacterItem = styled.div``;
