import InApp, { Browser } from "detect-inapp";
import { useEffect, useState } from "react";

export function detectInApp() {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inApp = new InApp(navigator.userAgent || navigator.vendor || (window as any).opera);

	const isInAppBrowser = inApp.isInApp && ["chrome", "safari", "firefox"].indexOf(inApp.browser) === -1;

	return {
		browser: inApp.browser,
		isMobile: inApp.isMobile,
		isDesktop: inApp.isDesktop,
		isInApp: inApp.isInApp,
		isInAppBrowser,
		userAgent: inApp.ua,
	};
}

const useInApp = () => {
	const [browser, setBrowser] = useState<Browser>();
	const [isMobile, setIsMobile] = useState<boolean>();
	const [isDesktop, setIsDesktop] = useState<boolean>();
	const [isInApp, setIsInApp] = useState<boolean>();
	const [isInAppBrowser, setIsInAppBrowser] = useState<boolean>();
	const [userAgent, setUserAgent] = useState<string>();

	const onResize = () => {
		const inApp = detectInApp();
		setBrowser(inApp.browser);
		setIsMobile(inApp.isMobile);
		setIsDesktop(inApp.isDesktop);
		setIsInApp(inApp.isInApp);
		setIsInAppBrowser(inApp.isInAppBrowser);
		setUserAgent(inApp.userAgent);
	};

	useEffect(() => {
		onResize();
		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return { browser, isMobile, isDesktop, isInApp, isInAppBrowser, userAgent };
};

export default useInApp;
