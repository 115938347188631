import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import WrappedArray from "Utils/WrappedArray";
import PivotContent from "./PivotContent";
import PivotMenu from "./PivotMenu";

export interface PivotChildProps {
	items: WrappedArray<PivotItem>;
	onRequestUpdate: (item: PivotItem) => void;
}
export interface PivotItem {
	menuTitle: string;
	id: string;
	component: () => React.ReactNode;
}

export type PivotProps = PropsWithChildren<{
	className?: string;
	items: PivotItem[];
	centerAlignNavigation?: boolean;
	currentIndex?: number;
	onIndexChanged?: (index: number) => void;
}>;

export default function Pivot(props: PivotProps) {
	const [currentIndex, setCurrentIndex] = useState(props.currentIndex ?? 0);
	const previousItem = useRef<PivotItem | undefined>(undefined);
	const items = useMemo(() => new WrappedArray(props.items, currentIndex), [props.items, currentIndex]);

	const handleItemChange = (item: PivotItem) => {
		previousItem.current = items.current();
		const index = items.indexOf(item);
		setCurrentIndex(index);
		props.onIndexChanged?.(index);
	};

	useEffect(() => {
		if (props.currentIndex != undefined) {
			setCurrentIndex(props.currentIndex);
		}
	}, [props.currentIndex]);

	return (
		<StyledPivot className={`Pivot ${props.className || ""}`}>
			<PivotMenu items={items} onRequestUpdate={handleItemChange} centerAlign={props.centerAlignNavigation} />
			<PivotContent items={items} previousItem={previousItem.current} onRequestUpdate={handleItemChange} />
			{props.children}
		</StyledPivot>
	);
}

const StyledPivot = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.palette.primary};
`;
