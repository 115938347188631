import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, useLocation } from "react-router";
import UserStore from "Stores/UserStore";
import styled from "styled-components";
import { useDi } from "Utils/DependencyInjection";
import { PageRoutes } from "Utils/Navigation";
import CharacterPage from "./Character/CharacterPage";
import { PageWrapper } from "./Global/Navigation/Page";
import NotFoundPage from "./NotFoundPage";
import SplashPage from "./SplashPage";
import ForgotPasswordPage from "./User/ForgotPasswordPage";
import LoginPage from "./User/LoginPage";
import LogoutPage from "./User/LogoutPage";
import SignupPage from "./User/SignupPage";

export interface DesktopProps {
	className?: string;
}

export default observer(Desktop);
function Desktop(props: DesktopProps) {
	const location = useLocation();
	const userStore = useDi(UserStore);

	return (
		<StyledDesktop className={`Desktop ${props.className || ""}`}>
			{userStore.isLoading ? (
				<SplashPage key="splash" />
			) : (
				<PageWrapper className="PageWrapper">
					<Routes location={location} key={location.pathname}>
						<Route path={PageRoutes.Logout} element={<LogoutPage />} />
						{userStore.isLoggedIn ? (
							<>
								<Route path={PageRoutes.Character} element={<CharacterPage />} />
							</>
						) : (
							<>
								<Route path={PageRoutes.Signup} element={<SignupPage />} />
								<Route path={PageRoutes.ForgotPassword} element={<ForgotPasswordPage />} />
								<Route path="*" element={<LoginPage />} />
							</>
						)}
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</PageWrapper>
			)}
		</StyledDesktop>
	);
}

const StyledDesktop = styled.div`
	height: 100%;
	width: 100vw;
	overflow: auto;
`;
