import { IconName } from "Component/Global/Content/Icon";
import { motion } from "framer-motion";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { PageRoutes } from "Utils/Navigation";
import MenuItem, { TabMenuItemSpec } from "./TabMenuItem";
import { v4 as uuid } from "uuid";
import { DefaultTransition } from "Style/Animation";

export interface TabMenuProps {
	className?: string;
	contextItem?: ContextItemName;
}

const TabMenu = forwardRef<HTMLDivElement, TabMenuProps>((props, ref) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedItem, setSelectedItem] = useState<TabMenuItemSpec>();

	const contextItem = ItemMap[props.contextItem ?? MenuItemName.QuickRoll];
	const menuItems = useMemo(() => [contextItem, ItemMap[MenuItemName.Generator], ItemMap[MenuItemName.Characters], ItemMap[MenuItemName.Groups]], [contextItem]);

	useEffect(() => {
		const itemMatchingRoute = menuItems.find((item) => item !== contextItem && item.link && (item.link.startsWith(location.pathname) || location.pathname.startsWith(item.link)));
		if (itemMatchingRoute) {
			setSelectedItem(itemMatchingRoute);
		}
	}, [contextItem, location.pathname, menuItems]);

	const handleLinkClicked = (item: TabMenuItemSpec) => {
		if (item !== contextItem) {
			setSelectedItem(item);
		}
		item.onClick?.({ location, navigate });
	};

	return (
		<StyledMenu ref={ref} className={`TabMenu ${props.className || ""}`}>
			{menuItems.map((item, i) => (
				<StyledLink key={item.text} to={item.link || ""} state={item.linkState?.()} data-id={item.text} onClick={handleLinkClicked.bind(null, item)}>
					<MenuItem icon={item.icon} name={item.text} isSelected={item === selectedItem} isContextual={i === 0} />
					{item === selectedItem && <Indicator layoutId="indicator" transition={DefaultTransition} />}
				</StyledLink>
			))}
		</StyledMenu>
	);
});
export default motion(TabMenu);

const StyledMenu = styled.div`
	flex-shrink: 0;
	flex-grow: 0;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	justify-content: stretch;
	width: 100%;
	background: ${(props) => props.theme.palette.primary};
	height: 80px;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	position: relative;
`;

const Indicator = styled(motion.div)`
	${({ theme }) => css`
		position: absolute;
		left: calc(50% - 3px);
		bottom: 8px;
		display: block;
		text-align: center;
		border-radius: 50%;
		width: 6px;
		height: 6px;
		background: ${theme.palette.accent2};
	`}
`;

export enum ContextItemName {
	Options = "Options",
	QuickRoll = "QuickRoll",
	Menu = "Menu",
}

export enum MenuItemName {
	Options = "Options",
	QuickRoll = "QuickRoll",
	Menu = "Menu",
	Generator = "Generator",
	Characters = "Characters",
	Groups = "Groups",
}

const ItemMap: Record<MenuItemName, TabMenuItemSpec> = {
	Options: {
		icon: IconName.Sliders,
		text: "Options",
	},
	QuickRoll: {
		icon: IconName.UserPlus,
		text: "Quick Roll",
		link: PageRoutes.Generator,
		linkState: () => {
			return {
				quick: uuid(),
			};
		},
	},
	Menu: {
		icon: IconName.Menu,
		text: "Menu",
	},
	Generator: {
		icon: IconName.Refresh,
		text: "Generator",
		link: PageRoutes.Generator,
	},
	Characters: {
		icon: IconName.User,
		text: "Characters",
		link: PageRoutes.Character,
	},
	Groups: {
		icon: IconName.Users,
		text: "Groups",
		link: PageRoutes.Group,
	},
};
