import { useEffect, useRef } from "react";

function useDebouncedCallback<T>(callback?: (value: T) => void, delay?: number): [(value: T) => void, () => void] {
	const timer = useRef<NodeJS.Timeout>();

	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
			clearTimer();
		};
	}, []);

	const clearTimer = () => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = undefined;
		}
	};

	const setter = (value: T) => {
		clearTimer();
		timer.current = setTimeout(() => {
			if (mounted.current) {
				callback?.(value);
			}
		}, delay || 500);
	};

	return [setter, clearTimer];
}

export default useDebouncedCallback;
